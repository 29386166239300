import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AddDashboardComponent } from '../add-dashboard/add-dashboard.component';
import { MatDialog } from '@angular/material/dialog';
import { DashlistService } from '../dashlist.service';
import { AuthService } from 'src/app/auth/auth.service';
import { iif } from 'rxjs';

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.css'],
  providers: [DashlistService, AuthService]
})
export class DashboardListComponent implements OnInit {
 path;
  dashboards;
  bulkDashboards;
  noResult = false;
  data =   {label: 'Delete dashboard'};
  selectedDashboardId = 0;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private dashListService: DashlistService
  ) {}

  ngOnInit() {
    this.path = this.authService.path;
    this.getDashboards();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let filteredDashboards = [];
    if (filterValue.length > 0) {
    this.bulkDashboards.forEach((element) => {
      if ((element.name).toLowerCase().includes(filterValue)) {
         filteredDashboards.push(element);
      }
    });
    } else {
        filteredDashboards = this.bulkDashboards;
    }
    this.dashboards = filteredDashboards;
    if (this.dashboards.length === 0) {
     this.noResult = true;
    } else {
      this.noResult = false;
    }
  }

  getDashboards() {
    this.dashListService.getDashboards().subscribe(
      (data) => {
        this.dashboards = data;
        this.bulkDashboards = data;
      }
    );
  }

  toDashboard(id, name) {
    this.selectedDashboardId = id;
    this.router.navigate([this.path + '/dashboard/' + id, {name}]);
  }

  onAddDashboard() {
    const dialogRef = this.dialog.open(AddDashboardComponent, {
      width: '500px',
      height: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
        this.getDashboards();
         });
  }

  onDelete(event) {
    this.dashListService.deleteDashboard(event.data.id).subscribe(
      (data) => {
        this.getDashboards();
      }
    );
  }

}
