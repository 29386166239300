import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  //base url
  baseURL = environment.baseUrl;

  constructor(
    private router: Router,
    private authServ: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }

  toLogin(value, desc) {
    this.authServ.setCurrentApp(value)
    this.authServ.setCurrentAppItem(desc)
    if (desc == 'ext') {
      //this.router.navigate(['ext-services'])
      window.location.href = this.baseURL + '/mkilimo/public/officers';
    }
    if (desc == 'emarket') {
      window.location.href = this.baseURL + "/mkilimo/public/market-places/sellers"
    }
    if (desc == 'frs') {
      window.location.href = this.baseURL + "/frs"
    }
    if (desc == 'dashb') {
      //this.router.navigate(['agri-dashboard'])
      window.location.href = this.baseURL + '/mkilimo/auth/login';
    }
  }

  weatherInfo() {
    this.router.navigate(['/weather-information']);
  }
  helpCenter() {
    this.router.navigate(['/help-center']);
  }
}
