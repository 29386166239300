import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from './auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dashb';

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(private translateService: TranslateService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private authservc: AuthService
    )
    {
      this.translateService.setDefaultLang('en');
      this.translateService.use(localStorage.getItem('language'));

      if(authservc.isAdmin) {
        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(900);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(60);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
          this.idleState = 'No longer idle.'
          console.log(this.idleState);
          this.reset();
        });

        idle.onTimeout.subscribe(() => {
          this.idleState = 'Timed out!';
          this.timedOut = true;
          console.log(this.idleState);
          this.authservc.removeCredentials();
          this.childModal.hide();
          this.router.navigate(['/login']);
        });

        idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!'
            console.log(this.idleState);
            this.childModal.show();
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
          this.idleState = 'You will time out in ' + countdown + ' seconds!'
          console.log(this.idleState);
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());

        this.reset();
      }


    }
    reset() {
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
    }
    hideChildModal(): void {
      this.childModal.hide();
    }

    stay() {
      this.childModal.hide();
      this.reset();
    }

    logout() {
      this.childModal.hide();
      this.authservc.removeCredentials();
      this.router.navigate(['/login']);
    }
}
