import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddMarketComponent } from 'src/app/modules/dashboard-definition/add-market/add-market.component';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AddProductionComponent } from 'src/app/modules/dashboard-definition/add-production/add-production.component';
import { AddSaleComponent } from 'src/app/modules/dashboard-definition/add-sale/add-sale.component';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-production-table',
  templateUrl: './production-table.component.html',
  styleUrls: ['./production-table.component.css']
})
export class ProductionTableComponent implements OnInit {
  private _cropId;
  current_year = new Date().getFullYear();
  next_year = new Date().getFullYear() + 1;
  current_season = this.current_year + '/' + this.next_year;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() set cropId(value) {
    this._cropId = value;
    this.getProduction();
  }

  get cropId() {
    return this._cropId;
  }

  displayedColumns: string[] = ['district', 'production'];
  dataSource;
  production = [];

  constructor(
    public dialog: MatDialog,
    private dashDefService: DashboardDefinitionService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.getProduction();
  }

  getProduction() {
    console.log(this.current_season);
    this.dashDefService.getCropProduction(this.cropId, this.current_season, 'undefined').subscribe(
      (data: []) => {
        console.log(data);
        this.production = data;
        this.dataSource = new MatTableDataSource(this.production);
        this.dataSource.paginator = this.paginator;
      }
    );
 }
  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddProduction() {
    const dialogRef = this.dialog.open(AddProductionComponent, {
      width: '500px',
      height: '500px',
      data: {cropId: this.cropId}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getProduction();
    });
  }
  onAddSale() {
    const dialogRef = this.dialog.open(AddSaleComponent, {
      width: '500px',
      height: '500px',
      data: {cropId: this.cropId}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getProduction();
    });
  }
}

export interface Market {
  local;
}
