import { Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators, FormArray, FormControlDirective} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DashboardDefinitionService } from '../dashboard-definition.service';

export interface DialogData {
  id;
  name;
  measure;
}

@Component({
  selector: 'app-add-crop',
  templateUrl: './add-crop.component.html',
  styleUrls: ['./add-crop.component.css'],
  providers: [DashboardDefinitionService]
})
export class AddCropComponent implements OnInit {
  measure
  measures
  cropForm = this.fb.group({
   menuId: ['', Validators.required],
   crops: this.fb.array([
    this.fb.group({
    name:  ['', Validators.required],
    name_en:  ['', Validators.required],
    price:  [''],
    unit_measure:  [''],
    })])
  });

  editForm = this.fb.group({
    cropId: ['', Validators.required],
    name:  ['', Validators.required],
    measure:  ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<AddCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dashDefServ: DashboardDefinitionService,
    private fb: FormBuilder,
    // private snackbar: MatSnackBar
    ) {
      this.cropForm.get('menuId').setValue(data.id);
      if (this.data.name !== undefined) {
        this.editForm.get('cropId').setValue(data.id);
        this.editForm.get('name').setValue(data.name);
        this.editForm.get('measure').setValue(data.measure);
        this.measure = data.measure;
        console.log(this.measure)
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getUnitMeasures()
  }


  getErrorMessage() {
    return 'You must enter a value';
  }

  getUnitMeasures() {
    this.dashDefServ.getUnitMeasures().subscribe(
      (data) => {
        this.measures = data;
      }
    );
  }

  get stocks() {
    return this.cropForm.get('crops') as FormArray;
  }

  addAlias() {
    this.stocks.push(
      this.fb.group({
        name: ['', Validators.required],
        price:  [''],
        unit_measure:  ['']
      })
    );
  }

  removeAlis(index) {
    this.stocks.removeAt(index);
  }

  onSubmit() {
    console.log(this.cropForm.value)
    this.dashDefServ.addCrops(this.cropForm.value).subscribe(
      (data) => {
        this.onNoClick();
      }
    );
  }

  onUpdate() {
    this.dashDefServ.updateCrop(this.editForm.value, localStorage.getItem('language')).subscribe(
      (data) => {
        this.onNoClick();
      }
    );
  }

}
