import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'node_modules/chart.js';

@Component({
  selector: 'app-capacity-chart',
  templateUrl: './capacity-chart.component.html',
  styleUrls: ['./capacity-chart.component.css']
})
export class CapacityChartComponent implements OnInit {
  @Input() dashboardName: string;

  constructor() { }

  ngOnInit() {

    var myChart = new Chart('capacity', {
      type: 'bar',
      data: {
          labels: ['Dar es Salaam', 'Dodoma', 'Kilimanjaro', 'Lindi', 'Manyara', 'Mbeya', 'Mtwara', 'Pwani', 'Ruvuma', 'Songwe', 'Tanga'],
          datasets: [{
              label: 'CAPACITY(TONNE)',
              data: [24000, 1500, 11000, 47500, 1500, 6500, 63500, 23300, 47850, 15500, 900],
              backgroundColor: '#79CD95',
              // borderColor: [
              //     'rgba(255, 99, 132, 1)',
              //     'rgba(54, 162, 235, 1)',
              //     'rgba(255, 206, 86, 1)',
              //     'rgba(75, 192, 192, 1)',
              //     'rgba(153, 102, 255, 1)',
              //     'rgba(255, 159, 64, 1)'
              // ],
              borderWidth: 0
          }]
      },
      options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  },
                  gridLines: {
                    display: false
                  }
              }],
              xAxes: [{
                  gridLines: {
                    display: false
                  }
              }]
          },
          legend: {
            display: false
          }
      }
  });
  }

}
