import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.css']
})
export class AddRolesComponent implements OnInit {

  rolesForm = this.fb.group({
    role : ['', Validators.required]
  })

  constructor(
    private fb : FormBuilder,
    private dashService : DashboardDefinitionService,
    private dialogRef: MatDialogRef<AddRolesComponent>
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    if(this.rolesForm.valid) {
      this.dashService.addRole(this.rolesForm.value).subscribe(
        (data) => {
          this.onNoClick();
        },
        error => {
          console.log(error);
        }
      )
    }
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
