import { Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators, FormArray, FormControlDirective} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DashboardDefinitionService } from '../dashboard-definition.service';

export interface DialogData {
  id;
  name;
}

@Component({
  selector: 'app-add-dashboard-main-menu',
  templateUrl: './add-dashboard-main-menu.component.html',
  styleUrls: ['./add-dashboard-main-menu.component.css'],
  providers: [DashboardDefinitionService]
})
export class AddDashboardMainMenuComponent implements OnInit {

  menuForm = this.fb.group({
   dashboardId: ['', Validators.required],
   menu: this.fb.array([
    this.fb.group({
    name:  ['', Validators.required]
    })])
  });

  editForm = this.fb.group({
    menuId: ['', Validators.required],
    name: ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<AddDashboardMainMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dashDefServ: DashboardDefinitionService,
    private fb: FormBuilder,
    // private snackbar: MatSnackBar
    ) {
      this.menuForm.get('dashboardId').setValue(data.id);
      if (data.name !== undefined) {
        this.editForm.get('menuId').setValue(data.id);
        this.editForm.get('name').setValue(data.name);
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() { }


  getErrorMessage() {
    return 'You must enter a value';
  }

  get stocks() {
    return this.menuForm.get('menu') as FormArray;
  }

  addAlias() {
    this.stocks.push(
      this.fb.group({
        name:  ['', Validators.required]
      })
    );
  }

  removeAlis(index) {
    this.stocks.removeAt(index);
  }

  onSubmit() {
    console.log(this.menuForm.value);
    this.dashDefServ.addMainMenu(this.menuForm.value).subscribe(
      (data) => {
        console.log(data);
        this.onNoClick();
      }
    );
    }

  onUpdate() {
    this.dashDefServ.updateMainMenu(this.editForm.value).subscribe(
      (data) => {
        this.onNoClick();
      }
    );
  }

}
