import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
import HC_exporting from 'highcharts/modules/exporting';
import { ProductionExcelService } from 'src/app/production-excel.service';
HC_exporting(Highcharts);

@Component({
  selector: 'app-crop-production-info-graph',
  templateUrl: './crop-production-info-graph.component.html',
  styleUrls: ['./crop-production-info-graph.component.css']
})
export class CropProductionInfoGraphComponent implements OnInit {
  private _cropId;
  private _seasons;
  private _regions;
  private _yLabel;
  private _title;
  private _color;
  private _consumption;
  Highcharts = Highcharts;
  chartOptions: {};
  chartConstructor = 'chart';

  @Input() crop;

  @Input() set cropId(value) {
    this._cropId = value;
    this.setOption();
  }
  get cropId() {
    return this._cropId;
  }
  @Input() set seasons(value) {
    this._seasons = value;
    this.setOption();
  }
  get seasons() {
    return this._seasons;
  }
  @Input() set consumptions(value) {
    this._consumption = value;
    this.setOption();
  }
  get consumptions() {
    return this._consumption;
  }

  @Input() set regions(value) {
    this._regions = value;
    this.setOption();
  }
  get regions() {
    return this._regions;
  }
  @Input() set yLabel(value) {
    this._yLabel = value;
    this.setOption();
  }
  get yLable() {
    return this._yLabel;
  }
  @Input() set title(value) {
    this._title = value;
    this.setOption();
  }
  get title() {
    return this._title;
  }
  @Input() set color(value) {
    this._color = value;
    this.setOption();
  }
  get color() {
    return this._color;
  }


  convertedData: any[] = [];
  graphData: any[] = [];

  constructor(
    private excelService:ProductionExcelService) { }

  ngOnInit() {
    this.setOption();
  }

  setOption() {
    this.chartOptions = {
      chart: {
          type: 'column'
      },
      title: {
          text: this.title + ' (' + this.crop + ')'
      },
      subtitle: {
          text: 'Click the columns to view regions data.'
      },
      accessibility: {
          announceNewData: {
              enabled: true
          }
      },
      xAxis: {
          type: 'category'
          // categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
      },
      yAxis: {
          title: {
              text: this.yLabel
          }

      },
      legend: {
          enabled: true
      },
      plotOptions: {
          series: {
              borderWidth: 0,
              // dataLabels: {
              //     enabled: true,
              //     format: '{point.y} Tonnes',
              //     color: '#4B4E4C'
              // }
          },
         column: {
            // color: this.color
            pointWidth: 25,
            groupPadding: 0.35
         }
      },

      tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} Tonnes</b>'
      },
      credits: {
      enabled: false
      },
      series: [
          {
              name: 'Production',
              colorByPoint: false,
              data: this.seasons
          },
          {
            name: 'Consumption',
            colorByPoint: false,
            data: this.consumptions
        },
      //   {
      //     name: 'Remain',
      //     colorByPoint: false,
      //     data: this.remains
      // }
      ],
      drilldown: {
          series: this.regions
      }
  };
  }


  exportAsXLSX():void {
    this._seasons.forEach(element => {
      this.convertedData = [];
      this.convertedData.push(element.name)
      this.convertedData.push(element.y)
      this.graphData.push(this.convertedData)
      // console.log(this.convertedData)
      console.log(this.graphData)
    });
    this.excelService.exportAsExcelFile1(this.graphData, 'seasons_crop_production');
 }
}
