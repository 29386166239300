import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { DashboardDefinitionService } from '../dashboard-definition.service';
import { ImportFileComponent } from '../import-file/import-file.component';

import {Chart} from 'node_modules/chart.js';

@Component({
  selector: 'app-main-mechanization-dashb',
  templateUrl: './main-mechanization-dashb.component.html',
  styleUrls: ['./main-mechanization-dashb.component.css']
})
export class MainMechanizationDashbComponent implements OnInit {
  types
  drilled = false;
  //  = [
  //   {
  //     id: 1,
  //     name: 'Power tiller'
  //   },
  //   {
  //     id: 2,
  //     name: 'Tractor'
  //   },
  //   {
  //     id: 3,
  //     name: 'Pough'
  //   },
  //   {
  //     id: 4,
  //     name: 'Harrow'
  //   },
  // ];
  typeId;
  typeName;
  mechanization;
  thickness = 25;
  seasonLabel;
  regionLabel;
  label = 'season';
  max = 16;

  level = 'season';

  selected = 0;
  selectedFilter;
  obj;
  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private dashDefService: DashboardDefinitionService,
    private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit() {
    this.getTypes();
    this.selectedFilter = 'all';
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollable() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noEquipment() {
    if (this.types.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  getTypes() {
    this.dashDefService.getEquipmentTypes().subscribe(
      data => {
        this.types = data;
        this.typeName = this.types[0].name;
        this.getMechanizationData(this.typeName);
      }
    );
  }

  backToSeasons() {
    this.thickness = 25;
    this.getMechanizationData(this.typeName);
    this.drilled = false;
    this.label = 'season';
    this.level = 'season';
    this.selectedFilter = 'all';
  }

  onChangeType(typeName, i) {
    console.log(typeName);
    this.typeName = typeName;
    this.selected = i;
    this.thickness = 25;
    this.label = "season";
    this.level = "season";
    this.selectedFilter = 'all';
    this.getMechanizationData(typeName);

   }

  filterOwnership(value) {
    if(this.level == 'season') {
      // type
      this.dashDefService.getMechanizationDataFilter(this.typeName, value).subscribe(
        data => {
          this.mechanization = data;
          this.drawMechanization(this.thickness);
        }
      );
    } else if(this.level == 'regions') {
      //  type, season
      this.dashDefService.getMechanizationDataFilterWithSeason(this.typeName, this.seasonLabel, value).subscribe(
        data => {
          this.mechanization = data;
          this.drawMechanization(this.thickness);
        }
      );
    } else {
    //  type, season, region
    this.dashDefService.getMechanizationDataFilterWithRegion(this.typeName, this.seasonLabel, this.regionLabel, value).subscribe(
      data => {
        this.mechanization = data;
        this.drawMechanization(this.thickness);
      }
    );
    }

  }

   onImportData(type) {
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '500px',
      height: '250px',
      data: {typeId: this.typeId, type}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selectedFilter = 'all';
      this.getTypes();
     this.getMechanizationData(this.typeName);
    });
   }

   onResize(event) {
    this.drawMechanization(this.thickness);
   }
   getMechanizationData(type) {
    this.dashDefService.getMechanizationData(type).subscribe(
      data => {
        this.mechanization = data;
        this.drawMechanization(this.thickness);
      }
    );
   }

  drawMechanization(barSize) {
    let myChart;
    document.getElementById('mechanization').innerHTML = '';
    document.getElementById('mechanization').innerHTML = '<canvas id="mechanizationChart" style="height: 100%; width: 100%"></canvas>';
    let ctx = document.getElementById('mechanizationChart');
    let data = {
      labels: this.mechanization[0],
      datasets: [
        {
          label: "Working",
          data: this.mechanization[1],
          backgroundColor: '#95CEFF',
          // borderColor: 'white',
          barThickness: barSize,

        },
        {
          label: "Not Working",
          data: this.mechanization[2],
          backgroundColor: '#FF7708',
          // borderColor: 'white',
          barThickness: barSize,
        },
    ]
    };

    Chart.Legend.prototype.afterFit = function() {
        this.height = this.height + 10;
    };
    Chart.defaults.global.legend.labels.usePointStyle = true;
    const $this = this;
    myChart = new Chart(ctx, {
      type: 'bar',
      data,
      options: {
        title: {
          text: "Working Status" + ' (' + this.typeName + ')',
          display: true,
          fontSize: 25,
        },
        onClick(e) {
          // const type = this.getElementsAtEvent(e)[0]._model.label;
          // console.log($this.seasonLabel);
          if($this.label == 'season') {
            $this.seasonLabel = this.getElementsAtEvent(e)[0]._model.label;
            $this.drillDownToRegions($this.seasonLabel);
          } else {
            console.log($this.seasonLabel)
            $this.regionLabel = this.getElementsAtEvent(e)[0]._model.label;
            $this.drillDownToDistricts($this.seasonLabel, $this.regionLabel);
          }
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        hover: {
          animationDuration: 0
        },
        animation: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: true
          },
          onComplete() {
            let chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily );
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.fillStyle = '#AEAEAE';

            this.data.datasets.forEach(function(dataset, i) {
              let meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                let data = numberWithCommas(dataset.data[index]);
                // ctx.fillText(data, bar._model.x - 4, bar._model.y - 3);
              });
            });
          }
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltips: {
          enabled: true
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              color: "#000"
              // labelString: "Amount"
            },
            gridLines: {
              display: true,
              // color: 'transparent',
              zeroLineColor: '#AEAEAE'
            },
            ticks: {
              // max: Math.max(...data.datasets[0].data) + 0.5,
              display: true,
              beginAtZero: true,
              // fontColor: '#D6D6D6',
            }
          }],
          xAxes: [{
            // barPercentage: 0.9,
            // categoryPercentage: 0.55,
            gridLines: {
              display: false,
              color: 'transparent',
              zeroLineColor: '#000'
            },
            ticks: {
              // max: Math.max(...data.datasets[0].data) + 10,
              beginAtZero: false,
              display: true,
              // fontColor: '#D6D6D6',
              autoSkip: false,
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 40,
            top: 20,
            bottom: 0
          }
        }
      }
    });
  }

  drillDownToRegions(season) {
    this.dashDefService.getMechanizationDataRegions(this.typeName, season).subscribe(
      data => {
        this.thickness = 20;
        this.mechanization = data;
        this.drilled = true;
        this.label = "region";
        this.level = "regions";
        this.selectedFilter = 'all';
        this.drawMechanization(this.thickness);
      }
    );
  }

  drillDownToDistricts(season, region) {
    this.dashDefService.getMechanizationDataDistricts(this.typeName, season, region).subscribe(
      data => {
        this.thickness = 20;
        this.mechanization = data;
        this.drilled = true;
        this.level = "districts";
        this.label = "districts";
        this.selectedFilter = 'all';
        this.drawMechanization(this.thickness);
      }
    );
  }

}

function numberWithCommas(x) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}
