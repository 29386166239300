import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { DashboardDefinitionService } from './../dashboard-definition.service';
import { Chart } from 'node_modules/chart.js';
import { numberFormat } from 'highcharts';
import { MatTabChangeEvent } from '@angular/material';
// import * as L from 'leaflet'

@Component({
  selector: 'app-main-irrigation-content',
  templateUrl: './main-irrigation-content.component.html',
  styleUrls: ['./main-irrigation-content.component.css']
})
export class MainIrrigationContentComponent implements OnInit, AfterViewInit {
zones;
regions;
districts:any = [];
zoneId = 0;
irrigationSummary;
areaSummary;
ratioToPotential;
potential;
designed;
improved;
traditional;
drySeason;
wetSeason;
height = true;
index = 0;
name;
regionId;
title = "Schemes Area (Ha)";
private map;

// private initMap(): void {
//   this.map = L.map('map', {
//     center: [ -6.3728253, 34.8924826 ],
//     zoom: 5
//   });

//   const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
//     maxZoom: 18,
//     minZoom: 5,
//     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
//   });

//   tiles.addTo(this.map);
// }

  constructor(
    private dashsrv: DashboardDefinitionService,
    // private el: ElementRef
  ) { }

  ngOnInit() {
    this.getSummary();
    this.getAllZones();
  }
  ngAfterViewInit(): void {
    // this.initMap();
    this.getSummary();
  }


  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index;
    this.zones = null;
    this.regions = null;
    this.districts = null;
    if(this.index == 1) {
      this.title = "Schemes Area (Ha)"
    }
    if(this.index == 2) {
      this.title = "Schemes"
    }
    if(this.index != 0) {
      this.getAllZones();
      this.getZoneRegion(0);
      this.getRegionDistricts(0);
      this.getGraphData(this.zoneId, this.index);
    }else {
      this.getSummary();
      this.getAllZones();
    }
  }
  getAllZones() {
    this.dashsrv.getAllZones().subscribe(
      data => {
        console.log(data)
        this.zones = data;
      },
      error => {
        console.log(error)
      }
    )
  }

  onChange(value) {
    if(value == 0) {this.height = true;}
    if(value != 0) {this.height = false;}
    console.log(this.index)
    this.getZoneRegion(value);
    this.getGraphData(value, this.index)
  }

  // onChangeZone(value, event) {
  //   console.log(value)
  //   this.getZoneRegions(value);
  //   this.getGraphData(value)
  //   let other = document.getElementsByClassName('list-group-item');
  //   for(var i =0; i < other.length; i++) {
  //     other[i].className = 'list-group-item';
  //   }
  //   console.log(document.getElementById('zone-' + value).className)
  //   event.target.className = 'list-group-item active';
  // }

  // getZoneRegions(value) {
  //   this.dashsrv.getZoneRegions(value).subscribe(
  //     data => {
  //       this.regions = data
  //       console.log(this.regions)
  //     }
  //   )
  // }
  getZoneRegion(value) {
    console.log(value)
    this.dashsrv.getZoneRegions(value).subscribe(
      data => {
        this.regions = data
        console.log(this.regions)
      }
    )
  }

  getGraphData(value, index) {

    this.dashsrv.getGraphData(value, index).subscribe(
      data => {
        console.log(data)
        this.potential    = data[0];
        this.designed     = data[1];
        this.improved     = data[2];
        this.traditional  = data[3];
        this.drySeason    = data[4];
        this.wetSeason    = data[5];

        this.name = "Regions";
        this.drawGraphs()
      }
    )
  }

  drawPotentialAreaChart() {
    var myChart
    document.getElementById('potentialContainer').innerHTML = "";
    document.getElementById('potentialContainer').innerHTML = '<canvas id="potential" style="height: 100%; width: 100%"></canvas>'
    var ctx = document.getElementById("potential");
    var data = {
      labels: this.potential[0],
      datasets: [{
        label: this.name,
        data: this.potential[1],
        backgroundColor: "#B33633"
      }]
    }
    let $this = this;
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: function (e) {
            var category = this.getElementsAtEvent(e)[0]._model.datasetLabel;
            var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
            if(category == 'Regions') {
              $this.drillDownToDistricts(activePointLabel);
            }
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
            display: true,
            text: 'Potential '+ this.title
        },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = numberFormat(dataset.data[index], 0,',',',');
                ctx.fillText(data, bar._model.x + 23, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false,
          position: "bottom"
        },
        tooltips: {
          "enabled": false
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 40,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  drawDesignedAreaChart() {
    var myChart
    document.getElementById('designedContainer').innerHTML = "";
    document.getElementById('designedContainer').innerHTML = '<canvas id="designed" style="height: 100%; width: 100%"></canvas>'
    var ctx = document.getElementById("designed");
    var data = {
      labels: this.designed[0],
      datasets: [{
        label: this.name,
        data: this.designed[1],
        backgroundColor: "#90B543"
      }]
    }
    let $this = this;
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: function (e) {
            var category = this.getElementsAtEvent(e)[0]._model.datasetLabel;
            var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
            if(category == 'Regions') {
              $this.drillDownToDistricts(activePointLabel);
            }
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: 'Designed ' + this.title
        },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = numberFormat(dataset.data[index], 0,',',',');
                ctx.fillText(data, bar._model.x + 18, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false
        },
        tooltips: {
          "enabled": false
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 40,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  drawImprovedAreaChart() {
    var myChart
    document.getElementById('improvedContainer').innerHTML = "";
    document.getElementById('improvedContainer').innerHTML = '<canvas id="improved" style="height: 100%; width: 100%"></canvas>'
    var ctx = document.getElementById("improved");
    var data = {
      labels: this.improved[0],
      datasets: [{
        label: this.name,
        data: this.improved[1],
        backgroundColor: "#76539E"
      }]
    }
    let $this = this;
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: function (e) {
            var category = this.getElementsAtEvent(e)[0]._model.datasetLabel;
            var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
            if(category == 'Regions') {
              $this.drillDownToDistricts(activePointLabel);
            }
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: 'Improved ' + this.title
      },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = numberFormat(dataset.data[index], 0,',',',');
                ctx.fillText(data, bar._model.x + 18, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false
        },
        tooltips: {
          "enabled": false
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 40,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  drawTraditionalAreaChart() {
    var myChart
    document.getElementById('traditionalContainer').innerHTML = "";
    document.getElementById('traditionalContainer').innerHTML = '<canvas id="traditional" style="height: 100%; width: 100%"></canvas>'
    var ctx = document.getElementById("traditional");
    var data = {
      labels: this.traditional[0],
      datasets: [{
        label: this.name,
        data: this.traditional[1],
        backgroundColor: "#33A5C4"
      }]
    }
    let $this = this;
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: function (e) {
            var category = this.getElementsAtEvent(e)[0]._model.datasetLabel;
            var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
            if(category == 'Regions') {
              $this.drillDownToDistricts(activePointLabel);
            }
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: 'Traditional ' + this.title
      },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = numberFormat(dataset.data[index], 0,',',',');
                ctx.fillText(data, bar._model.x + 18, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false
        },
        tooltips: {
          "enabled": false
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 40,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  drawDrySeasonAreaChart() {

    var myChart
    document.getElementById('dryContainer').innerHTML = "";
    document.getElementById('dryContainer').innerHTML = '<canvas id="dry" style="height: 100%; width: 100%"></canvas>'
    var ctx = document.getElementById("dry");
    var data = {
      labels: this.drySeason[0],
      datasets: [{
        label: this.name,
        data: this.drySeason[1],
        backgroundColor: "#EC8326"
      }]
    }
    let $this = this;
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: function (e) {
            var category = this.getElementsAtEvent(e)[0]._model.datasetLabel;
            var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
            if(category == 'Regions') {
              $this.drillDownToDistricts(activePointLabel);
            }
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: 'Dry Season ' + this.title
      },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = numberFormat(dataset.data[index], 0,',',',');
                ctx.fillText(data, bar._model.x + 18, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false
        },
        tooltips: {
          "enabled": false
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 40,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  drawWetSeasonAreaChart() {
    var myChart
    document.getElementById('wetContainer').innerHTML = "";
    document.getElementById('wetContainer').innerHTML = '<canvas id="wet" style="height: 100%; width: 100%"></canvas>'
    var ctx = document.getElementById("wet");
    var data = {
      labels: this.wetSeason[0],
      datasets: [{
        label: this.name,
        data: this.wetSeason[1],
        backgroundColor: "#565656"
      }]
    }
    let $this = this;
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: function (e) {
            var category = this.getElementsAtEvent(e)[0]._model.datasetLabel;
            var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
            if(category == 'Regions') {
              $this.drillDownToDistricts(activePointLabel);
            }
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: 'Improved and Traditional ' + this.title
        },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data =  numberFormat(dataset.data[index], 0,',',',');
                ctx.fillText(data, bar._model.x + 18, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false
        },
        tooltips: {
          "enabled": false
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 40,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }


  onChangeRegion(value) {
    console.log(value)
    if(value != 0) {
      this.getGraphDataByRegion(value, this.index);
      this.getRegionDistricts(value);
    }
    // let other = document.getElementsByClassName('regions-list');
    // for(var i =0; i < other.length; i++) {
    //   other[i].className = 'regions-list';
    // }
    // console.log(document.getElementById('region-' + value).className)
    // event.target.className = 'regions-list active';
  }

  getRegionDistricts(value) {
    console.log(value)
    this.dashsrv.getRegionDistricts(value).subscribe(
      data => {
        this.districts = data
        console.log(this.districts)
      }
    )
  }

  onChangeDistrict(value) {
    if(value != 0) {
      this.getGraphDataByDistrict(value);
    }
  }

  getGraphDataByRegion(value, index) {

    this.dashsrv.getGraphDataByRegion(value, index).subscribe(
      data => {
        console.log(data)
        this.potential    = data[0];
        this.designed     = data[1];
        this.improved     = data[2];
        this.traditional  = data[3];
        this.drySeason    = data[4];
        this.wetSeason    = data[5];

        this.name = "Districts";
        this.drawGraphs()
      }
    )
  }

  getGraphDataByDistrict(value) {

    this.dashsrv.getGraphDataByDistrict(value, this.index).subscribe(
      data => {
        console.log(data)
        this.potential    = data[0];
        this.designed     = data[1];
        this.improved     = data[2];
        this.traditional  = data[3];
        this.drySeason    = data[4];
        this.wetSeason    = data[5];

        this.name = "Districts";
        this.drawGraphs()
      }
    )
  }

  drawGraphs() {
    this.drawPotentialAreaChart()
    this.drawDesignedAreaChart()
    this.drawImprovedAreaChart()
    this.drawTraditionalAreaChart()
    this.drawDrySeasonAreaChart()
    this.drawWetSeasonAreaChart()
  }

  // summary graphs

  getSummary() {
    this.dashsrv.getDataSummary().subscribe(
      data => {
        this.irrigationSummary = data[0];
        this.areaSummary = data[1];
        this.ratioToPotential = data[2];
        this.drawSummaryGraphs();
      }
    )
  }

  drawSummaryGraphs() {
    this.drawSummaryOfIrrigationSchemes();
    this.drawSummaryOfIrrigationArea();
    this.drawRatioToPotentialArea();
  }

  drawSummaryOfIrrigationSchemes() {
    var myChart
    document.getElementById('irrigation_summary_container').innerHTML = "";
    document.getElementById('irrigation_summary_container').innerHTML = '<canvas id="irrigationSummary" style="height: 100%; width: 100%; margin-left: 7px;"></canvas>'
    var ctx = document.getElementById("irrigationSummary");
    var data = {
      labels: this.irrigationSummary[0],
      datasets: [{
        data: this.irrigationSummary[1],
        backgroundColor: ["#F79646", "#4BACC6", "#8064A2", "#9BBB59", "#C0504D", "#4F81BD"]
      }]
    }
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: graphClickEvent,
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: 'Summary of Irrigation Schemes'
        },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data =  numberFormat(dataset.data[index], 0,',',',');
                ctx.fillText(data, bar._model.x + 18, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false
        },
        tooltips: {
          "enabled": true
        },
        scales: {
          yAxes: [{
            display: false,
            scaleLabel: {
              display: false,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 40,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  drawSummaryOfIrrigationArea() {
    var myChart
    document.getElementById('irrigation_area_container').innerHTML = "";
    document.getElementById('irrigation_area_container').innerHTML = '<canvas id="irrigationAreaSummary" style="height: 100%; width: 100%; margin-left: 7px;"></canvas>'
    var ctx = document.getElementById("irrigationAreaSummary");
    var data = {
      labels: this.areaSummary[0],
      datasets: [{
        data: this.areaSummary[1],
        backgroundColor: ["#F79646", "#4BACC6", "#8064A2", "#9BBB59", "#C0504D", "#4F81BD"]
      }]
    }
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: graphClickEvent,
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: 'Summary of Irrigation Area (Ha)'
        },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data =  numberFormat(dataset.data[index], 0,',',',');
                ctx.fillText(data, bar._model.x + 25, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false
        },
        tooltips: {
          "enabled": true
        },
        scales: {
          yAxes: [{
            display: false,
            scaleLabel: {
              display: false,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 55,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  drawRatioToPotentialArea() {
    var myChart
    document.getElementById('ratio_area_container').innerHTML = "";
    document.getElementById('ratio_area_container').innerHTML = '<canvas id="ratioAreaSummary" style="height: 100%; width: 100%; margin-left: 7px;"></canvas>'
    var ctx = document.getElementById("ratioAreaSummary");
    var data = {
      labels: this.ratioToPotential[0],
      datasets: [{
        data: this.ratioToPotential[1],
        backgroundColor: ["#4BACC6", "#8064A2", "#9BBB59", "#C0504D", "#4F81BD"]
      }]
    }
    myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: data,
      options: {
        onClick: graphClickEvent(this.name, ctx),
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: 'Ratio to Potential Irrigation Area'
        },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data =  dataset.data[index] + '%';
                ctx.fillText(data, bar._model.x + 20, bar._model.y + 8);
              });
            });
          }
        },
        legend: {
          "display": false
        },
        tooltips: {
          "enabled": true
        },
        scales: {
          yAxes: [{
            display: false,
            scaleLabel: {
              display: false,
              labelString: this.name
            },
            gridLines: {
              display: false
            },
            ticks: {
              max: Math.max(...data.datasets[0].data) + 10,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 50,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  drillDownToDistricts(region) {
    console.log(region)
    this.dashsrv.getRegionId(region).subscribe(
      data => {
        this.regionId = data;
        console.log(this.regionId)
        this.dashsrv.getGraphDataByRegion(this.regionId, this.index).subscribe(
          data => {
            this.potential    = data[0];
            this.designed     = data[1];
            this.improved     = data[2];
            this.traditional  = data[3];
            this.drySeason    = data[4];
            this.wetSeason    = data[5];

            this.name = "Districts";
            this.drawGraphs()
          }
        )

        }
      )
  }
}


function graphClickEvent(event, array){
  console.log(event)
  if(array[0]){
     console.log(array[0]._view.label);
  }
}

