import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
const url1 = apiUrl + '/aims/indicator-categories/';
const url2 = apiUrl + '/aims/items_by_indicator_category/';
const url3 = apiUrl + '/aims/livestock-values/';
@Injectable({
  providedIn: 'root'
})
export class LivestockService {

  constructor(private http: HttpClient) { }
  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Requested-Width': 'XMLHttpRequeest',
    }
  );

  getMainMenu(type) {
    return this.http.get(url1 + type, {headers: this.headers});
  }

  getItemsByIndicatorCategory(menuId) {
    return this.http.get(url2 + menuId, {headers: this.headers});
  }

  getNumberofAnimalsValues(id, type) {
    return this.http.get(url3 + id + '/' + type, {headers: this.headers});
  }
}
