import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);

@Component({
  selector: 'app-prodval-chart',
  templateUrl: './prodval-chart.component.html',
  styleUrls: ['./prodval-chart.component.css']
})
export class ProdvalChartComponent implements OnInit {
  @Input() values: any;
  @Input() title: any;
  @Input() itemName: any;


  Highcharts = Highcharts;
  chartOptions: {};
  chartConstructor = 'chart';
  showChart = false;
  constructor() { }

  ngOnInit() {
    console.log(this.itemName)
    this.showChart =this.values > 0;
    this.drawChart(this.values, this.title)
  }
  drawChart(values, title) {
    this.chartOptions = {
      chart: {
          type: 'column',
      },
      title: {
          text: 'Average Farm Gate Price Vs Average Consumer Price'
      },
      subtitle: {
          text: this.itemName ? '(for ' + this.itemName + ' ) '  : ''
      },
      accessibility: {
          announceNewData: {
              enabled: true
          }
      },
      xAxis: {
          type: 'category',
          crosshair: true
      },
      yAxis: {
            min: 0,
        //   minorTickInterval: 0.1,
        //   type: 'logarithmic',
          title: {
              text: 'Total Prices'
          },
          
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      legend: {
        enabled: false
    },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              // borderWidth: 0,
              dataLabels: {
                enabled: false
            },
            borderRadius: '0.5%',
            groupPadding: 0.25
          }
      },
      series: values[0],
    drilldown: {
        breadcrumbs: {
            position: {
                align: 'right'
            }
        },
        series: values[1]
      }
  };
}
}
