import { ProfileComponent } from './modules/shared/components/profile/profile.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HttpClient} from '@angular/common/http';

import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ShContextMenuModule} from 'ng2-right-click-menu';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddDashboardMainMenuComponent } from './modules/dashboard-definition/add-dashboard-main-menu/add-dashboard-main-menu.component';
import { DashboardIndexComponent } from './modules/dashboard-definition/dashboard-index/dashboard-index.component';
import { MainMenuContentComponent } from './modules/dashboard-definition/main-menu-content/main-menu-content.component';
import { AddCropComponent } from './modules/dashboard-definition/add-crop/add-crop.component';
import { CropPriceDetailsComponent } from './modules/dashboard-definition/crop-price-details/crop-price-details.component';
import { CropTradingInfoComponent } from './modules/dashboard-definition/crop-trading-info/crop-trading-info.component';
import { SharedModule } from './modules/shared/shared.module';
import { LoginComponent } from './auth/login/login.component';
import { AuthModule } from './auth/auth.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DashboardListComponent } from './modules/dashboard-listing/dashboard-list/dashboard-list.component';
import { AddDashboardComponent } from './modules/dashboard-listing/add-dashboard/add-dashboard.component';
import { AddMarketComponent } from './modules/dashboard-definition/add-market/add-market.component';
import { AddProductionComponent } from './modules/dashboard-definition/add-production/add-production.component';
import { ImportFileComponent } from './modules/dashboard-definition/import-file/import-file.component';
import { DeleteDialogComponent } from './modules/dashboard-definition/delete-dialog/delete-dialog.component';
import { CostsComponent } from './modules/settings/costs/costs.component';
import { AddCostComponent } from './modules/settings/add-cost/add-cost.component';
import { ChangeCurrencyComponent } from './modules/dashboard-definition/change-currency/change-currency.component';
import { FirstviewComponent } from './modules/first/firstview/firstview.component';
import { AboutUsComponent } from './modules/first/about-us/about-us.component';
import { AddSaleComponent } from './modules/dashboard-definition/add-sale/add-sale.component';
import { CapacityChartComponent } from './modules/warehouse-charts/capacity-chart/capacity-chart.component';
import { MatCardModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AddSeasonComponent } from './modules/dashboard-definition/add-season/add-season.component';
import { AddUsersComponent } from './modules/dashboard-definition/add-users/add-users.component';
import { AddRolesComponent } from './modules/dashboard-definition/add-roles/add-roles.component';
import { AddUnitMeasureComponent } from './modules/dashboard-definition/add-unit-measure/add-unit-measure.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { ModalModule } from 'ngx-bootstrap/modal';
import { MainIrrigationContentComponent } from './modules/dashboard-definition/main-irrigation-content/main-irrigation-content.component';
import { MainProductionDashbComponent } from './modules/dashboard-definition/main-production-dashb/main-production-dashb.component';
import { CropProductionInfoComponent } from './modules/dashboard-definition/crop-production-info/crop-production-info.component';
import { CropProductionInfoGraphComponent } from './modules/dashboard-definition/crop-production-info-graph/crop-production-info-graph.component';
import { HighchartsChartModule} from 'highcharts-angular';
import { UsersComponent } from './modules/dashboard-definition/users/users.component';
import { MainExtensionDashbComponent } from './modules/dashboard-definition/main-extension-dashb/main-extension-dashb.component';
import { MainMechanizationDashbComponent } from './modules/dashboard-definition/main-mechanization-dashb/main-mechanization-dashb.component';
import { UsdTshTodayComponent } from './modules/dashboard-definition/usd-tsh-today/usd-tsh-today.component';
import { IndexComponent } from './modules/integration/index/index.component';
import { MainHeaderComponent } from './modules/integration/components/main-header/main-header.component';
import { MainFooterComponent } from './modules/integration/components/main-footer/main-footer.component';
import { MainLoginComponent } from './modules/integration/main-login/main-login.component';
import { ExtServicesComponent } from './modules/integration/extension-services/ext-services/ext-services.component';
import { FarmerRegistrationComponent } from './modules/integration/farmer-registration/farmer-registration.component';
import { AskQuestionComponent } from './modules/integration/extension-services/ask-question/ask-question.component';
import { ExtOfficersRegistrationComponent } from './modules/integration/extension-services/ext-officers-registration/ext-officers-registration.component';
import { SellProductComponent } from './modules/integration/emarket/sell-product/sell-product.component';
import { MarketPlaceComponent } from './modules/integration/emarket/market-place/market-place.component';
import { MainWeatherDashbComponent } from './modules/dashboard-definition/main-weather-dashb/main-weather-dashb.component';
import { SignupComponent } from './auth/signup/signup.component';
import { WaitingApprovalComponent } from './auth/waiting-approval/waiting-approval.component';
import { WeatherTodayComponent } from './modules/integration/weather-today/weather-today.component';
import { WeatherFutureComponent } from './modules/integration/weather-future/weather-future.component';
import { StorageComponent } from './modules/dashboard-definition/stocks/storage/storage.component';
import { TradingComponent } from './modules/dashboard-definition/stocks/trading/trading.component';
import { HelpCenterComponent } from './modules/integration/help-center/help-center.component';
import { MainAimsDashbComponent } from './modules/dashboard-definition/aims/main-aims-dashb/main-aims-dashb.component';
import { CropsDashbComponent } from './modules/dashboard-definition/aims/crops/crops-dashb/crops-dashb.component';
import { CropsMenuComponent } from './modules/dashboard-definition/aims/crops/crops-menu/crops-menu.component';
import { CropValuesComponent } from './modules/dashboard-definition/aims/crops/crop-values/crop-values.component';
import { ItemsMenuComponent } from './modules/dashboard-definition/aims/items/items-menu/items-menu.component';
import { ItemsDashbComponent } from './modules/dashboard-definition/aims/items/items-dashb/items-dashb.component';
import { ItemsValuesComponent } from './modules/dashboard-definition/aims/items/items-values/items-values.component';
import { LandcoverDashbComponent } from './modules/dashboard-definition/aims/landcover/landcover-dashb/landcover-dashb.component';
import { LandcoverValuesComponent } from './modules/dashboard-definition/aims/landcover/landcover-values/landcover-values.component';
import { AniplantDashbComponent } from './modules/dashboard-definition/aims/animal-plant-health/aniplant-dashb/aniplant-dashb.component';
import { AniplantMenuComponent } from './modules/dashboard-definition/aims/animal-plant-health/aniplant-menu/aniplant-menu.component';
import { AniplantValuesComponent } from './modules/dashboard-definition/aims/animal-plant-health/aniplant-values/aniplant-values.component';
import { AniplantMenu2Component } from './modules/dashboard-definition/aims/animal-plant-health/aniplant-menu2/aniplant-menu2.component';
import { LivestockDashbComponent } from './modules/dashboard-definition/aims/livestock/livestock-dashb/livestock-dashb.component';
import { LivestockMenuComponent } from './modules/dashboard-definition/aims/livestock/livestock-menu/livestock-menu.component';
import { LivestockValuesComponent } from './modules/dashboard-definition/aims/livestock/livestock-values/livestock-values.component';
import { NationalAccountDashbComponent } from './modules/dashboard-definition/aims/national-accounts/national-account-dashb/national-account-dashb.component';
import { NationalAccountValuesComponent } from './modules/dashboard-definition/aims/national-accounts/national-account-values/national-account-values.component';
import { PublicFinanceDashbComponent } from './modules/dashboard-definition/aims/public-finances/public-finance-dashb/public-finance-dashb.component';
import { PublicFinanceValuesComponent } from './modules/dashboard-definition/aims/public-finances/public-finance-values/public-finance-values.component';
import { SocioeconomyValuesComponent } from './modules/dashboard-definition/aims/socioeconomic/socioeconomy-values/socioeconomy-values.component';
import { SocioeconomyDashbComponent } from './modules/dashboard-definition/aims/socioeconomic/socioeconomy-dashb/socioeconomy-dashb.component';
import { SocioeconomyMenuComponent } from './modules/dashboard-definition/aims/socioeconomic/socioeconomy-menu/socioeconomy-menu.component';
import { SocioeconomyChartComponent } from './modules/dashboard-definition/aims/socioeconomic/socioeconomy-chart/socioeconomy-chart.component';
import { TradequantityMainComponent } from './modules/dashboard-definition/aims/tradequantity/tradequantity-main/tradequantity-main.component';
import { TradequantityDashbComponent } from './modules/dashboard-definition/aims/tradequantity/crops/tradequantity-dashb/tradequantity-dashb.component';
import { TradequantityMenuComponent } from './modules/dashboard-definition/aims/tradequantity/crops/tradequantity-menu/tradequantity-menu.component';
import { AnimalsDashbComponent } from './modules/dashboard-definition/aims/tradequantity/animalsprod/animals-dashb/animals-dashb.component';
import { AnimalnumberDashbComponent } from './modules/dashboard-definition/aims/tradequantity/animalnumber/animalnumber-dashb/animalnumber-dashb.component';
import { AquafishDashbComponent } from './modules/dashboard-definition/aims/tradequantity/aquafish/aquafish-dashb/aquafish-dashb.component';
import { AquafishMenuComponent } from './modules/dashboard-definition/aims/tradequantity/aquafish/aquafish-menu/aquafish-menu.component';
import { AgriproductMenuComponent } from './modules/dashboard-definition/aims/tradequantity/agriproducts/agriproduct-menu/agriproduct-menu.component';
import { AgriproductDashbComponent } from './modules/dashboard-definition/aims/tradequantity/agriproducts/agriproduct-dashb/agriproduct-dashb.component';
import { AnimalnumberMenuComponent } from './modules/dashboard-definition/aims/tradequantity/animalnumber/animalnumber-menu/animalnumber-menu.component';
import { AnimalsMenuComponent } from './modules/dashboard-definition/aims/tradequantity/animalsprod/animals-menu/animals-menu.component';
import { ProdvalMainComponent } from './modules/dashboard-definition/aims/productionvalue/prodval-main/prodval-main.component';
import { ProdvalAnimalprodComponent } from './modules/dashboard-definition/aims/productionvalue/prodval-animalprod/prodval-animalprod.component';
import { ProdvalAquafishComponent } from './modules/dashboard-definition/aims/productionvalue/prodval-aquafish/prodval-aquafish.component';
import { ProdvalCropsComponent } from './modules/dashboard-definition/aims/productionvalue/prodval-crops/prodval-crops.component';
import { ProdvalForestryComponent } from './modules/dashboard-definition/aims/productionvalue/prodval-forestry/prodval-forestry.component';
import { ProdvalAnimalnumberComponent } from './modules/dashboard-definition/aims/productionvalue/prodval-animalnumber/prodval-animalnumber.component';
import { ProdvalChartComponent } from './modules/dashboard-definition/aims/productionvalue/prodval-chart/prodval-chart.component';
import { DashbRegisterComponent } from './auth/dashb-register/dashb-register.component';
import { DashbLoginComponent } from './auth/dashb-login/dashb-login.component';


const path = '.';
// const path = 'agri-dashboard/public';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, path + '/assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    AddDashboardMainMenuComponent,
    DashboardIndexComponent,
    MainMenuContentComponent,
    AddCropComponent,
    CropPriceDetailsComponent,
    CropTradingInfoComponent,
    LoginComponent,
    SignupComponent,
    DashbRegisterComponent,
    DashbLoginComponent,
    WaitingApprovalComponent,
    DashboardListComponent,
    AddDashboardComponent,
    AddMarketComponent,
    AddProductionComponent,
    ImportFileComponent,
    DeleteDialogComponent,
    CostsComponent,
    AddCostComponent,
    ChangeCurrencyComponent,
    FirstviewComponent,
    AboutUsComponent,
    AddSaleComponent,
    CapacityChartComponent,
    AddSeasonComponent,
    AddUsersComponent,
    AddRolesComponent,
    AddUnitMeasureComponent,
    MainIrrigationContentComponent,
    MainProductionDashbComponent,
    CropProductionInfoComponent,
    CropProductionInfoGraphComponent,
    UsersComponent,
    MainExtensionDashbComponent,
    MainMechanizationDashbComponent,
    UsdTshTodayComponent,
    IndexComponent,
    MainHeaderComponent,
    MainFooterComponent,
    MainLoginComponent,
    ExtServicesComponent,
    FarmerRegistrationComponent,
    AskQuestionComponent,
    ExtOfficersRegistrationComponent,
    SellProductComponent,
    MarketPlaceComponent,
    MainWeatherDashbComponent,
    WeatherTodayComponent,
    WeatherFutureComponent,
    StorageComponent,
    TradingComponent,
    HelpCenterComponent,
    MainAimsDashbComponent,
    CropsDashbComponent,
    CropsMenuComponent,
    CropValuesComponent,
    ItemsMenuComponent,
    ItemsDashbComponent,
    ItemsValuesComponent,
    LandcoverDashbComponent,
    LandcoverValuesComponent,
    AniplantDashbComponent,
    AniplantMenuComponent,
    AniplantValuesComponent,
    AniplantMenu2Component,
    LivestockDashbComponent,
    LivestockMenuComponent,
    LivestockValuesComponent,
    NationalAccountDashbComponent,
    NationalAccountValuesComponent,
    PublicFinanceDashbComponent,
    PublicFinanceValuesComponent,
    SocioeconomyValuesComponent,
    SocioeconomyDashbComponent,
    SocioeconomyMenuComponent,
    SocioeconomyChartComponent,
    TradequantityMainComponent,
    TradequantityDashbComponent,
    TradequantityMenuComponent,
    AnimalsDashbComponent,
    AnimalsMenuComponent,
    AnimalnumberMenuComponent,
    AnimalnumberDashbComponent,
    AquafishDashbComponent,
    AquafishMenuComponent,
    AgriproductMenuComponent,
    AgriproductDashbComponent,
    ProdvalMainComponent,
    ProdvalAnimalprodComponent,
    ProdvalAquafishComponent,
    ProdvalCropsComponent,
    ProdvalForestryComponent,
    ProdvalAnimalnumberComponent,
    ProdvalChartComponent
  ],
  imports: [

    HighchartsChartModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    AuthModule,
    MatMenuModule,
    HttpClientModule,
    MatSnackBarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    ShContextMenuModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCardModule,
    ModalModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },DatePipe
   ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddDashboardMainMenuComponent,
    AddCropComponent,
    AddDashboardComponent,
    AddMarketComponent,
    AddProductionComponent,
    AddSaleComponent,
    ImportFileComponent,
    DeleteDialogComponent,
    AddCostComponent,
    ChangeCurrencyComponent,
    AddSeasonComponent,
    AddUsersComponent,
    AddRolesComponent,
    AddUnitMeasureComponent,
    ProfileComponent,
    UsdTshTodayComponent
  ]
})
export class AppModule { }
