import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SettingService } from '../setting.service';
import { AddCostComponent } from '../add-cost/add-cost.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.css'],
  providers: [SettingService]
})
export class CostsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['crop', 'transport', 'profit'];
  dataSource;
  costs;
  constructor(
    private settingService: SettingService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getCosts();
  }

  getCosts() {
    this.settingService.getCosts().subscribe(
      (data) => {
        this.costs = data;
        this.dataSource = new MatTableDataSource(this.costs);
        this.dataSource.paginator = this.paginator;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddCost() {
    const dialogRef = this.dialog.open(AddCostComponent, {
      width: '550px',
      height: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getCosts();
    });
  }

}
