import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
const apiUrl = environment.apiUrl;
const url1 = apiUrl + '/login';
const url2 = apiUrl + '/signup';
const url3 = apiUrl + '/agri-dashboard/register';
const url4 = apiUrl + '/agri-dashboard/login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  path = '';
  // path = 'agri-dashboard/public';
  headers = new HttpHeaders({'Content-Type': 'application/json', 'X-Requested-Width': 'XMLHttpRequeest'});

  constructor(private http: HttpClient) {
    localStorage.setItem('currency', 'Tzs');
   }

  login(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }
  setCredentials(id, username, role, status) {
    if(role == null) {
      role = ''
    } else {
      role = role.role
    }
    localStorage.setItem('username', username);
    localStorage.setItem('id', id);
    localStorage.setItem('role', role);
    localStorage.setItem('status', status);
  }


  setUserCredentials(id, email) {
    localStorage.setItem('dashb_email', email);
    localStorage.setItem('dashb_user_id', id);
    
  }

  get isAdmin() {
    const role = localStorage.getItem('role');
    if (role === 'admin') {
       return true;
    }
    return false;
  }
  get isWarehouse() {
    const role = localStorage.getItem('role');
    if (role === 'warehouse') {
       return true;
    }
    return false;
  }

  get isIrrigation() {
    const role = localStorage.getItem('role');
    if (role === 'irrigation') {
       return true;
    }
    return false;
  }

  get isMarketing() {
    const role = localStorage.getItem('role');
    if (role === 'marketing') {
       return true;
    }
    return false;
  }
  get isMechanization() {
    const role = localStorage.getItem('role');
    if (role === 'mechanization') {
       return true;
    }
    return false;
  }
  get isAuth() {
    if (this.getUserId() === null) {
      return false;
    } else {
      return true;
    }
  }
  removeCredentials() {
    // localStorage.removeItem('username');
    // localStorage.removeItem('id');
    // localStorage.removeItem('role');
    // localStorage.removeItem('status');
    localStorage.clear()
  }
  getUsername() {
    return localStorage.getItem('username');
  }
  getId() {
    return localStorage.getItem('id');
  }

  getUserId() {
    return localStorage.getItem('dashb_user_id');
  }

  setCurrency(value) {
    localStorage.removeItem('currency');
    localStorage.setItem('currency', value);
  }

  get isTzs() {
    if (this.getCurrency() === 'Tzs') {
      return true;
    } else {
      return false;
    }
  }

  getCurrency() {
    return localStorage.getItem('currency');
  //  if (this._currency === 'Tzs') {
  //    return true;
  //  } else {
  //    return false;
  //  }
  }


  setLanguage(value) {
    // localStorage.removeItem('language');
    localStorage.setItem('language', value);
    window.location.reload()
  }


  getLanguage() {
    return localStorage.getItem('language');
  }


  // for integration
  setCurrentApp(app) {
    localStorage.setItem('app', app);
  }

  setCurrentAppItem(app_description) {
    localStorage.setItem('app_description', app_description);
  }

  get isKilimo() {
    const app = localStorage.getItem('app');
    if (app === '1') {
       return true;
    }
    if (app === '2') {
      return false;
    }
  }

  get isDashb() {
    const app = localStorage.getItem('app');
    if (app === '3') {
       return true;
    }
    return false;
  }

  signup(data) {
    return this.http.post(url2, data, {headers: this.headers});
  }


  get isApproved() {
    if (localStorage.getItem('status') === 'pending') {
      return false;
    } else {
      return true;
    }
  }


  register(data) {
    return this.http.post(url3, data, {headers: this.headers});
  }

  signin(data) {
    return this.http.post(url4, data, {headers: this.headers});
  }
}
