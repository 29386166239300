import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { DashboardDefinitionService } from '../dashboard-definition.service';

@Component({
  selector: 'app-usd-tsh-today',
  templateUrl: './usd-tsh-today.component.html',
  styleUrls: ['./usd-tsh-today.component.css']
})
export class UsdTshTodayComponent implements OnInit {

  
  currencyForm = this.fb.group({
    tsh: ['', Validators.required],
   });
  constructor(
    private fb: FormBuilder,
    private dashService : DashboardDefinitionService,
    private dialogRef: MatDialogRef<UsdTshTodayComponent>,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit() {
    
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  onSubmit() {
    if(this.currencyForm.valid) {
      this.dashService.usdToTsh(this.currencyForm.value).subscribe(
        data => {
          this.snackbar.open('success', data.toString(), {
            duration: 5000
          });
          this.onNoClick();
        },
        error => {
          console.log(error);
        }
      )

    }
  }



  onNoClick(): void {
    this.dialogRef.close();
  }

}
