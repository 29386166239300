import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  cropId;
  type;
}

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.css']
})
export class ImportFileComponent implements OnInit {
  file: File;
  isUploading = false;
  path;

  constructor(
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ImportFileComponent>,
    private snackBar: MatSnackBar,
    private dashsrvc: DashboardDefinitionService,
  ) { }

  ngOnInit() {
    this.path = this.dashsrvc.path;
   }

  exportProductionSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.path + 'assets/production_import_sample.xlsx';
    link.download = 'production_import_sample.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  // exportSalesSample() {
  //   const link = document.createElement('a');
  //   link.setAttribute('type', 'hidden');
  //   link.href = this.path + 'assets/sales_import_sample.xlsx';
  //   link.download = 'sales_import_sample.xlsx';
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // }

  exportSalesSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.path + 'assets/amount_sold_import_sample.xlsx';
    link.download = 'amount_sold_import_sample.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  exportLocalSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.path + 'assets/add_local_market_prices_sample.xlsx';
    link.download = 'add_local_market_prices_sample.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  exportInternationalSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.path + 'assets/add_international_market_prices_sample.xlsx';
    link.download = 'add_international_market_prices_sample.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  exportAllMarketsSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.path + 'assets/import_all_markets.xlsx';
    link.download = 'import_all_markets_sample.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  exportIrrigationsSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.path + 'assets/import_irrigations.xlsx';
    link.download = 'import_irrigations_sample.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  exportMechanizationSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.path + 'assets/import_mechanization.xlsx';
    link.download = 'import_mechanization_sample.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  exportConsumptionSample() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.path + 'assets/import_consumption.xlsx';
    link.download = 'import_consumption_sample.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChooseFile(event) {
    this.file = event.target.files[0];
  }

   onImportProduction() {
    this.isUploading = true;
    const url = environment.apiUrl + '/import-production';
    const data: FormData = new FormData();
    data.append('fileToUpload', this.file);
    data.append('cropId', this.data.cropId);
    this.http.post(url, data).subscribe(
    response => {
     this.isUploading = false;
     this.onNoClick();
    },
   error => {
    this.snackBar.open('File failed to import', 'Try again, with format as given sample', {
      duration: 4000,
    });
   });
   }

   onImportSales() {
    this.isUploading = true;
    const url = environment.apiUrl + '/import-sales';
    const data: FormData = new FormData();
    data.append('fileToUpload', this.file);
    data.append('cropId', this.data.cropId);
    this.http.post(url, data).subscribe(
    response => {
     this.isUploading = false;
     this.onNoClick();
    },
   error => {
    console.log(error);
    this.isUploading = false;
    this.snackBar.open('File failed to import', 'Try again, with format as given sample', {
      duration: 4000,
    });
   });
   }

   onImportPrices() {
     console.log(this.data.type);
     console.log(this.data.cropId);
     this.isUploading = true;
     const url = environment.apiUrl + '/import-prices';
     const data: FormData = new FormData();
     data.append('fileToUpload', this.file);
     data.append('cropId', this.data.cropId);
     data.append('type', this.data.type);
     this.http.post(url, data).subscribe(
    response => {
     this.isUploading = false;
     this.onNoClick();
    },
   error => {
    console.log(error);
    this.isUploading = false;
    this.snackBar.open('File failed to import', 'Try again, with format as given sample', {
      duration: 4000,
    });
   });
   }


   onImportAllMarkets() {
     this.isUploading = true;
     const url = environment.apiUrl + '/import-all-markets';
     const data: FormData = new FormData();
     data.append('fileToUpload', this.file);
     this.http.post(url, data).subscribe(
       response => {
      console.log('response')
     this.isUploading = false;
     this.onNoClick();
      // window.location.reload();
    },
   error => {
    console.log(error);
    this.isUploading = false;
    this.snackBar.open('File failed to import', error.error.message, {
      duration: 10000,

    });
   });
   }

   onImportIrrigation() {
    this.isUploading = true;
    const url = environment.apiUrl + '/import-irrigation';
    const data: FormData = new FormData();
     data.append('fileToUpload', this.file);
     this.http.post(url, data).subscribe(
    response => {
     this.isUploading = false;
     this.onNoClick();
      window.location.reload();
    },
   error => {
    console.log(error);
    this.isUploading = false;
    this.snackBar.open('File failed to import', error.error.message, {
      duration: 10000,

    });
   });
   }

   onImportMechanization() {
    this.isUploading = true;
    const url = environment.apiUrl + '/import-mechanization';
    const data: FormData = new FormData();
     data.append('fileToUpload', this.file);
     this.http.post(url, data).subscribe(
    response => {
     this.isUploading = false;
     this.onNoClick();
      // window.location.reload();
    },
   error => {
    console.log(error);
    this.isUploading = false;
    this.snackBar.open('File failed to import', error.error.message, {
      duration: 10000,

    });
   });
   }

   onImportConsumption() {
    this.isUploading = true;
    const url = environment.apiUrl + '/import-consumptions';
    const data: FormData = new FormData();
     data.append('fileToUpload', this.file);
     data.append('cropId', this.data.cropId);
     this.http.post(url, data).subscribe(
    response => {
     this.isUploading = false;
     this.onNoClick();
    //  this.ngOnInit();
      // window.location.reload();
    },
   error => {
    console.log(error);
    this.isUploading = false;
    this.snackBar.open('File failed to import', error.error.message, {
      duration: 10000,

    });
   });
   }

}
