import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/exporting';
import { ExcelService } from './../../../../excel.service';
import { AuthService } from './../../../../auth/auth.service';
import { DatePipe } from '@angular/common';
import { DashboardDefinitionService } from './../../../dashboard-definition/dashboard-definition.service';
HC_exporting(Highcharts);

@Component({
  selector: 'app-area-graph',
  templateUrl: './area-graph.component.html',
  styleUrls: ['./area-graph.component.css']
})
export class AreaGraphComponent implements OnInit {
  Highcharts = Highcharts;
  @Input() graphType;
  private _data;
  convertedData: any[] = [];
  graphData: any[] = [];

  get data() {
    return this._data;
  }
  @Input() set data(value) {
    this._data = value;
    this.areaChart();
  }

  @Input() markets: [];

  chartOptions: {};

  type = 'column';

// data2: any = [
//   {

//       "CATEGORYID": 1,
//       "CATEGORYNAME": "BOOKS",
//       "DESCRIPTION": "It contains all types of books",
//       "IMAGE": "Books",
//       "STATUS": "TRUE"
//   },
//   {

//       "CATEGORYID": 2,
//       "CATEGORYNAME": "EBOOKS",
//       "DESCRIPTION": "It contains all types of ebooks",
//       "IMAGE": "Ebooks",
//       "STATUS": "TRUE"
//   },
//   {

//       "CATEGORYID": 3,
//       "CATEGORYNAME": "Bookss",
//       "DESCRIPTION": "DESCRIPTION",
//       "IMAGE": "IMAGE",
//       "STATUS": "TRUE"
//   }
// ]

  constructor(
    private excelService:ExcelService,
    public authService: AuthService,
    public datepipe: DatePipe,
    private dashsvc: DashboardDefinitionService
    ) { }

  ngOnInit() {
  }

   areaChart() {
    this.chartOptions = {
      chart: {
        zoomType: 'x',
        type: this.graphType
      },
      title: {
          text: ''
      },
      subtitle: {
        text: document.ontouchstart === undefined ?
            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
      },
      xAxis: {
          type: 'datetime',
          // tickmarkPlacement: 'on',
          // title: {
          //     enabled: false
          //     text: 'R'
          // },
          // crosshair: true
      },
      yAxis: {
          // min: 10,
          title: {
              text: 'Price'
          },
          labels: {
              formatter() {
                  return this.value;
              }
          }
      },
      legend: {
        enabled: false
      },
      tooltip: {
          split: false,
          borderColor: '#116B38'
          // valueSuffix: ' measure'
      },
      credits: {
      enabled: false
      },
      exporting: {
        enabled: true
      },
      // plotOptions: {
      //     area: {
      //         stacking: 'normal',
      //         lineColor: '#666666',
      //         lineWidth: 1,
      //         marker: {
      //             lineWidth: 1,
      //             lineColor: '#666666'
      //         }
      //     }
      // },
      plotOptions: {
        line: {
           color: '#116B38',
           marker: {
            radius: 0,
            // lineColor: '#116B38',
            // fillColor: '#116B38'
        },
        },
        area: {
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, '#116B38'],
                    [1, '#b4cebf']
                ]
            },
            lineColor: '#116B38',
            marker: {
                radius: 3,
                lineColor: '#116B38',
                fillColor: '#116B38'
            },
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 1
                }
            },
            threshold: null
        }
    },
    rangeSelector: {
       buttonPosition: {
          align: 'left',
          x: 0,
          y: 0
       },
    buttons: [{
        type: 'month',
        count: 1,
        text: '1m'
    }, {
        type: 'month',
        count: 3,
        text: '3m'
    }, {
        type: 'month',
        count: 6,
        text: '6m'
    }, {
        type: 'ytd',
        text: 'YTD'
    }, {
        type: 'year',
        count: 1,
        text: '1y'
    }, {
        type: 'all',
        text: 'All'
    }],
    enabled: true,
    floating: false
    },
      series: [{
        // type: this.graphType,
        name: 'Price',
        color: '#116B38',
        data: this.data[0],

      }, {
        // type: this.graphType,
        name: 'Price',
        color: '#E20000',
        data: this.data[1],
      }, {
        // type: this.graphType,
        name: 'Price',
        color: '#F67C07',
        data: this.data[2],
      }, {
        // type: this.graphType,
        name: 'Price',
        color: '#3390FF',
        data: this.data[3],
      }, {
        // type: this.graphType,
        // title: 'yup',
        name: 'Price',
        color: '#F7F700',
        data: this.data[4],
      }
    ]
  };
    HC_exporting(Highcharts);
    setTimeout(() => {
    window.dispatchEvent(
      new Event('resize')); }, 300);
  }

  exportAsXLSX():void {
    this._data.forEach(element => {
      this.convertedData = [];
      this.convertedData.push(this.datepipe.transform(element[0], 'dd-MM-yyyy'))
      this.convertedData.push(element[1])
      this.graphData.push(this.convertedData)
      console.log(this.convertedData)
    });
    // console.log(this.graphData)
    // this.excelService.exportAsExcelFile(this.graphData, 'historical_prices_in_dar_es_salaam');
 }

}
