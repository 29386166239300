import { Component, OnInit } from '@angular/core';
import { HelpCenterService } from 'src/app/help-center.service';
import { FormGroup, FormBuilder ,Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.css']
})
export class HelpCenterComponent implements OnInit {
  registerForm: FormGroup;
  regions:{};
  districts:{};
  wards:{};
  villages:{};

  services:any;
  sectors:any;
  submitted = false;
  dataSaved = false;
  constructor(private helpcenterService:HelpCenterService,private formBuilder: FormBuilder,private router: Router) {   
  }

  ngOnInit() {
    this.helpcenterService.getServices().subscribe((services_data:any)=>{
      this.services = services_data;
      console.log(this.services);
    });
    this.helpcenterService.getSectors().subscribe((sectors:any)=>{
      this.sectors = sectors;
      console.log(this.sectors);
    });
    this.helpcenterService.getRegions().subscribe(
      data => {this.regions = data;
       console.log(this.regions);   
      }
     );
    this.registerForm = new FormGroup({
      region : new FormControl(''),
      district : new FormControl(''),
      ward:new FormControl(''),
      village: new FormControl('')
    });
    // validation
    this.registerForm = this.formBuilder.group({
      service_id: ['', Validators.required],
      sector_id:['',Validators.required],
      full_name: ['', Validators.required],
      phone: ['', Validators.required],
      question: ['', Validators.required],
      email:['',Validators.email],
      region_id:['',Validators.required],
      district_id:['',Validators.required],
      ward_id:['',Validators.required]

    });
  }

  suggest_districts(regionId):void{
    if(regionId){
      this.helpcenterService.getDistricts(regionId).subscribe(
        data => {
          this.districts = data;
          console.log(this.districts);
        }
       );
    }
    else{
      this.districts = null;
    }
  }

  suggest_wards(districtId):void{
    if(districtId){
      this.helpcenterService.getWards(districtId).subscribe(
        data => {
          this.wards = data;
          console.log(this.wards);
        }
       );
    }
    else{
      this.wards = null;
    }
  }

  suggest_villages(wardId):void{
    if(wardId){
      this.helpcenterService.getVillages(wardId).subscribe(
        data => {
          this.villages = data;
          console.log(this.villages);
        }
       );
    }
    else{
      this.villages = null;
    }
  }

saveData() {
this.submitted = true;
if(this.registerForm.invalid){
  this.markFormGroupTouched(this.registerForm);
  return;
}
 // Prepare data object from form values
      const data = {
        full_name: this.registerForm.value.full_name,
        service_id: this.registerForm.value.service_id,
        sector_id:this.registerForm.value.sector_id,
        phone:this.registerForm.value.phone,
        question:this.registerForm.value.question,
        email:this.registerForm.value.email,
        region_id:this.registerForm.value.region_id,
        district_id:this.registerForm.value.district_id,
        ward_id:this.registerForm.value.ward_id,
        village_id:this.registerForm.value.village_id,
        location:this.registerForm.value.location,

      };
      this.helpcenterService.saveData(data).subscribe(
        response => {
          console.log('Data saved successfully');
          this.dataSaved = true;
        },
        error => {
          console.error('Failed to save data', error);
          this.dataSaved = false;
        }
      );  
      this.registerForm.reset();
  }
  

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
  

  goBack()
  { 
     this.router.navigate(['']);
   }
  
}
