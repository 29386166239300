import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';

@Component({
  selector: 'app-add-unit-measure',
  templateUrl: './add-unit-measure.component.html',
  styleUrls: ['./add-unit-measure.component.css']
})
export class AddUnitMeasureComponent implements OnInit {

  unitMeasureForm = this.fb.group({
    unit_measure: ['', Validators.required],

   });
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddUnitMeasureComponent>,
    private dashService: DashboardDefinitionService) { }

  ngOnInit() {
  }

  getErrorMessage() {
    return 'You must enter a unit measure';
  }

  onSubmit() {
    if(this.unitMeasureForm.valid) {
      this.dashService.addUnitMeasure(this.unitMeasureForm.value).subscribe(
        (data) => {
          if(data == "unit measure exists") {
            console.log(data)
          }
          else {
            this.onNoClick();
          }
        },
        error => {
          console.log(error);
        }
      )
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}
