import { Component, Input, OnInit } from '@angular/core';
import { AimsService } from '../../aims.service';

@Component({
  selector: 'app-aniplant-menu2',
  templateUrl: './aniplant-menu2.component.html',
  styleUrls: ['./aniplant-menu2.component.css']
})
export class AniplantMenu2Component implements OnInit {
  @Input() indicators: any;
  @Input() item_id: any;

  itemId;
  itemName;
  items;
  selected = 0;

  wait = true;
  values = [];
  wait_values = true;
  constructor(
    public aimsServ: AimsService
  ) { }

  ngOnInit() {
    // thisIndicators();
    // console.log(this.indicators)
    if(this.indicators) {
      this.itemId = this.indicators[0].id
      this.itemName = this.indicators[0].title
      this.getItemValues();
    }
    this.wait = false;
  }

  // getItems() {
  //   this.aimsServ.getItems(this.menuId).subscribe(
  //     (data: []) => {
  //       this.items = data;
  //       // console.log(this.items[0]);
  //       if (this.items.length !== 0) {;
  //          this.itemId = this.items[0].id;
  //          this.itemName = this.items[0].title;
  //          this.getItemValues();
  //       }
  //       this.wait = false;
  //     }
  //   );
  // }

  getItemValues() {
    this.aimsServ.getAnimalPlantHealthValues(this.itemId, this.item_id).subscribe(
      (data: []) => {
        this.values = data;
        this.wait_values = false;
      }
    );
  }

  onScrollLeft() {
    document.getElementById('list2').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list2').scrollLeft -= 240;
  }

  isSScrollAble() {
    if (document.getElementById('list2') !== null) {
    if (document.getElementById('list2').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noIndicators() {
    if (this.indicators.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  getUpperCase(value: string) {
    var val = '';
    if(value) {
      val = value.toUpperCase();;
    } else {
      val = value;
    }
    return val;
  }

  onChangeItem(itemId, itemName, i, event) {
    this.wait_values = true;

    this.itemId = itemId;
    this.itemName = itemName;
    this.selected = i;
    // console.log(event.target.innerText)
    // console.log(i)
    // console.log(document.getElementById('list'))
    this.getItemValues();
   }

}
