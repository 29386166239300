import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddMarketComponent } from 'src/app/modules/dashboard-definition/add-market/add-market.component';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from 'src/app/auth/auth.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  providers: [DashboardDefinitionService, AuthService]
})
export class TableComponent implements OnInit {
  private _cropId;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() set cropId(value) {
    this._cropId = value;
    this.getmarkets();
  }

  get cropId() {
    return this._cropId;
  }

  displayedColumns: string[] = ['market name', 'region', 'price', 'change', ];
  dataSource;
  localMarkets = [];

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private dashDefService: DashboardDefinitionService
  ) {}

  ngOnInit() {  }

  getmarkets() {
    this.dashDefService.getMarkets(this.cropId).subscribe(
      (data: Market) => {
        this.localMarkets = data.local;
        this.dataSource = new MatTableDataSource(this.localMarkets);
        this.dataSource.paginator = this.paginator;
      }
    );
 }
  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddMarket(type) {
    const dialogRef = this.dialog.open(AddMarketComponent, {
      width: '500px',
      height: '500px',
      data: {cropId: this.cropId, type}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getmarkets();
    });
  }

  onAddMarketPrice(type) {
    const edit = true;
    const dialogRef = this.dialog.open(AddMarketComponent, {
      width: '500px',
      height: '350px',
      data: {cropId: this.cropId, type, edit}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getmarkets();
    });
  }
}

export interface Market {
  local;
}
