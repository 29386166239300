import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpCenterService {

  constructor(private http:HttpClient) {}
  getServices(){
    return this.http.get(`${environment.apiUrl}/services/lists`)
  }
  getSectors(){
    return this.http.get(`${environment.apiUrl}/sector/lists`)
  }
  getRegions(){
    return this.http.get(`${environment.apiUrl}/regions`)
  }
  getDistricts(regionId){
    return this.http.get(`${environment.apiUrl}/districts/${regionId}`)       
  }
  getWards(districtId){
    return this.http.get(`${environment.apiUrl}/wards/${districtId}`)       
  }
  getVillages(wardId){
    return this.http.get(`${environment.apiUrl}/villages/${wardId}`)       
  }
  saveData(data:any){
    return this.http.post(`${environment.apiUrl}/help-center/store`,data)
  }
}
