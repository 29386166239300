import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';

@Component({
  selector: 'app-add-season',
  templateUrl: './add-season.component.html',
  styleUrls: ['./add-season.component.css']
})
export class AddSeasonComponent implements OnInit {

  seasonsForm = this.fb.group({
    season: ['', Validators.required],

   });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddSeasonComponent>,
    private dashService: DashboardDefinitionService
  ) {

  }

  ngOnInit() {
  }

  getErrorMessage() {
    return 'You must enter a season';
  }

  onSubmit() {
    if(this.seasonsForm.valid) {
      this.dashService.addSeason(this.seasonsForm.value).subscribe(
        (data) => {
          this.onNoClick();
        },
        error => {
          console.log(error);
        }
      )
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
