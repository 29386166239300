import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-tradequantity-main',
  templateUrl: './tradequantity-main.component.html',
  styleUrls: ['./tradequantity-main.component.css']
})
export class TradequantityMainComponent implements OnInit {
  path = this.authService.path;
  
  dashId;
  dashName;
  itemType;
  itemName;

  constructor(
    public router: Router,
    public authService: AuthService,
    public route: ActivatedRoute
  ) {
    console.log(route.url['_value'])
    this.dashId = route.url['_value'][1].parameters['dashb'];
    this.itemType = route.url['_value'][1].parameters['name'];
    this.dashName = route.url['_value'][0].path;
  }

  ngOnInit() {
  }

  backToDashboards() {
    this.router.navigate([`${this.path + '/agri-dashboard'}`]);
  }

  backToAimsDashboards() {
    var name = this.dashName;
    this.router.navigate(['/dashboard/' + this.dashId, {'dashb':this.dashId,  'name': name }], { skipLocationChange: false });
  }

  toTradeQuantityDashb() {
    var name = this.dashName;
    this.router.navigate(['/aims/trade-quantity/crops', {'dashb':this.dashId,  'name': name }], { skipLocationChange: false });
  }

  toTradeQuantityAnimalProdDashb() {
    var name = this.dashName;
    this.router.navigate(['/aims/trade-quantity/animal-products', {'dashb':this.dashId,  'name': name }], { skipLocationChange: false });
  }

  toTradeQuantityAnimalNumberDashb() {
    var name = this.dashName;
    this.router.navigate(['/aims/trade-quantity/animal-number', {'dashb':this.dashId,  'name': name }], { skipLocationChange: false });
  }
  toTradeQuantityAquaFishDashb() {
    var name = this.dashName;
    this.router.navigate(['/aims/trade-quantity/aquaculture-and-fisheries', {'dashb':this.dashId,  'name': name }], { skipLocationChange: false });
  }
  toTradeQuantityAgriProdDashb() {
    var name = this.dashName;
    this.router.navigate(['/aims/trade-quantity/agriculture-products-and-goods', {'dashb':this.dashId,  'name': name }], { skipLocationChange: false });
  }
}
