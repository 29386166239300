import { Component, Input, OnInit } from '@angular/core';
import { LivestockService } from '../../../livestock/livestock.service';
import { TradequantityService } from '../../tradequantity.service';

@Component({
  selector: 'app-animalnumber-menu',
  templateUrl: './animalnumber-menu.component.html',
  styleUrls: ['./animalnumber-menu.component.css']
})
export class AnimalnumberMenuComponent implements OnInit {
  @Input() menuId: string;
  @Input() dashboardName: string;

  cropId;
  cropName;
  crops;
  selected = 0;

  wait = true;
  values = [];
  wait_values = true;
  cropsPresent = false;
  constructor(
    public livestockServ: LivestockService,
    public tradeServ: TradequantityService,
  ) { }

  ngOnInit() {
    this.getCrops();
  }

  getCrops() {
    this.livestockServ.getItemsByIndicatorCategory(this.menuId).subscribe(
      (data: []) => {
        this.crops = data;
        // console.log(this.crops[0]);
        if (this.crops.length !== 0) {;
           this.cropId = this.crops[0].id;
           this.cropName = this.crops[0].title;
           this.getAnimalNumberValues();
        }
        this.wait = false;
        this.wait_values = false;
      }
    );
  }
  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollAble() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noCrops() {
    if (this.crops.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  getUpperCase(value: string) {
    var val = '';
    if(value) {
      val = value.toUpperCase();;
    } else {
      val = value;
    }
    return val;
  }

  onChangeCrop(cropId, cropName, i, event) {
    this.wait_values = true;

    this.cropId = cropId;
    this.cropName = cropName;
    this.selected = i;
    // console.log(event.target.innerText)
    // console.log(i)
    // console.log(document.getElementById('list'))
    this.getAnimalNumberValues();
   }


  getAnimalNumberValues() {
    this.tradeServ.getAnimalNumberValues(this.cropId, this.dashboardName).subscribe(
      (data: any) => {
        if(data[0].chart1.chart1.original[0].series.length == 0) {
          this.cropsPresent = false;
        } else{
          this.cropsPresent = true;
        }
        this.values = data[0];
        this.wait_values = false;
      }
    );
  }
}
