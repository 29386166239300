import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
const url1 = apiUrl + '/aims/tradeqty-crops-values/';
const url2 = apiUrl + '/aims/tradeqty-animal-product-values/';
const url3 = apiUrl + '/aims/tradeqty-animal-number-values/';
const url4 = apiUrl + '/aims/tradeqty-aqua-fisheries-values/';
const url5 = apiUrl + '/aims/tradeqty-agri-products-values/';

@Injectable({
  providedIn: 'root'
})
export class TradequantityService {

  constructor(private http: HttpClient) { }
  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Requested-Width': 'XMLHttpRequeest',
    }
  );

  getCropsValues(id, type) {
    return this.http.get(url1 + id + '/' + type, {headers: this.headers});
  }
  getAnimalProductValues(id, type) {
    return this.http.get(url2 + id + '/' + type, {headers: this.headers});
  }
  getAnimalNumberValues(id, type) {
    return this.http.get(url3 + id + '/' + type, {headers: this.headers});
  }
  getAquafishValues(id, type) {
    return this.http.get(url4 + id + '/' + type, {headers: this.headers});
  }
  getAgriProductValues(id, type) {
    return this.http.get(url5 + id + '/' + type, {headers: this.headers});
  }
}