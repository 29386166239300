import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AimsService } from '../../../aims.service';

@Component({
  selector: 'app-aquafish-dashb',
  templateUrl: './aquafish-dashb.component.html',
  styleUrls: ['./aquafish-dashb.component.css']
})
export class AquafishDashbComponent implements OnInit {
  path = this.authService.path;
  index = 0;
  menus
  dashId;
  dashName;
  category;
  type;
  constructor(
    public router: Router,
    public authService: AuthService,
    public aimsServ: AimsService,
    public route: ActivatedRoute
  ) { 
    // console.log(route.url['_value'])
    this.dashId = route.url['_value'][2].parameters['dashb'];
    this.category = route.url['_value'][1].path;
    this.type = route.url['_value'][2].path;
    this.dashName = route.url['_value'][0].path;
    // console.log(this.dashName)
  }

  ngOnInit() {
    // this.getAllmenus();
    this.getMainMenu();
  }

  getMainMenu() {
     this.aimsServ.getMainMenuByTitle('Aquaculture and Fisheries', this.category).subscribe(
       (data: []) => {
         this.menus = data;
        //  console.log(this.menus)
       }
     );
   }

  backToDashboards() {
      this.router.navigate([`${this.path + '/agri-dashboard'}`]);
  }

   backToAimsDashboards() {
    var name = this.dashName;
      this.router.navigate(['/dashboard/'+ this.dashId, { 'name':name }], { skipLocationChange: false });
  }
  
  toTradeQtyDashb() {
    this.router.navigate(['/aims/trade-quantity', {'dashb':this.dashId, 'name':this.category}])
  }
  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    // console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index;
  }
}
