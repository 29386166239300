import { Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators, FormArray, FormControlDirective} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DashboardDefinitionService } from '../dashboard-definition.service';

export interface DialogData {
  cropId;
  type;
  edit;
  market;
  marketId;
  cropType;
}

@Component({
  selector: 'app-add-market',
  templateUrl: './add-market.component.html',
  styleUrls: ['./add-market.component.css']
})
export class AddMarketComponent implements OnInit {
  marketForm = this.fb.group({
   cropId: ['', Validators.required],
   type:  ['', Validators.required],
   markets: this.fb.array([
    this.fb.group({
    name:  ['', Validators.required],
    region:  [''],
    district:  [''],
    price:  [''],
    crop_type:  [''],
    high_price:  ['', Validators.required],
    low_price:  [''],
    price_date:  [''],
    date:  [''],
    })])
  });

  marketAddForm = this.fb.group({
    market: [''],
    marketId: ['', Validators.required],
    high_price:  ['', Validators.required],
    low_price:  [''],
    price_date:  ['', Validators.required],
    date:  ['', Validators.required],
   });

  regions;
  localmarkets;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    public dialogRef: MatDialogRef<AddMarketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dashDefServ: DashboardDefinitionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    ) {  this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getRegions();
    if (this.data.edit === undefined) {
      if (this.data.marketId === undefined) {
        this.marketForm.get('cropId').setValue(this.data.cropId);
        this.marketForm.get('type').setValue(this.data.type);
      }
      if (this.data.marketId !== undefined) {
        this.marketAddForm.get('marketId').setValue(this.data.marketId);
        this.marketAddForm.get('market').setValue(this.data.market);
      }
    } else {
     this.getmarkets();
     }
  }

  getRegions() {
    this.dashDefServ.getRegions().subscribe(
      (data: []) => {
        this.regions = data;
      }
    );
   }

  setMarketId(marketId) {
    this.marketAddForm.get('marketId').setValue(marketId);
  }

  getmarkets() {
    this.dashDefServ.getMarkets(this.data.cropId).subscribe(
      (data: Market) => {
        this.localmarkets = data.local;
      }
    );
 }

  getErrorMessage() {
    return 'You must enter a value';
  }

  get stocks() {
    return this.marketForm.get('markets') as FormArray;
  }

  addAlias() {
    this.stocks.push(
      this.fb.group({
        name:  ['', Validators.required],
        region:  [''],
        district:  [''],
        type:  [''],
        high_price:  [''],
        low_price:  [''],
        crop_type:  [''],
      })
    );
  }

  removeAlis(index) {
    this.stocks.removeAt(index);
  }

  onSubmit() {
    console.log(this.marketForm.value);
    this.dashDefServ.addMarket(this.marketForm.value).subscribe(
      (data) => {
        if (data === 'market exists') {
          this.snackBar.open('Market already exist', '', {
            duration: 2000,
          });
        } else {
          this.onNoClick();
        }
          }
    );
  }

  onAddMarketPrice() {
    console.log(this.marketAddForm.value.price_date)
    this.dashDefServ.addMarketPrice(this.marketAddForm.value).subscribe(
      (data) => {
        this.onNoClick();
      }
    );
  }

}

export class Market {
  local;
  international;
  destination;
}
