import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
const url1 = apiUrl + '/add-dashboard-main-menu';
const url2 = apiUrl + '/get-dashboard-main-menus/';
const url3 = apiUrl + '/add-crop';
const url4 = apiUrl + '/get-crops/';
const url5 = apiUrl + '/add-market';
const url6 = apiUrl + '/get-markets/';
const url7 = apiUrl + '/get-crop-production/';
const url8 = apiUrl + '/add-crop-production';
const url9 = apiUrl + '/add-crop-sell';
const url10 = apiUrl + '/add-market-price';
const url11 = apiUrl + '/update-main-menu';
const url12 = apiUrl + '/delete-main-menu/';
const url13 = apiUrl + '/delete-crop/';
const url14 = apiUrl + '/update-crop/';
const url15 = apiUrl + '/get-crop-prices/';
const url16 = apiUrl + '/add-rate';
const url17 = apiUrl + '/get-regions';
const url18 = apiUrl + '/add-sale';
const url19 = apiUrl + '/get-districts/';
const url20 = apiUrl + '/get-seasons';
const url21 = apiUrl + '/add-season';
const url22 = apiUrl + '/add-user';
const url23 = apiUrl + '/get-roles';
const url24 = apiUrl + '/add-role';
const url25 = apiUrl + '/add-unit-measure';
const url26 = apiUrl + '/get-unit-measures';
const url27 = apiUrl + '/get-crop-measure/';
const url28 = apiUrl + '/get-zones';
const url29 = apiUrl + '/get-zone-regions/';
const url30 = apiUrl + '/get-graph-data/';
const url31 = apiUrl + '/get-graph-data-by-region/';
const url32 = apiUrl + '/get-region-districts/';
const url33 = apiUrl + '/get-graph-data-by-district/';
const url34 = apiUrl + '/get-summary-data';
const url35 = apiUrl + '/get-region-id/';
const url36 = apiUrl + '/get-production-crops/';
const url37 = apiUrl + '/get-crop-production/';
const url38 = apiUrl + '/get-mechanization-types';
const url39 = apiUrl + '/mechanization_chart/';
const url40 = apiUrl + '/drill_mechanization_chart/';
const url41 = apiUrl + '/second_drill_mechanization_chart/';
const url42 = apiUrl + '/filter_ownership/';
const url43 = apiUrl + '/filter_ownership_inseasons/';
const url44 = apiUrl + '/filter_ownership_seasons_inregions/';

const url45 = apiUrl + '/user/';
const url46 = apiUrl + '/update_profile/';
const url47 = apiUrl + '/update_passwords/';
const url48 = apiUrl + '/usd_to_tsh';

const url49 = apiUrl + '/get-countries';

@Injectable({
  providedIn: 'root'
})

export class DashboardDefinitionService {
  path = '';
  // path = 'agri-dashboard/public/';
  lang = localStorage.getItem('language') || 'en';
  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Requested-Width': 'XMLHttpRequeest',
    }
  );
  selected = 'Select Market';
  cropName = '';
  constructor(private http: HttpClient) { }

  setCropName(name) {
    this.cropName = name;
  }
  addMainMenu(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }
  getMainMenu(id) {
    return this.http.get(url2 + id, {headers: this.headers});
  }

  addCrops(data) {
    return this.http.post(url3, data, {headers: this.headers});
  }

  getCrops(id, lang) {
    return this.http.get(url4 + id + '/' + lang, {headers: this.headers});
  }

  addMarket(data) {
    return this.http.post(url5, data, {headers: this.headers});
  }

  getMarkets(id) {
    return this.http.get(url6 + id, {headers: this.headers});
  }

  getCropProduction(cropId, season, condition) {
    // console.log(season);
    return this.http.get(url7 + cropId +'/' + season + '/' + condition, {headers: this.headers});
  }

   addCropProduction(data) {
    return this.http.post(url8, data, {headers: this.headers});
  }

  addCropSell(data) {
    return this.http.post(url9, data, {headers: this.headers});
  }

  addMarketPrice(data) {
    return this.http.post(url10, data, {headers: this.headers});
  }

  updateMainMenu(data) {
    return this.http.post(url11, data, {headers: this.headers});
  }
  deleteMainMenu(id) {
    return this.http.get(url12 + id, {headers: this.headers});
  }
  deleteCrop(id) {
    return this.http.get(url13 + id, {headers: this.headers});
  }
  updateCrop(data, lang) {
    return this.http.post(url14 + lang, data, {headers: this.headers});
  }
  getPrices(cropId, value) {
    return this.http.get(url15 + cropId + '/' + value, {headers: this.headers});
  }
  addRate(data) {
    return this.http.post(url16, data, {headers: this.headers});
  }
  getRegions() {
    return this.http.get(url17, {headers: this.headers});
  }

  addCropSales(data) {
    return this.http.post(url18, data, {headers: this.headers});
  }

  getDistricts(data) {
    return this.http.get(url19 + data, {headers: this.headers});
  }

  getSeasons() {
    return this.http.get(url20, {headers: this.headers});
  }

  addSeason(data) {
    return this.http.post(url21, data, {headers: this.headers});
  }

  addUser(data) {
    return this.http.post(url22, data, {headers: this.headers});
  }

  getUserRoles() {
    return this.http.get(url23, {headers: this.headers});
  }

  addRole(data) {
    return this.http.post(url24, data,  {headers: this.headers});
  }
  addUnitMeasure(data) {
    return this.http.post(url25, data,  {headers: this.headers});
  }

  getUnitMeasures() {
    return this.http.get(url26, {headers: this.headers});
  }

  getCropMeasure(data) {
    console.log('cropId is: ' + data)
    return this.http.get(url27 + data, {headers: this.headers});
  }

  getAllZones() {
    return this.http.get(url28, {headers: this.headers});
  }

  getZoneRegions(zoneid) {
    return this.http.get(url29 + zoneid, {headers: this.headers});
  }

  getGraphData(zoneid, index) {
    return this.http.get(url30 + zoneid + '/' + index, {headers: this.headers});
  }

  getGraphDataByRegion(regionid, index) {
    return this.http.get(url31 + regionid + '/' + index, {headers: this.headers});
  }

  getRegionDistricts(regionid) {
    return this.http.get(url32 + regionid, {headers: this.headers});
  }

  getGraphDataByDistrict(districtid, index) {
    return this.http.get(url33 + districtid + '/' + index, {headers: this.headers});
  }

  getDataSummary() {
    return this.http.get(url34, {headers: this.headers});
  }

  getRegionId(region) {
    return this.http.get(url35 + region, {headers: this.headers});
  }

  resetSelected() {
    this.selected = 'Select Market';
  }

  getProductionCrops(id, lang) {
    return this.http.get(url36 + id + '/' + lang, {headers:this.headers});
  }

  getCropProductionInfo(id) {
    return this.http.get(url37 + id, {headers:this.headers});
  }

  // Mechanization

  getEquipmentTypes() {
    return this.http.get(url38, {headers:this.headers});
  }

  getMechanizationData(type) {
    return this.http.get(url39 + type, {headers:this.headers});
  }

  getMechanizationDataRegions(type, season) {
    return this.http.get(url40 + type + '/' + season, {headers:this.headers});
  }

  getMechanizationDataDistricts(type, season, region) {
    return this.http.get(url41 + type + '/' + season + '/' + region, {headers:this.headers});
  }

  getMechanizationDataFilter(type, category) {
    return this.http.get(url42 + type + '/' + category, {headers:this.headers});
  }

  getMechanizationDataFilterWithSeason(type, season, category) {
    return this.http.get(url43 + type + '/' + season + '/' + category, {headers:this.headers});
  }

  getMechanizationDataFilterWithRegion(type, season, region, category) {
    return this.http.get(url44 + type + '/' + season + '/' + region + '/' + category, {headers:this.headers});
  }


  getUser(id) {
    return this.http.get(url45 + id, {headers:this.headers});
  }

  updateProfile(data, id) {
    return this.http.post(url46 + id, data, {headers: this.headers});
  }

  updatePasswords(data, id) {
    return this.http.post(url47 + id, data, {headers: this.headers});
  }

  usdToTsh(data) {
    return this.http.post(url48, data, {headers: this.headers});
  }

  getCountries() {
    return this.http.get(url49, {headers: this.headers});
  }
}
