import { Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddCropComponent } from '../add-crop/add-crop.component';
import { DashboardDefinitionService } from '../dashboard-definition.service';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { MatTabChangeEvent } from '@angular/material';
import { CropPriceDetailsComponent } from '../crop-price-details/crop-price-details.component';
import { interval } from 'rxjs';

@Component({
  selector: 'app-main-menu-content',
  templateUrl: './main-menu-content.component.html',
  styleUrls: ['./main-menu-content.component.css'],
  providers: [DashboardDefinitionService, AuthService]
})
export class MainMenuContentComponent implements OnInit, AfterViewInit {
  @Input() menuId: string;
  @Input() dashboardName: string;
  @Output() onFetchGraphData = new EventEmitter<string>();
  @ViewChild(CropPriceDetailsComponent, {static: false}) child1: CropPriceDetailsComponent;

  crops;
  cropId;
  cropName;
  crop_names = [
    "KAHAWA",
    "MKONGE",
    "CHAI",
    "PAMBA",
    "PARETO",
    "KOROSHO",
    "TUMBAKU",
    "SUKARI",
    "MAHINDI",
    "MCHELE",
    "MTAMA",
    "MAHARAGE",
    "NYANYA",
    "VIAZI MVIRINGO",
    "VITUNGUU MAJI",
    "PILIPILI HOHO",
    "TIKITIMAJI",
    "NANASI",
    "TANGO",
  ];
  noChange = false;
  // date
  unitMeasure;
  index = 0;
  count = 0;
  wait = true;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private dashDefService: DashboardDefinitionService,
    private cdRef: ChangeDetectorRef
  ) {  }

  ngOnInit() {
    this.getCrops();

  }

  ngAfterViewInit() {
    if (this.count === 0) {
      interval(3000).subscribe(() => {
        this.wait = false;
        this.count = 1;
      });
    }
  }

  // ngAfterViewChecked(){
  //   this.authService = this.authService;
  //   this.cdRef.detectChanges();
  // }
  getColor(change) {
    if (change > 0) {
        return '#116B38';
    }
    if (change < 0) {
        return '#C81212';
    }
    if (change === 0) {
      return '#0A3EA6';
  }
  }

  // ngAfterViewInit() {
  //   const btnContainer = document.getElementById('list');
  //   const btns = btnContainer.getElementsByClassName('crop-holder');
  //   btns[0].className += ' active';
  // }

  getCrops() {
    var lang = localStorage.getItem('language');
    this.dashDefService.getCrops(this.menuId, lang).subscribe(
      (data: []) => {
        this.crops = data;
        console.log(this.crops);
        if (this.crops.length !== 0) {;
           this.cropId = this.crops[0].id;
           this.cropName = this.crops[0].name;
           this.getCropMeasure(this.cropId);
        }
      }
    );
    this.child1.getmarkets()
  }


  getCropMeasure(cropId) {
    this.dashDefService.getCropMeasure(cropId).subscribe(
      (data) => {
        this.unitMeasure = data['unit_measure'].toUpperCase()

      }
    )
  }

  onChangeCrop(cropId, cropName) {
    console.log(cropId);
    this.getCropMeasure(cropId);
    this.dashDefService.resetSelected();
    // if (cropId === '2') {
    const btnContainer = document.getElementById('list');
    const btns = btnContainer.getElementsByClassName('crop-holder');
    this.cropId = cropId;
    this.cropName = cropName;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener('click', function() {
          const current = document.getElementsByClassName('active');

          if (current.length > 0) {
      current[0].className = current[0].className.replace(' active', '');
    }
          this.className += ' active';
        });
      }


    // }
   }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  increase(change) {
    if (change > 0) {
      return true;
    }
    if (change < 0) {
      return false;
    }
    // if (change === 0) {
    //   this.noChange = true;
    // }
  }

  onAddCrop() {
    const dialogRef = this.dialog.open(AddCropComponent, {
      width: '500px',
      height: '510px',
      data: {id: this.menuId}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getCrops();
    });
  }

  onEditCrop(id, name, measure) {
    const dialogRef = this.dialog.open(AddCropComponent, {
      width: '500px',
      height: '400px',
      data: {id, name, measure}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getCrops();
    });
  }

  onDeleteCrop(id) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      height: '350px',
      data: {id, type: 'crop'}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCrops();
    });
  }

  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollable() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noCrops() {
    if (this.crops.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index;
  }
}
