import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
HC_exporting(Highcharts);

// import {Chart} from 'node_modules/chart.js';

@Component({
  selector: 'app-main-extension-dashb',
  templateUrl: './main-extension-dashb.component.html',
  styleUrls: ['./main-extension-dashb.component.css']
})
export class MainExtensionDashbComponent implements OnInit {

  genderLabels = [];
  genderData = [];
  trendLabels = [];
  trendData = []
  ageRangeLabels = [];
  ageRangeData = [];
  sectorLabels = [];
  sectorData = [];
  educationLabels = [];
  educationData = [];

  Highcharts = Highcharts;
  chartOptions: {};
  chartOptionsAge: {};
  chartOptionsYear: {};
  chartOptionsSector: {};
  chartOptionsEducation: {};
  chartConstructor = 'chart';
  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Requested-Width': 'XMLHttpRequeest',
    }
  );
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.graphData();
    // this.test();
  }

  graphData() {
    this.officersTrend();
    this.officersGender();
    this.officersAgeRange();
    this.officersSector();
    this.officersEducation();
  }

  officersGender() {
    this.http.get('https://iks.kilimo.go.tz/mkilimo/api/v2/officers/gender_based').subscribe(
      data  => {
        console.log(data)
        var total = parseInt(data[0]['value']) + parseInt(data[1]['value']);
        this.genderLabels.push('Total', data[0]['label'], data[1]['label']);
        this.genderData.push(total, parseInt(data[0]['value']), parseInt(data[1]['value']));
        // for (var i = 0; i < data.length; i++) {
        //   total += data[i]['value']
        // }

        // this.drawOfficersByGender();
        this.genderDistribution();
      }
    );
  }

  officersTrend() {
    this.http.get('https://iks.kilimo.go.tz/mkilimo/api/v2/officers/regional_based').subscribe(
      (data: []) => {
        console.log(data)
        for (var i = 0; i < data.length; i++) {
          this.trendLabels.push(data[i]['label']);
          this.trendData.push(data[i]['value']);

        }

        // this.drawOfficersTrend();
        this.regionalTrend();
      }
    );
  }

  officersAgeRange() {
    this.http.get('https://iks.kilimo.go.tz/mkilimo/api/v2/officers/age_groups_based').subscribe(
      (data: []) => {
        console.log(data)
        for (var i = 5; i < data.length && i >= 0; i--) {
          this.ageRangeLabels.push(data[i]['label']);
          this.ageRangeData.push(parseInt(data[i]['value']));
        }
        console.log(this.ageRangeData)
        // this.drawOfficersAgeRange();
        this.ageRange();
      }
    );
  }

  officersSector() {
    this.http.get('https://iks.kilimo.go.tz/mkilimo/api/v2/officers/sector_based').subscribe(
      (data: []) => {
        console.log(data)
        for (var i = 0; i < data.length; i++) {
          this.sectorLabels.push(data[i]['label']);
          this.sectorData.push(data[i]['value']);
        }

        // this.drawOfficersSector();
        this.sectorDistribution();
      }
    );
  }

  officersEducation() {
    this.http.get('https://iks.kilimo.go.tz/mkilimo/api/v2/officers/education_based').subscribe(
      (data: []) => {
        console.log(data)
        for (var i = 0; i < data.length; i++) {
          this.educationLabels.push(data[i]['label']);
          this.educationData.push(data[i]['value']);
        }
        console.log(this.educationLabels)

        // this.drawOfficersEducation();
        this.educationDistribution();
      }
    );
  }

  // drawOfficersByGender() {
  //   let myChart;
  //   document.getElementById('officersGender').innerHTML = '';
  //   document.getElementById('officersGender').innerHTML = '<canvas id="officersGenderChart" style="height: 100%; width: 100%"></canvas>';
  //   let ctx = document.getElementById('officersGenderChart');
  //   let data = {
  //     labels: this.genderLabels,
  //     datasets: [{
  //       data: this.genderData,
  //       backgroundColor: '#5096D6',
  //       // borderColor: 'white',
  //       barThickness: 30,
  //     }]
  //   };

  //   myChart = new Chart(ctx, {
  //     type: 'bar',
  //     data,
  //     options: {

  //       hover: {
  //         animationDuration: 0
  //       },
  //       animation: {
  //         tension: {
  //           duration: 1000,
  //           easing: 'linear',
  //           from: 1,
  //           to: 0,
  //           loop: true
  //         },
  //         onComplete() {
  //           let chartInstance = this.chart,
  //             ctx = chartInstance.ctx;

  //           ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily );
  //           ctx.textAlign = 'center';
  //           ctx.textBaseline = 'bottom';
  //           ctx.fillStyle = '#AEAEAE';

  //           this.data.datasets.forEach(function(dataset, i) {
  //             let meta = chartInstance.controller.getDatasetMeta(i);
  //             meta.data.forEach(function(bar, index) {
  //               let data = numberWithCommas(dataset.data[index]);
  //               ctx.fillText(data, bar._model.x - 4, bar._model.y - 3);
  //             });
  //           });
  //         }
  //       },
  //       legend: {
  //         display: false,
  //         position: 'bottom'
  //       },
  //       tooltips: {
  //         enabled: false
  //       },
  //       scales: {
  //         yAxes: [{
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             // labelString: this.name
  //           },
  //           gridLines: {
  //             display: true,
  //             // color: 'transparent',
  //             zeroLineColor: '#AEAEAE'
  //           },
  //           ticks: {
  //             // max: Math.max(...data.datasets[0].data) - 10,
  //             display: false,
  //             beginAtZero: true,
  //             fontColor: '#D6D6D6',
  //           }
  //         }],
  //         xAxes: [{
  //           gridLines: {
  //             display: false,
  //             color: 'transparent',
  //             zeroLineColor: '#000'
  //           },
  //           ticks: {
  //             max: Math.max(...data.datasets[0].data) + 100,
  //             beginAtZero: true,
  //             display: true,
  //             // fontColor: '#D6D6D6',
  //             autoSkip: false,
  //           }
  //         }]
  //       },
  //       responsive: true,
  //       maintainAspectRatio: true,
  //       layout: {
  //         padding: {
  //           left: 0,
  //           right: 40,
  //           top: 20,
  //           bottom: 0
  //         }
  //       }
  //     }
  //   });
  // }

  // drawOfficersTrend() {
  //   let myChart;
  //   document.getElementById('officersTrend').innerHTML = '';
  //   document.getElementById('officersTrend').innerHTML = '<canvas id="officersTrendChart" style="height: 100%; width: 100%"></canvas>';
  //   let ctx = document.getElementById('officersTrendChart');
  //   let data = {
  //     labels: this.trendLabels,
  //     datasets: [{
  //       data: this.trendData,
  //       backgroundColor: '#5096D6',
  //       // borderColor: 'white',
  //       barThickness: 30,
  //     }]
  //   };

  //   myChart = new Chart(ctx, {
  //     type: 'bar',
  //     data,
  //     options: {

  //       hover: {
  //         animationDuration: 0
  //       },
  //       animation: {
  //         tension: {
  //           duration: 1000,
  //           easing: 'linear',
  //           from: 1,
  //           to: 0,
  //           loop: true
  //         },
  //         onComplete() {
  //           let chartInstance = this.chart,
  //             ctx = chartInstance.ctx;

  //           ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily );
  //           ctx.textAlign = 'center';
  //           ctx.textBaseline = 'bottom';
  //           ctx.fillStyle = '#AEAEAE';

  //           this.data.datasets.forEach(function(dataset, i) {
  //             let meta = chartInstance.controller.getDatasetMeta(i);
  //             meta.data.forEach(function(bar, index) {
  //               let data = numberWithCommas(dataset.data[index]);
  //               ctx.fillText(data, bar._model.x - 4, bar._model.y - 3);
  //             });
  //           });
  //         }
  //       },
  //       legend: {
  //         display: false,
  //         position: 'bottom'
  //       },
  //       tooltips: {
  //         enabled: false
  //       },
  //       scales: {
  //         yAxes: [{
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             // labelString: this.name
  //           },
  //           gridLines: {
  //             display: true,
  //             // color: 'transparent',
  //             zeroLineColor: '#AEAEAE'
  //           },
  //           ticks: {
  //             // max: Math.max(...data.datasets[0].data) - 10,
  //             display: false,
  //             beginAtZero: true,
  //             fontColor: '#D6D6D6',
  //           }
  //         }],
  //         xAxes: [{
  //           gridLines: {
  //             display: false,
  //             color: 'transparent',
  //             zeroLineColor: '#fff'
  //           },
  //           ticks: {
  //             max: Math.max(...data.datasets[0].data) + 100,
  //             beginAtZero: true,
  //             display: true,
  //             // fontColor: '#D6D6D6',
  //             autoSkip: false,
  //           }
  //         }]
  //       },
  //       responsive: true,
  //       maintainAspectRatio: true,
  //       layout: {
  //         padding: {
  //           left: 0,
  //           right: 40,
  //           top: 20,
  //           bottom: 0
  //         }
  //       }
  //     }
  //   });
  // }

  // drawOfficersAgeRange() {
  //   let myChart;
  //   document.getElementById('officersAgeRange').innerHTML = '';
  //   document.getElementById('officersAgeRange').innerHTML = '<canvas id="officersAgeRangeChart" style="height: 100%; width: 100%"></canvas>';
  //   let ctx = document.getElementById('officersAgeRangeChart');
  //   let data = {
  //     labels: this.ageRangeLabels,
  //     datasets: [{
  //       data: this.ageRangeData,
  //       backgroundColor: '#5096D6',
  //       // borderColor: 'white',
  //       barThickness: 30,
  //     }]
  //   };

  //   myChart = new Chart(ctx, {
  //     type: 'bar',
  //     data,
  //     options: {

  //       hover: {
  //         animationDuration: 0
  //       },
  //       animation: {
  //         tension: {
  //           duration: 1000,
  //           easing: 'linear',
  //           from: 1,
  //           to: 0,
  //           loop: true
  //         },
  //         onComplete() {
  //           let chartInstance = this.chart,
  //             ctx = chartInstance.ctx;

  //           ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily );
  //           ctx.textAlign = 'center';
  //           ctx.textBaseline = 'bottom';
  //           ctx.fillStyle = '#AEAEAE';

  //           this.data.datasets.forEach(function(dataset, i) {
  //             let meta = chartInstance.controller.getDatasetMeta(i);
  //             meta.data.forEach(function(bar, index) {
  //               let data = numberWithCommas(dataset.data[index]);
  //               ctx.fillText(data, bar._model.x - 4, bar._model.y - 3);
  //             });
  //           });
  //         }
  //       },
  //       legend: {
  //         display: false,
  //         position: 'bottom'
  //       },
  //       tooltips: {
  //         enabled: false
  //       },
  //       scales: {
  //         yAxes: [{
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             // labelString: this.name
  //           },
  //           gridLines: {
  //             display: true,
  //             // color: 'transparent',
  //             zeroLineColor: '#AEAEAE'
  //           },
  //           ticks: {
  //             // max: Math.max(...data.datasets[0].data) - 10,
  //             display: false,
  //             beginAtZero: true,
  //             fontColor: '#D6D6D6',
  //           }
  //         }],
  //         xAxes: [{
  //           gridLines: {
  //             display: false,
  //             color: 'white',
  //             zeroLineColor: 'transparent'
  //           },
  //           ticks: {
  //             max: Math.max(...data.datasets[0].data) + 100,
  //             beginAtZero: true,
  //             display: true,
  //             // fontColor: '#D6D6D6',
  //             autoSkip: false,
  //           }
  //         }]
  //       },
  //       responsive: true,
  //       maintainAspectRatio: true,
  //       layout: {
  //         padding: {
  //           left: 0,
  //           right: 40,
  //           top: 20,
  //           bottom: 0
  //         }
  //       }
  //     }
  //   });
  // }

  // drawOfficersSector() {
  //   let myChart;
  //   document.getElementById('officersSector').innerHTML = '';
  //   document.getElementById('officersSector').innerHTML = '<canvas id="officersSectorChart" style="height: 100%; width: 100%"></canvas>';
  //   let ctx = document.getElementById('officersSectorChart');
  //   let data = {
  //     labels: this.sectorLabels,
  //     datasets: [{
  //       data: this.sectorData,
  //       backgroundColor: '#5096D6',
  //       // borderColor: 'white',
  //       barThickness: 30,
  //     }]
  //   };

  //   myChart = new Chart(ctx, {
  //     type: 'bar',
  //     data,
  //     options: {

  //       hover: {
  //         animationDuration: 0
  //       },
  //       animation: {
  //         tension: {
  //           duration: 1000,
  //           easing: 'linear',
  //           from: 1,
  //           to: 0,
  //           loop: true
  //         },
  //         onComplete() {
  //           let chartInstance = this.chart,
  //             ctx = chartInstance.ctx;

  //           ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily );
  //           ctx.textAlign = 'center';
  //           ctx.textBaseline = 'bottom';
  //           ctx.fillStyle = '#AEAEAE';

  //           this.data.datasets.forEach(function(dataset, i) {
  //             let meta = chartInstance.controller.getDatasetMeta(i);
  //             meta.data.forEach(function(bar, index) {
  //               let data = numberWithCommas(dataset.data[index]);
  //               ctx.fillText(data, bar._model.x - 4, bar._model.y - 3);
  //             });
  //           });
  //         }
  //       },
  //       legend: {
  //         display: false,
  //         position: 'bottom'
  //       },
  //       tooltips: {
  //         enabled: false
  //       },
  //       scales: {
  //         yAxes: [{
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             // labelString: this.name
  //           },
  //           gridLines: {
  //             display: true,
  //             // color: 'transparent',
  //             zeroLineColor: '#AEAEAE'
  //           },
  //           ticks: {
  //             // max: Math.max(...data.datasets[0].data) - 10,
  //             display: false,
  //             beginAtZero: true,
  //             fontColor: '#D6D6D6',
  //           }
  //         }],
  //         xAxes: [{
  //           gridLines: {
  //             display: false,
  //             color: 'transparent',
  //             zeroLineColor: '#000'
  //           },
  //           ticks: {
  //             max: Math.max(...data.datasets[0].data) + 100,
  //             beginAtZero: true,
  //             display: true,
  //             // fontColor: '#D6D6D6',
  //             autoSkip: false,
  //           }
  //         }]
  //       },
  //       responsive: true,
  //       maintainAspectRatio: true,
  //       layout: {
  //         padding: {
  //           left: 0,
  //           right: 40,
  //           top: 20,
  //           bottom: 0
  //         }
  //       }
  //     }
  //   });
  // }

  // drawOfficersEducation() {
  //   let myChart;
  //   document.getElementById('officersEducation').innerHTML = '';
  //   document.getElementById('officersEducation').innerHTML = '<canvas id="officersEducationChart" style="height: 100%; width: 100%"></canvas>';
  //   let ctx = document.getElementById('officersEducationChart');
  //   let data = {
  //     labels: this.educationLabels,
  //     datasets: [{
  //       data: this.educationData,
  //       backgroundColor: '#5096D6',
  //       // borderColor: 'white',
  //       barThickness: 30,
  //     }]
  //   };

  //   myChart = new Chart(ctx, {
  //     type: 'bar',
  //     data,
  //     options: {

  //       hover: {
  //         animationDuration: 0
  //       },
  //       animation: {
  //         tension: {
  //           duration: 1000,
  //           easing: 'linear',
  //           from: 1,
  //           to: 0,
  //           loop: true
  //         },
  //         onComplete() {
  //           let chartInstance = this.chart,
  //             ctx = chartInstance.ctx;

  //           ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily );
  //           ctx.textAlign = 'center';
  //           ctx.textBaseline = 'bottom';
  //           ctx.fillStyle = '#AEAEAE';

  //           this.data.datasets.forEach(function(dataset, i) {
  //             let meta = chartInstance.controller.getDatasetMeta(i);
  //             meta.data.forEach(function(bar, index) {
  //               let data = numberWithCommas(dataset.data[index]);
  //               ctx.fillText(data, bar._model.x - 4, bar._model.y - 3);
  //             });
  //           });
  //         }
  //       },
  //       legend: {
  //         display: false,
  //         position: 'bottom'
  //       },
  //       tooltips: {
  //         enabled: false
  //       },
  //       scales: {
  //         yAxes: [{
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             // labelString: this.name
  //           },
  //           gridLines: {
  //             display: true,
  //             // color: 'transparent',
  //             zeroLineColor: '#AEAEAE'
  //           },
  //           ticks: {
  //             // max: Math.max(...data.datasets[0].data) - 10,
  //             display: false,
  //             beginAtZero: true,
  //             fontColor: '#D6D6D6',
  //           }
  //         }],
  //         xAxes: [{
  //           gridLines: {
  //             display: false,
  //             color: 'transparent',
  //             zeroLineColor: '#000'
  //           },
  //           ticks: {
  //             max: Math.max(...data.datasets[0].data) + 100,
  //             beginAtZero: true,
  //             display: true,
  //             // fontColor: '#D6D6D6',
  //             autoSkip: false,
  //           }
  //         }]
  //       },
  //       responsive: true,
  //       maintainAspectRatio: true,
  //       layout: {
  //         padding: {
  //           left: 0,
  //           right: 40,
  //           top: 20,
  //           bottom: 0
  //         }
  //       }
  //     }
  //   });
  // }

  genderDistribution() {
    this.chartOptions = {
      chart: {
          type: 'column'
      },
      title: {
          text: 'Distribution by gender'
      },
      subtitle: {
          // text: 'Source: WorldClimate.com'
      },
      xAxis: {
          categories: this.genderLabels,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Number of people'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.3,
              borderWidth: 0.01
          }
      },
      series: [{
          name: 'Officers',
          data: this.genderData
      }]
  };
  }

  ageRange() {
    this.chartOptionsAge = {
      chart: {
          type: 'column'
      },
      title: {
          text: 'Distribution by age'
      },
      subtitle: {
          // text: 'Source: WorldClimate.com'
      },
      xAxis: {
          categories: this.ageRangeLabels,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Number of people'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [{
          name: 'Officers',
          data: this.ageRangeData
      }]
  };
  }

  regionalTrend() {
    this.chartOptionsYear = {
      chart: {
          type: 'column'
      },
      title: {
          text: 'Regional based'
      },
      subtitle: {
          // text: 'Source: WorldClimate.com'
      },
      xAxis: {
          categories: this.trendLabels,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Number of people'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [{
          name: 'Officers',
          data: this.trendData
      }]
  };
  }

  sectorDistribution() {
    this.chartOptionsSector = {
      chart: {
          type: 'column'
      },
      title: {
          text: 'Distribution by sector'
      },
      subtitle: {
          // text: 'Source: WorldClimate.com'
      },
      xAxis: {
          categories: this.sectorLabels,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Number of people'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [{
          name: 'Officers',
          data: this.sectorData
      }]
  };
  }

  educationDistribution() {
    this.chartOptionsEducation = {
      chart: {
          type: 'column'
      },
      title: {
          text: 'Distribution by Education Level'
      },
      subtitle: {
          // text: 'Source: WorldClimate.com'
      },
      xAxis: {
          categories: this.educationLabels,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Number of people'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [{
          name: 'Officers',
          data: this.educationData
      }]
  };
  }
}


function numberWithCommas(x) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}
