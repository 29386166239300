import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
HC_exporting(Highcharts);

@Component({
  selector: 'app-crop-values',
  templateUrl: './crop-values.component.html',
  styleUrls: ['./crop-values.component.css']
})
export class CropValuesComponent implements OnInit {
  @Input() values: any;
  @Input() cropName: any;

  Highcharts = Highcharts;
  chartOptions: {};
  chartConstructor = 'chart';
  data = 0
  constructor() { }

  ngOnInit() {
    // console.log(this.values[0].categories)
    this.drawChart(this.values[0])
    this.data = this.values[0].series.length
  }

  drawChart(values) {
    this.chartOptions = {
      chart: {
          type: 'column',
      },
      title: {
          text: 'Indicator Values'
      },
      subtitle: {
          text: 'per year (' + this.cropName + ')'
      },
      xAxis: {
          categories: values.categories,
          crosshair: true
      },
      yAxis: {
        minorTickInterval: 0.1,
        type: 'logarithmic',
          title: {
              text: 'Values'
          },
          
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              // borderWidth: 0,
              dataLabels: {
                enabled: false
            },
            borderRadius: '0.2%',
            groupPadding: 0.1
          }
      },
      series: values.series
  };
                
  }
}
