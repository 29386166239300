import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.css']
})
export class AddUsersComponent implements OnInit {
  roles

  usersForm = this.fb.group({
    firstName : ['', Validators.required],
    lastName : ['', Validators.required],
    username : ['', Validators.required],
    email : ['', Validators.required],
    password : ['', Validators.required],
    role : ['', Validators.required],
  })
  constructor(
    private fb: FormBuilder,
    private dashService : DashboardDefinitionService,
    private dialogRef: MatDialogRef<AddUsersComponent>
    ) { }

  ngOnInit() {
    this.getRoles();
  }


  getErrorMessage() {
    return 'You must enter a value';
  }

  onSubmit() {
    if(this.usersForm.valid) {
      this.dashService.addUser(this.usersForm.value).subscribe(
        (data) => {
          this.onNoClick();
        },
        error => {
          console.log(error);
        }
      )

    }
  }

  getRoles() {
    this.dashService.getUserRoles().subscribe(
      (data: Roles) => {
        this.roles = data.roles
        console.log(this.roles)
      }
    )
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface Roles {
  roles
}
