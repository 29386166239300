import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
HC_exporting(Highcharts);

@Component({
  selector: 'app-items-values',
  templateUrl: './items-values.component.html',
  styleUrls: ['./items-values.component.css']
})
export class ItemsValuesComponent implements OnInit {
  @Input() values: any;
  @Input() itemName: any;

  Highcharts = Highcharts;
  chartOptions: {};
  chartConstructor = 'chart';
  data = 0;
  type = 'line';
  constructor() { }

  ngOnInit() {
    this.data = this.values[0].series.length
    // console.log(this.itemName)
    if(this.itemName == 'Socioeconomy' || this.itemName == 'Land Cover and Use' || this.itemName == 'National Accounts') {
      this.type = 'column';
    }
    this.drawChart(this.values[0])
  }

  drawChart(values) {
    this.chartOptions = {
      chart: {
          type: this.type,
      },
      title: {
          text: 'Indicator Values'
      },
      subtitle: {
          text: 'per year (' + this.itemName + ')'
      },
      xAxis: {
          categories: values.categories,
          crosshair: true,
          // type: 'logarithmic'
      },
      yAxis: {
          minorTickInterval: 0.1,
          type: 'logarithmic',
          title: {
              text: 'Values'
          },
          
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              // borderWidth: 0,
              dataLabels: {
                enabled: false
            },
            borderRadius: '0.2%',
            groupPadding: 0.1
          }
      },
      series: values.series
  };
                
  }

}
