
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// tslint:disable-next-line: import-spacing
import { AddDashboardMainMenuComponent }
from 'src/app/modules/dashboard-definition/add-dashboard-main-menu/add-dashboard-main-menu.component';
import { DashboardDefinitionService} from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { ImportFileComponent } from '../import-file/import-file.component';
import { MatTabChangeEvent } from '@angular/material';
import { MainMenuContentComponent } from '../main-menu-content/main-menu-content.component';

@Component({
  selector: 'app-dashboard-index',
  templateUrl: './dashboard-index.component.html',
  styleUrls: ['./dashboard-index.component.css'],
  providers: [DashboardDefinitionService, AuthService]
})
export class DashboardIndexComponent implements OnInit {
  path = this.authService.path;
  menus;
  dashboardId;
  dashboardName;
  currency;
  menu_names = [
    'Traditional export crops',
    'Food crops',
    'Horticulture',
  ];
  index = 0;
  stock_index = 0;
  zones

  @ViewChild(MainMenuContentComponent, {static: false}) child: MainMenuContentComponent;
  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private dashDefSer: DashboardDefinitionService,
    private router: Router,
    private active: ActivatedRoute) {
    this.active.url.subscribe(
        data => {
          if(this.path == '') {
            this.dashboardName = data[1].parameters.name;
            this.dashboardId = data[1].path;
          } else {
            this.dashboardName = data[3].parameters.name;
            this.dashboardId = data[3].path;
          }
        }
      );
  }

  ngOnInit() {
    // this.getAllmenus();
    this.getMainMenu();
    this.getZones()
  }

  getMainMenu() {
     this.dashDefSer.getMainMenu(this.dashboardId).subscribe(
       (data: []) => {
         this.menus = data;
       }
     );
   }

  backToDashboards() {
      this.router.navigate([`${this.path + '/agri-dashboard'}`]);
  }

  onAddMenu(): void {
    const dialogRef = this.dialog.open(AddDashboardMainMenuComponent, {
      width: '600px',
      height: '430px',
      data: {id: this.dashboardId}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMainMenu();
    });
  }

  onEditMenu(id, name) {
    const dialogRef = this.dialog.open(AddDashboardMainMenuComponent, {
      width: '600px',
      height: '250px',
      data: {id, name}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMainMenu();
    });
  }

  onDeleteMenu(id) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      height: '350px',
      data: {id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMainMenu();
    });
  }

  onClick(event) {
    console.log('event');
    // this.dashListService.deleteDashboard(event.data.id).subscribe(
    //   (data) => {
    //     console.log(data);
    //   }
    // );
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  onImportMarket() {
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '500px',
      height: '250px',
      data: {type: 'markets'}
    });
    dialogRef.afterClosed().subscribe(result => {
     this.child.getCrops();
    });
  }

  onImportIrrigation() {
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '500px',
      height: '250px',
      data: {type: 'irrigation'}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index;
  }


  tabChangedStock = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.stock_index = tabChangeEvent.index;
  }

  getZones() {
    this.dashDefSer.getAllZones().subscribe(
      data => {
        var keys = Object.keys(data);
        var len = keys.length;
        this.zones = len;
        console.log(len)
      }
    )
  }
}
