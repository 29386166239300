import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-dashb-login',
  templateUrl: './dashb-login.component.html',
  styleUrls: ['./dashb-login.component.css']
})
export class DashbLoginComponent implements OnInit {
  loginForm: FormGroup;
  submitting = false;

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: Router) 
    { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.submitting = true;
      this.authService.signin(this.loginForm.value).subscribe(
        (data: string) => {
          if (data.hasOwnProperty('id')) {
            this.authService.setUserCredentials(data['id'], data['email']);
            this.route.navigate(['/agri-dashboard']);
            // window.location.replace("/");
          } else {
             this.snackBar.open(data, '', {
               duration: 2000,
             });
          }
        },
        error => {
          console.error('Error login user', error);
        }
      );
      this.submitting = false;
    }
  }

  toRegister() {
    this.route.navigate(['/signup'])
  }
}
