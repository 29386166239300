import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting-approval',
  templateUrl: './waiting-approval.component.html',
  styleUrls: ['./waiting-approval.component.css']
})
export class WaitingApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
