import { Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { DashboardDefinitionService } from '../dashboard-definition.service';
import { FormControl, Validators } from '@angular/forms';

export interface DialogData {
  id;
  type;
}
@Component({
  selector: 'app-change-currency',
  templateUrl: './change-currency.component.html',
  styleUrls: ['./change-currency.component.css']
})
export class ChangeCurrencyComponent implements OnInit {
  isLoading = false;
  rate = new FormControl('', Validators.required);
  constructor(
    public dialogRef: MatDialogRef<ChangeCurrencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dashDefServ: DashboardDefinitionService,
    private snackbar: MatSnackBar
    ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() { }

  onSubmit() {
    this.isLoading = true;
    this.dashDefServ.addRate({rate: this.rate.value}).subscribe(
      (data) => {
        this.onNoClick();
        this.snackbar.open('rate exchange updated successfully!', '', {
          duration: 2000,
        });
      }
    );
    }
  getErrorMessage() {
      return 'You must enter a value';
    }

}

