import { Component, OnInit, EventEmitter, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { MatDialog, MatTabChangeEvent } from '@angular/material';
import { ImportFileComponent } from '../import-file/import-file.component';
import { AddProductionComponent } from '../add-production/add-production.component';
import { CropProductionInfoComponent } from '../crop-production-info/crop-production-info.component';

@Component({
  selector: 'app-main-production-dashb',
  templateUrl: './main-production-dashb.component.html',
  styleUrls: ['./main-production-dashb.component.css']
})
export class MainProductionDashbComponent implements OnInit, AfterViewInit {
  @Input() menuId: string;
  @Input() dashboardName: string;
  @Output() onFetchGraphData = new EventEmitter<string>();
  @ViewChild(CropProductionInfoComponent, {static:false}) child: CropProductionInfoComponent;

  crops;
  cropId;
  cropName;
  crop_names = [
    "KAHAWA",
    "MKONGE",
    "CHAI",
    "PAMBA",
    "PARETO",
    "KOROSHO",
    "TUMBAKU",
    "SUKARI",
    "MAHINDI",
    "MCHELE",
    "MTAMA",
    "MAHARAGWE",
    "NYANYA",
    "VIAZI MVIRINGO",
    "VITUNGUU MAJI",
    "PILIPILI HOHO",
    "TIKITIMAJI",
    "NANASI",
    "TANGO",
  ];
  noChange = false;
  selected = 0;
  // date
  unitMeasure;
  index = 0;
  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private dashDefService: DashboardDefinitionService,
  ) {
    console.log(document.getElementById('list')) }

  ngOnInit() {
    this.cropName = 'COTTON'
    this.getCrops();
  }

  ngAfterViewInit() {
    // this.child.getStats()
  }

  getColor(change) {
    if (change > 0) {
        return '#116B38';
    }
    if (change < 0) {
        return '#C81212';
    }
    if (change === 0) {
      return '#0A3EA6';
  }
  }

  getCrops() {
    if (this.menuId == '8') {this.menuId = '1'}
    if (this.menuId == '9') {this.menuId = '2'}
    if (this.menuId == '10') {this.menuId = '5'}
    this.dashDefService.getProductionCrops(this.menuId, localStorage.getItem('language')).subscribe(
      (data: []) => {
        this.crops = data;
        console.log(this.crops);
        if (this.crops.length !== 0) {;
           this.cropId = this.crops[0].id;
           this.cropName = this.crops[0].name;
           this.dashDefService.setCropName(this.cropName.toUpperCase());
        }
      }
    );
  }


  getUpperCase(value: string) {
    return value.toUpperCase();
  }


  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollable() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noCrops() {
    if (this.crops.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index;
    // this.child.s
  }


  onChangeCrop(cropId, cropName, i, event) {
    console.log(cropId);
    this.cropId = cropId;
    this.cropName = cropName;
    this.selected = i;
    console.log(event.target.innerText)
    this.dashDefService.setCropName(cropName.toUpperCase());
    console.log(document.getElementById('list'))
   }

   onAddProduction() {
    const dialogRef = this.dialog.open(AddProductionComponent, {
      width: '500px',
      height: '500px',
      data: {cropId: this.cropId}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.child.getStats();
    });
  }

  onImportProduction(type) {
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '500px',
      height: '250px',
      data: {cropId: this.cropId, type}
    });
    dialogRef.afterClosed().subscribe(result => {
     this.child.getStats();
    });
  }

  onImportConsumption(type) {
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '500px',
      height: '250px',
      data: {cropId: this.cropId, type}
    });
    dialogRef.afterClosed().subscribe(result => {
     this.child.getStats();
    });
  }
}
