import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-login',
  templateUrl: './main-login.component.html',
  styleUrls: ['./main-login.component.css']
})
export class MainLoginComponent implements OnInit {

  action
  year = (new Date()).getFullYear()
  authForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  constructor(
    private fb: FormBuilder,
    private authServ: AuthService,
    private snackBar: MatSnackBar,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.action = environment.baseUrl + "/mkilimo/auth/login"
    // if (this.authServ.isKilimo) {
    //   this.action = "http://ekilimo.multics.co.tz/mkilimo/auth/login"
    // } else {
    //   this.action = "http://ekilimo.multics.co.tz/frs/auth/login"
    // }
  }

  onSubmit() {
    this.authServ.login(this.authForm.value).subscribe(
      (data: string) => {
        if (data.hasOwnProperty('id')) {
          this.authServ.setCredentials(data['id'], data['username'], data['role'], data['status']);
          // this.route.navigate(['/dashboards']);
          this.route.navigate(['/agri-dashboard']);
          window.location.href.replace("/", '#/agri-dashboard')
          //  window.location.href.replace('/login', '');
         } else {
           this.snackBar.open(data, 'Wrong password or username', {
             duration: 2000,
           });
         }
      }
    );
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  // submit() {
  //   querySelector('form#external-form').submit()
  // }

  toSignup() {
    this.route.navigate(['signup'])
  }

}
