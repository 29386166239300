import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { AddDashboardComponent } from '../../dashboard-listing/add-dashboard/add-dashboard.component';
import { DashlistService } from '../../dashboard-listing/dashlist.service';

@Component({
  selector: 'app-firstview',
  templateUrl: './firstview.component.html',
  styleUrls: ['./firstview.component.css'],
  providers: [AuthService, DashlistService,]
})
export class FirstviewComponent implements OnInit {
  //base url
  apiURL = environment.apiUrl;
  baseUrl = environment.baseUrl;

  path;
  dashboards;
  bulkDashboards;
  selectedDashboardId = 0;

  constructor(
    private router: Router,
    public authService: AuthService,
    private dashListService: DashlistService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    if(!this.authService.isAuth) {
      this.router.navigate(['/login'])
    }
    this.path = this.authService.path;
    this.getDashboards();
  }
  toDashboards() {
    this.router.navigate([`${this.path + '/dashboards'}`]);
   //window.location.href = this.apiURL + '/mkilimo/auth/login';
  }
  toLogin() {
    //this.router.navigate([`${this.path + '/login'}`]);
    window.location.href = this.apiURL + '/mkilimo/auth/login';
  }
  getDashboards() {
    if (localStorage.getItem('language') == null) {
      this.authService.setLanguage('en');
    }
    this.dashListService.getDashboards().subscribe(
      (data) => {
        this.dashboards = data;
        this.bulkDashboards = data;
      }
    );
  }
  toDashboard(id, name) {
    this.selectedDashboardId = id;
    if(name.toUpperCase() != 'AGRI-VISUAL') {
      this.router.navigate(['/dashboard/' + id, { name }], { skipLocationChange: false });
    } else {
      window.location.href = this.baseUrl + '/frs/maps';
    }
    // if (this.authService.isAuth) {
    //   this.selectedDashboardId = id;
    //   // if(this.authService.isApproved) {
    //   //   this.router.navigate(['/dashboard/' + id, {name}], { skipLocationChange: false });
    //   // } else {
    //   //   this.router.navigate(['/waiting-approval'])
    //   // }
    // } else {
    //   //this.router.navigate(['/login'])
    //   window.location.href = this.apiURL + '/mkilimo/auth/login';
    // }
  }

  getColor(index) {
    if ((index) % 3 === 0) {
      return '#0732A2';
    } else if ((index) % 3 === 1) {
      return '#ff7708';
    } else {
      return '#e83e8c';
    }
  }

  onAddDashboard() {
    const dialogRef = this.dialog.open(AddDashboardComponent, {
      width: '500px',
      height: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDashboards();
    });
  }

  onDelete(event) {
    this.dashListService.deleteDashboard(event.data.id).subscribe(
      (data) => {
        this.getDashboards();
      }
    );
  }

}
