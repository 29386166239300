import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DistrictCoordinates} from './models/district-model';
import { RegionData } from './models/region-model';
import { WeatherData } from './models/weather-model';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {
  constructor(private http:HttpClient) { }
    getWeatherForecast(latitude,longitude: any):Observable<WeatherData>{
    return  this.http.get<WeatherData>(environment.weatherApiBaseUrl,{
        params:new HttpParams()
        .set('lat',latitude)
        .set('lon',longitude)   
      })    
  }
  getRegions(){
    return this.http.get(`${environment.apiUrl}/regions`)
  }
  getDistricts(regionId){
    return this.http.get(`${environment.apiUrl}/districts/${regionId}`)       
  }
  getDistrict(districtId):Observable<DistrictCoordinates>{
    return this.http.get<DistrictCoordinates>(`${environment.apiUrl}/districtCoordinates/${districtId}`)       
  }
}
