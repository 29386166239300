import { Component, Inject, OnInit} from '@angular/core';
import { FormControl, Validators, FormArray, FormControlDirective } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DashboardDefinitionService } from '../dashboard-definition.service';

export interface DialogData {
  cropId;
  type;
}

@Component({
  selector: 'app-add-production',
  templateUrl: './add-production.component.html',
  styleUrls: ['./add-production.component.css']
})
export class AddProductionComponent implements OnInit {
  regions;
  districts;
  seasons;


  productionForm = this.fb.group({
   cropId: ['', Validators.required],
   details: this.fb.array([
    this.fb.group({
    district:  ['', Validators.required],
    region:  ['', Validators.required],
    production:  ['', Validators.required],
    season:  ['', Validators.required],
    })])
  });

  constructor(
    public dialogRef: MatDialogRef<AddProductionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dashDefServ: DashboardDefinitionService,
    private fb: FormBuilder,
    // private snackbar: MatSnackBar
    ) {
      this.productionForm.get('cropId').setValue(data.cropId);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getRegions();
    this.getSeasons();
  }

  getRegions() {
   this.dashDefServ.getRegions().subscribe(
     (data) => {
       this.regions = data;
     }
   );
  }

  getDistricts() {
    console.log(this.productionForm.value.details[0].region);
    this.dashDefServ.getDistricts(this.productionForm.value.details[0].region).subscribe(
      (data) => {
        this.districts = data;
      }
    );
  }

  getSeasons() {
    this.dashDefServ.getSeasons().subscribe(
      (data : Seasons) => {
        this.seasons = data.seasons;
      }
    );
  }


  getErrorMessage() {
    return 'You must enter a value';
  }

  get stocks() {
    return this.productionForm.get('details') as FormArray;
  }

  addAlias() {
    this.stocks.push(
      this.fb.group({
        district:  ['', Validators.required],
        region:  ['', Validators.required],
        production:  ['', Validators.required],
      })
    );
  }

  removeAlis(index) {
    this.stocks.removeAt(index);
  }

  onSubmit() {
    if (this.productionForm.valid) {
      this.dashDefServ.addCropProduction(this.productionForm.value).subscribe(
        (data) => {
          this.onNoClick();
        }
      );
    } else {
      console.log('invalid');
    }
    }

}
export interface Seasons {
  seasons;
}
