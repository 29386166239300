import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
const url1 = apiUrl + '/aims/prodval-animal-products/';
const url2 = apiUrl + '/aims/prodval-aqua-fish/';
const url3 = apiUrl + '/aims/prodval-forestry/';
const url4 = apiUrl + '/aims/prodval-animal-number/';
const url5 = apiUrl + '/aims/prodval-crops/';

const url6 = apiUrl + '/aims/prodval-animal-products-monthly/';
const url7 = apiUrl + '/aims/prodval-aqua-fish-monthly/';
const url8 = apiUrl + '/aims/prodval-forestry-monthly/';
const url9 = apiUrl + '/aims/prodval-animal-number-monthly/';
const url10 = apiUrl + '/aims/prodval-crops-monthly/';

@Injectable({
  providedIn: 'root'
})
export class ProdvalService {

  constructor(private http: HttpClient) { }
  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Requested-Width': 'XMLHttpRequeest',
    }
  );

  /*================ yearly values ===============*/
  getAnimalProductValues(itemId) {
    return this.http.get(url1 + itemId, {headers: this.headers});
  }

  getAquaFishValues(itemId) {
    return this.http.get(url2 + itemId, {headers: this.headers});
  }

  getForestryValues(itemId) {
    return this.http.get(url3 + itemId, {headers: this.headers});
  }

  getAnimalNumberValues(itemId) {
    return this.http.get(url4 + itemId, {headers: this.headers});
  }

  getCropsValues(itemId) {
    return this.http.get(url5 + itemId, {headers: this.headers});
  }

  
  /*================ monthly values ===============*/
  getAnimalProductMonthlyValues(year) {
    return this.http.get(url6 + year, {headers: this.headers});
  }

  getAquaFishMonthlyValues(year) {
    return this.http.get(url7 + year, {headers: this.headers});
  }

  getForestryMonthlyValues(year) {
    return this.http.get(url8 + year, {headers: this.headers});
  }

  getAnimalNumberMonthlyValues(year) {
    return this.http.get(url9 + year, {headers: this.headers});
  }

  getCropsMonthlyValues(year) {
    return this.http.get(url10 + year, {headers: this.headers});
  }
}
