import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell-product',
  templateUrl: './sell-product.component.html',
  styleUrls: ['./sell-product.component.css']
})
export class SellProductComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
