import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardIndexComponent } from './modules/dashboard-definition/dashboard-index/dashboard-index.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardListComponent } from './modules/dashboard-listing/dashboard-list/dashboard-list.component';
import { CostsComponent } from './modules/settings/costs/costs.component';
import { FirstviewComponent } from './modules/first/firstview/firstview.component';
import { AboutUsComponent } from './modules/first/about-us/about-us.component';
import { UsersComponent } from './modules/dashboard-definition/users/users.component';
import { APP_BASE_HREF } from '@angular/common';
import { IndexComponent } from './modules/integration/index/index.component';
import { MainLoginComponent } from './modules/integration/main-login/main-login.component';
import { FarmerRegistrationComponent } from './modules/integration/farmer-registration/farmer-registration.component';
import { ExtServicesComponent } from './modules/integration/extension-services/ext-services/ext-services.component';
import { AskQuestionComponent } from './modules/integration/extension-services/ask-question/ask-question.component';
import { ExtOfficersRegistrationComponent } from './modules/integration/extension-services/ext-officers-registration/ext-officers-registration.component';
import { MarketPlaceComponent } from './modules/integration/emarket/market-place/market-place.component';
import { SellProductComponent } from './modules/integration/emarket/sell-product/sell-product.component';
import { SignupComponent } from './auth/signup/signup.component';
import { WaitingApprovalComponent } from './auth/waiting-approval/waiting-approval.component';
import { WeatherTodayComponent } from './modules/integration/weather-today/weather-today.component';
import { WeatherFutureComponent } from './modules/integration/weather-future/weather-future.component';
import { HelpCenterComponent } from './modules/integration/help-center/help-center.component';
import { MainAimsDashbComponent } from './modules/dashboard-definition/aims/main-aims-dashb/main-aims-dashb.component';
import { CropsDashbComponent } from './modules/dashboard-definition/aims/crops/crops-dashb/crops-dashb.component';
import { ItemsDashbComponent } from './modules/dashboard-definition/aims/items/items-dashb/items-dashb.component';
import { LandcoverDashbComponent } from './modules/dashboard-definition/aims/landcover/landcover-dashb/landcover-dashb.component';
import { AniplantDashbComponent } from './modules/dashboard-definition/aims/animal-plant-health/aniplant-dashb/aniplant-dashb.component';
import { LivestockDashbComponent } from './modules/dashboard-definition/aims/livestock/livestock-dashb/livestock-dashb.component';
import { NationalAccountDashbComponent } from './modules/dashboard-definition/aims/national-accounts/national-account-dashb/national-account-dashb.component';
import { PublicFinanceDashbComponent } from './modules/dashboard-definition/aims/public-finances/public-finance-dashb/public-finance-dashb.component';
import { SocioeconomyDashbComponent } from './modules/dashboard-definition/aims/socioeconomic/socioeconomy-dashb/socioeconomy-dashb.component';
import { TradequantityMainComponent } from './modules/dashboard-definition/aims/tradequantity/tradequantity-main/tradequantity-main.component';
import { TradequantityDashbComponent } from './modules/dashboard-definition/aims/tradequantity/crops/tradequantity-dashb/tradequantity-dashb.component';
import { AnimalsDashbComponent } from './modules/dashboard-definition/aims/tradequantity/animalsprod/animals-dashb/animals-dashb.component';
import { AnimalnumberDashbComponent } from './modules/dashboard-definition/aims/tradequantity/animalnumber/animalnumber-dashb/animalnumber-dashb.component';
import { AquafishDashbComponent } from './modules/dashboard-definition/aims/tradequantity/aquafish/aquafish-dashb/aquafish-dashb.component';
import { AgriproductDashbComponent } from './modules/dashboard-definition/aims/tradequantity/agriproducts/agriproduct-dashb/agriproduct-dashb.component';
import { ProdvalMainComponent } from './modules/dashboard-definition/aims/productionvalue/prodval-main/prodval-main.component';
import { DashbRegisterComponent } from './auth/dashb-register/dashb-register.component';
import { DashbLoginComponent } from './auth/dashb-login/dashb-login.component';


var path = true;
const myPath = '';
// var myPath = 'agri-dashboard/public';
if(myPath == '') {
  path = false;
}

const routes: Routes = [
  {path: 'login', component: DashbLoginComponent},
  {path: 'signup', component: DashbRegisterComponent},
  {path: 'waiting-approval', component: WaitingApprovalComponent},
  {path: 'dashboards', component: DashboardListComponent},
  {path: '', component: IndexComponent},
  {path: 'agri-dashboard', component: FirstviewComponent},
  {path: 'about-us', component: AboutUsComponent},
  // {path: '', component: DashboardListComponent},
  {path: 'dashboard/:id', component: DashboardIndexComponent},
  {path: 'costs', component: CostsComponent},
  {path: 'users', component: UsersComponent},
  {path: 'ext-services', component: ExtServicesComponent},
  {path: 'ext-services/ask-question', component: AskQuestionComponent},
  {path: 'ext-services/officers-registration', component: ExtOfficersRegistrationComponent},
  {path: 'emarket/market-places', component: MarketPlaceComponent},
  {path: 'emarket/sell-products', component: SellProductComponent},
  {path: 'farmer-registration', component: FarmerRegistrationComponent},
  {path: 'weatherDayForecast', component: WeatherTodayComponent},
  {path: 'weather-information', component: WeatherFutureComponent},
  {path: 'help-center', component: HelpCenterComponent},

  // aims
  {path: 'aims/crops', component: CropsDashbComponent},
  {path: 'aims/livestock', component: LivestockDashbComponent},
  {path: 'aims/socio-economics', component: SocioeconomyDashbComponent},
  {path: 'aims/land-cover', component: LandcoverDashbComponent},
  {path: 'aims/national-accounts', component: NationalAccountDashbComponent},
  {path: 'aims/public-finance', component: PublicFinanceDashbComponent},
  {path: 'aims/trade-quantity', component: TradequantityMainComponent},
  {path: 'aims/trade-quantity/crops', component: TradequantityDashbComponent},
  {path: 'aims/trade-quantity/animal-products', component: AnimalsDashbComponent},
  {path: 'aims/trade-quantity/animal-number', component: AnimalnumberDashbComponent},
  {path: 'aims/trade-quantity/aquaculture-and-fisheries', component: AquafishDashbComponent},
  {path: 'aims/trade-quantity/agriculture-products-and-goods', component: AgriproductDashbComponent},
  {path: 'aims/production-value-price', component: ProdvalMainComponent},
  {path: 'aims/:item', component: ItemsDashbComponent},
  {path: 'aims/type-2/:item', component: LandcoverDashbComponent},
  {path: 'aims/aniplant-health/animal-plant-health', component: AniplantDashbComponent},


];

@NgModule({
  // providers: [{provide: APP_BASE_HREF, useValue: myPath}],
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
