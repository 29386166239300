import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
import HC_exporting from 'highcharts/modules/exporting';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { AddProductionComponent } from 'src/app/modules/dashboard-definition/add-production/add-production.component';
import { MatDialog } from '@angular/material/dialog';
import { async } from 'rxjs/internal/scheduler/async';
HC_exporting(Highcharts);

@Component({
  selector: 'app-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.css']
})
export class BarGraphComponent implements OnInit {
  private _cropId;
  private _regions;
  private _districts;
  private _yLabel;
  private _title;
  private _color;
  private _remain;
  private _sells;
  Highcharts = Highcharts;
  chartOptions: {};
  chartConstructor = 'chart';

  @Input() set cropId(value) {
    this._cropId = value;
    this.setOption();
  }
  get cropId() {
    return this._cropId;
  }
  @Input() set regions(value) {
    this._regions = value;
    this.setOption();
  }
  get regions() {
    return this._regions;
  }
  @Input() set remains(value) {
    this._remain = value;
    this.setOption();
  }
  get remains() {
    return this._remain;
  }
  @Input() set sells(value) {
    this._sells = value;
    this.setOption();
  }
  get sells() {
    return this._sells;
  }
  @Input() set districts(value) {
    this._districts = value;
    this.setOption();
  }
  get districts() {
    return this._districts;
  }
  @Input() set yLabel(value) {
    this._yLabel = value;
    this.setOption();
  }
  get yLable() {
    return this._yLabel;
  }
  @Input() set title(value) {
    this._title = value;
    this.setOption();
  }
  get title() {
    return this._title;
  }
  @Input() set color(value) {
    this._color = value;
    this.setOption();
  }
  get color() {
    return this._color;
  }


  constructor( ) { }

  ngOnInit() {
    this.setOption();
  }

  setOption() {
    this.chartOptions = {
      chart: {
          type: 'column'
      },
      title: {
          text: this.title
      },
      subtitle: {
          text: 'Click the columns to view district councils data.'
      },
      accessibility: {
          announceNewData: {
              enabled: true
          }
      },
      xAxis: {
          type: 'category'
          // categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
      },
      yAxis: {
          title: {
              text: this.yLabel
          }

      },
      legend: {
          enabled: true
      },
      plotOptions: {
          series: {
              borderWidth: 0,
              // dataLabels: {
              //     enabled: true,
              //     format: '{point.y} Tonnes',
              //     color: '#4B4E4C'
              // }
          },
         column: {
            // color: this.color
         }
      },

      tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} Tonnes</b>'
      },
      credits: {
      enabled: false
      },
      series: [
          {
              name: 'Production',
              colorByPoint: false,
              data: this.regions
          },
          {
            name: 'Sales',
            colorByPoint: false,
            data: this.sells
        },
        {
          name: 'Remain',
          colorByPoint: false,
          data: this.remains
      }
      ],
      drilldown: {
          series: this.districts
      }
  };
  }
}
