import { Component, Input, OnInit } from '@angular/core';
import { AimsService } from '../../aims.service';
import { LivestockService } from '../../livestock/livestock.service';

@Component({
  selector: 'app-socioeconomy-menu',
  templateUrl: './socioeconomy-menu.component.html',
  styleUrls: ['./socioeconomy-menu.component.css']
})
export class SocioeconomyMenuComponent implements OnInit {
  @Input() menuId: string;
  @Input() dashboardName: string;

  itemId;
  itemName;
  items;
  selected = 0;

  wait = true;
  values = [];
  wait_values = true;
  constructor(
    public aimsServ: AimsService,
    public livestock_serv: LivestockService,
    ) 
    { }

  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this.livestock_serv.getItemsByIndicatorCategory(this.menuId).subscribe(
      (data: []) => {
        this.items = data;
        // console.log(this.items[0]);
        if (this.items.length !== 0) {;
           this.itemId = this.items[0].id;
           this.itemName = this.items[0].title;
           this.getItemValues();
        }
        this.wait = false;
      }
    );
  }
  isSScrollAble() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noItems() {
    if (this.items.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  getUpperCase(value: string) {
    var val = '';
    if(value) {
      val = value.toUpperCase();;
    } else {
      val = value;
    }
    return val;
  }

  onChangeItem(itemId, itemName, i, event) {
    this.wait_values = true;

    this.itemId = itemId;
    this.itemName = itemName;
    this.selected = i;
    // console.log(event.target.innerText)
    // console.log(i)
    // console.log(document.getElementById('list'))
    this.getItemValues();
   }


  getItemValues() {
    this.aimsServ.getItemValues(this.itemId, this.dashboardName).subscribe(
      (data: []) => {
        this.values = data;
        this.wait_values = false;
      }
    );
  }
}
