import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {
  //base url
  baseURL = environment.baseUrl;
  loginUrl = environment.loginUrl

  language;
  app;
  app_desc;

  isHome = true
  isExt = false
  iseMarket = false
  isFrs = false
  isDashb = false
  showLogin = true
  constructor(
    private route: Router,
    public authService: AuthService,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('language'));
  }

  ngOnInit(): void {
    this.language = this.authService.getLanguage() || 'en'
    this.app = localStorage.getItem('app')
    this.app_desc = localStorage.getItem('app_description')

    if (this.route.url != '/') {
      if (this.route.url.includes('ext-services')) {
        this.isExt = true
      }
      if (this.route.url.includes('emarket')) {
        this.iseMarket = true
      }
      if (this.app_desc == 'frs') {
        this.isFrs = true
      }
      if (this.app_desc == 'dashb') {
        this.isDashb = true
      }
      this.showLogin = true
      this.isHome = false
    } else {
      this.showLogin = false
      this.isHome = true
    }
  }

  toDash() {
    this.route.navigate(['/']);
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  toLoginPage() {
    window.location.href = this.baseURL + '/mkilimo/auth/login';
  }

  marketPlaces() {
    window.location.href = this.baseURL + '/mkilimo/public/market-places/sellers';
  }

  sellProducts() {
    window.location.href = this.baseURL + '/mkilimo/public/market-places/sell-products';
  }

  askQuestion() {
    window.location.href = this.baseURL + '/mkilimo/public/questions';
  }

  extServices() {
    window.location.href = this.baseURL + '/mkilimo/public/officers';
  }

  officersRegistration() {
    window.location.href = this.baseURL + '/mkilimo/public/officers/registration';
  }

  setLanguage(value) {
    this.language = value;
    this.authService.setLanguage(value);
  }

  toLogin(value, desc) {
    this.authService.setCurrentApp(value)
    this.authService.setCurrentAppItem(desc)
    if (desc == 'ext') {
      window.location.href = this.baseURL + '/mkilimo/public/officers';
    }

    if (desc == 'emarket') {
      window.location.href = this.baseURL + "/mkilimo/public/market-places/sellers"
    }

    if (desc == 'frs') {
      window.location.href = this.baseURL + "/frs"
    }

    if (desc == 'dashb') {
      this.route.navigate(['agri-dashboard'])
      // window.location.href = this.baseURL + '/mkilimo/auth/login';
    }
  }
}
