import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ForecastService } from 'src/app/forecast.service';
import { WeatherData } from 'src/app/models/weather-model';
import {Chart} from 'node_modules/chart.js';
import { RegionData } from 'src/app/models/region-model';
import { DistrictCoordinates } from 'src/app/models/district-model';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-main-weather-dashb',
  templateUrl: './main-weather-dashb.component.html',
  styleUrls: ['./main-weather-dashb.component.css']
})
export class MainWeatherDashbComponent implements OnInit {
  selectedDistrict = ''
  iconSun;
  iconCloud;
  iconMoon;
  iconRain;
  createGroupForm:FormGroup;
  regions:{};
  districts:{};
  chart = [];
  constructor(private forecastService:ForecastService) { }
  weatherData?:WeatherData;  
  regionData?:RegionData;
  districtCoordinates?:DistrictCoordinates;
  latitude:any = -10.3725;
  longitude:any = 38.76251;

  ngOnInit() {
    this.getWeatherData(this.latitude,this.longitude);
    this.forecastService.getRegions().subscribe(
      data => {this.regions = data;
       console.log(this.regions);
      }
     );
     this.createGroupForm = new FormGroup({
       region : new FormControl(''),
       district : new FormControl('')
     })
    }
 onChangeRegion(regionId){
   if(regionId){
     this.forecastService.getDistricts(regionId).subscribe(
       data => {
         this.districts = data;
         console.log(this.districts);
       }
      );
   }
   else{
     this.districts = null;
   }
   }
   onChangeDistrict(id):void{
    this.forecastService.getDistrict(id).subscribe(
      response=>{
        this.districtCoordinates = response;
        console.log(this.districtCoordinates);
        const latitude = this.districtCoordinates[0].latitude;
        const longitude = this.districtCoordinates[0].longitude;
        this.selectedDistrict = this.districtCoordinates[0].name;
        console.log(latitude);
        console.log(longitude);
        this.getWeatherData(latitude,longitude);
        if(this.weatherData.properties.timeseries[0].data.instant.details.air_temperature>19.9){
          this.iconSun=true;
          this.iconCloud=false;
          this.iconMoon=false;
          this.iconRain=false;
        }
        else if(this.weatherData.properties.timeseries[0].data.instant.details.air_temperature<=19.9){
          this.iconCloud=true;
          this.iconSun=false;
          this.iconMoon=false;
          this.iconRain=false;
        }
       else if(this.weatherData.properties.timeseries[0].data.next_1_hours.details.precipitation_amount>0){
          this.iconRain=true;
          this.iconSun=false;
          this.iconCloud=false;
          this.iconMoon=false;
        }
        else if(this.weatherData.properties.timeseries[0].data.next_1_hours.summary.symbol_code=='fair_night'){
          this.iconRain=false;
          this.iconSun=false;
          this.iconCloud=false;
          this.iconMoon=true;
        }
        else{
          this.iconRain=false;
          this.iconSun=false;
          this.iconCloud=false;
          this.iconMoon=false;
        }       
      }
     ); 
}

private getWeatherData(latitude,longitude:any){
  this.forecastService.getWeatherForecast(latitude,longitude).subscribe({
    next:(respnse)=>{
      this.weatherData = respnse;      
      console.log(respnse);  
      if(this.weatherData.properties.timeseries[0].data.instant.details.air_temperature>19.9){
        this.iconSun=true;
        this.iconCloud=false;
        this.iconMoon=false;
        this.iconRain=false;
      }
      else if(this.weatherData.properties.timeseries[0].data.instant.details.air_temperature<=19.9){
        this.iconCloud=true;
        this.iconSun=false;
        this.iconMoon=false;
        this.iconRain=false;
      }
     else if(this.weatherData.properties.timeseries[0].data.next_1_hours.details.precipitation_amount>0){
        this.iconRain=true;
        this.iconSun=false;
        this.iconCloud=false;
        this.iconMoon=false;
      }
      else if(this.weatherData.properties.timeseries[0].data.next_1_hours.summary.symbol_code=='fair_night'){
        this.iconRain=false;
        this.iconSun=false;
        this.iconCloud=false;
        this.iconMoon=true;
      }
      else{
        this.iconRain=false;
        this.iconSun=false;
        this.iconCloud=false;
        this.iconMoon=false;
      }  
let date = respnse.properties['timeseries'].map(respnse=>respnse.time);
let temperature = respnse.properties['timeseries'].map(respnse=>respnse.data.instant.details.air_temperature);
let humidity = respnse.properties['timeseries'].map(respnse=>respnse.data.instant.details.relative_humidity);
let windSpeed = respnse.properties['timeseries'].map(respnse=>respnse.data.instant.details.wind_speed);
let precipit = respnse.properties['timeseries'].map(respnse=>respnse.data.instant.details.fog_area_fraction);
      this.chart = new Chart("myChart", {
        type: 'line',
        data: {
            labels: date,
            datasets: [
              {
                label: 'Temperature °C',
                data: temperature,
                backgroundColor: ['transparent'
                   
                ],
                borderColor: [
                   'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)'
                    // 'red'
                ],
                borderWidth: 2
            },
           
          {
            label: 'Wind_Speed m/s',
            data: windSpeed,
            backgroundColor: ['transparent'
               
            ],
            borderColor: [
                'purple'
            ],
            borderWidth: 2
        },
        {
          label: 'Humidity %',
          data: humidity,
          backgroundColor: ['transparent'
             
          ],
          borderColor: ['#48929b'],
          borderWidth: 2
      },
      //   {
      //     label: 'Precipitation_Amount mm',
      //     data: precipit,
      //     backgroundColor: ['transparent'            
      //     ],
      //     borderColor: [
      //         'blue'
      //     ],
      //     borderWidth: 2
      // }
          ]
        },
         options:{
    scales: {
        y: {
            max: 5,
            min: 0,
            ticks: {
                stepSize: 5
            }
        }
    }
  }
    });
   
    }
  })
}

//  private getRegion(){
//   this.forecastService.getRegions().subscribe(
// {
//   next:(respnse)=>{
// this.regionData = respnse;
// console.log(respnse);
// }}
//   );
// }

// private getDistrict(){
//   this.forecastService.getDistricts().subscribe(
// {
//   next:(respnse)=>{
// this.districtData = respnse;
// console.log(respnse);
// }}
//   );
// }

}

