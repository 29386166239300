import { Component, Input, OnInit } from '@angular/core';
import { AimsService } from '../../aims.service';

@Component({
  selector: 'app-aniplant-menu',
  templateUrl: './aniplant-menu.component.html',
  styleUrls: ['./aniplant-menu.component.css']
})
export class AniplantMenuComponent implements OnInit {
  @Input() menuId: string;
  @Input() dashboardName: string;

  itemId;
  itemName;
  items;
  selected = 0;

  wait = true;
  indicators = [];
  wait_indicators = true;
  constructor(
    public aimsServ: AimsService
  ) { }

  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this.aimsServ.getItems(this.menuId).subscribe(
      (data: []) => {
        this.items = data;
        // console.log(this.items[0]);
        if (this.items.length !== 0) {;
           this.itemId = this.items[0].id;
           this.itemName = this.items[0].title;
           this.getItemIndicators();
        }
        this.wait = false;
      }
    );
  }

  getItemIndicators() {
    this.aimsServ.getIndicatorsByCategoryAndType('animal-plant-health', this.menuId).subscribe(
      (data: []) => {
        this.indicators = data;
        this.wait_indicators = false;
        // console.log(this.indicators)
      }
    );
  }

  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollAble() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noItems() {
    if (this.items.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  getUpperCase(value: string) {
    var val = '';
    if(value) {
      val = value.toUpperCase();;
    } else {
      val = value;
    }
    return val;
  }

  onChangeItem(itemId, itemName, i, event) {
    this.wait_indicators = true;

    this.itemId = itemId;
    this.itemName = itemName;
    this.selected = i;
    // console.log(event.target.innerText)
    // console.log(i)
    // console.log(document.getElementById('list'))
    this.getItemIndicators();
   }

}
