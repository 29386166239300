import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// const apiUrl = 'http://stocks.multics.co.tz/public/api';
const apiUrl = 'https://asds.kilimo.go.tz/public/api';
const url1 = apiUrl + '/warehouse_stock/';

// warehouse stock filters
const url2 = apiUrl + '/warehouse_region/';
const url3 = apiUrl + '/warehouse_region_and_year/';
const url4 = apiUrl + '/warehouse_year/';



// market
const url5 = apiUrl + '/stock_trend/';
// trading filters
const url6 = apiUrl + '/exclude_district_trading/';
const url7 = apiUrl + '/market_type_region_trading/';
const url8 = apiUrl + '/market_type_year_trading/';
const url9 = apiUrl + '/region_year_trading/';
const url10 = apiUrl + '/market_type_trading/';
const url11 = apiUrl + '/region_trading/';
const url12 = apiUrl + '/year_trading/';


const url13 = apiUrl + '/crops';
const url14 = apiUrl + '/regions';
const url15 = apiUrl + '/districts/';
@Injectable({
  providedIn: 'root'
})
export class StocksService {

  headers = new HttpHeaders({'Content-Type': 'application/json', 'X-Requested-Width': 'XMLHttpRequeest'});
  constructor(private http: HttpClient) { }


  stockWarehouseCharts(crop) {
    return this.http.get(url1 + crop, {headers: this.headers});
  }

  getWarehouseStockByRegion(region, crop) {
    return this.http.get(url2 + region + '/' + crop, {headers: this.headers});
  }

  getWarehouseStockByRegionAndYear(region, year, crop) {
    return this.http.get(url3 + region + '/' + year + '/'  + crop, {headers: this.headers});
  }

  getWarehouseStockByYear(year, crop) {
    return this.http.get(url4 + year + '/'  + crop, {headers: this.headers});
  }


  // market chart

  stockMarketCharts(cropId) {
    return this.http.get(url5 + cropId, {headers: this.headers});
  }

  excludeDistrictTrading(type, region, year, crop) {
    return this.http.get(url6 + type + '/' + region + '/' + year + '/' + crop, {headers: this.headers});
  }
  marketTypeRegionTrading(type, region, crop) {
    return this.http.get(url7 + type + '/' + region + '/' + crop, {headers: this.headers});
  }
  marketTypeYearTrading(type, year, crop) {
    return this.http.get(url8 + type + '/' + year + '/' + crop, {headers: this.headers});
  }
  regionYearTrading(region, year, crop) {
    return this.http.get(url9 + region + '/' + year + '/' + crop, {headers: this.headers});
  }
  onlyMarketTypeTrading(type, crop) {
    return this.http.get(url10 + type + '/' + crop, {headers: this.headers});
  }
  onlyRegionTrading(region, crop) {
    return this.http.get(url11 + region + '/' + crop, {headers: this.headers});
  }
  onlyYearTrading(year, crop) {
    return this.http.get(url12 + year + '/' + crop, {headers: this.headers});
  }

  
  getYears() {
    const year = new Date().getFullYear();
    const range = [];
    range.push(year);
    for (let i = 1; i <= 10; i++) {
        range.push(year - i);
    }

    return range;
  }

  
  // Crops
  getCrops() {
    return this.http.get(url13, {headers: this.headers});
  }
  getRegions() {
    return this.http.get(url14, {headers: this.headers});
  }
  getDistricts(regionId, id) {
    return this.http.get(url15 + regionId + '/' + id, {headers: this.headers});
  }
}
