import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
const url1 = apiUrl + '/aims/items_by_category_type/';
const url2 = apiUrl + '/aims/landcover-values/';

@Injectable({
  providedIn: 'root'
})
export class LandcoverService {

  constructor(private http: HttpClient) { }
  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Requested-Width': 'XMLHttpRequeest',
    }
  );

  getItemsByType(type) {
    return this.http.get(url1 + type, {headers: this.headers});
  }

  getItemValues(id, type) {
    return this.http.get(url2 + id + '/' + type, {headers: this.headers});
  }
}