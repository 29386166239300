import { Component, OnInit, Input } from '@angular/core';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';

@Component({
  selector: 'app-crop-production-info',
  templateUrl: './crop-production-info.component.html',
  styleUrls: ['./crop-production-info.component.css']
})
export class CropProductionInfoComponent implements OnInit {

  private _cropId: string;
  regionsProduction;
  seasonsProduction;
  regionsConsumption;
  seasonsConsumption;
  @Input() crop;
  @Input() set cropId(value) {
    this._cropId = value;
    this.getStats();
  }

  cropName;
  get cropId() {
    return this._cropId;
   }

  constructor(
    private dashDefService: DashboardDefinitionService
    ) { }

  ngOnInit() {
    this.getStats();
    // alert(this.crop)
  }

  getStats() {
    this.cropName = this.dashDefService.cropName;
    this.dashDefService.getCropProductionInfo(this.cropId).subscribe(
      (data: Result) => {
        this.seasonsProduction = data.seasonsProduction;
        this.regionsProduction = data.regionsProduction;
        this.seasonsConsumption = data.seasonsConsumption;
        this.regionsConsumption = data.regionsConsumption;

        console.log(this.seasonsProduction)
      }
    );
  }
}

export class Result {
  seasonsProduction;
  regionsProduction;
  seasonsConsumption;
  regionsConsumption;
}
