import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddMarketComponent } from '../add-market/add-market.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardDefinitionService } from '../dashboard-definition.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ImportFileComponent } from '../import-file/import-file.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-crop-price-details',
  templateUrl: './crop-price-details.component.html',
  styleUrls: ['./crop-price-details.component.css'],
  providers: [DashboardDefinitionService, AuthService]
})
export class CropPriceDetailsComponent implements OnInit {
  private _cropId;
  private _cropName;
  internationalMarkets;
  destinationMarkets;
  localMarkets;
  prices;
  usdPrices = [];
  markets;
  selected;

  market = new FormControl('');
  @Output() newMarketEditEvent = new EventEmitter<string>();

  @Input() set cropId(value) {
         this._cropId = value;
         this.getmarkets();
         this.getPrices(value, 'undefined');
   }
  @Input() set cropName(value) {
    this._cropName = value;
  }
  // tslint:disable-next-line: adjacent-overload-signatures
  get cropId() {
   return this._cropId;
  }
  // tslint:disable-next-line: adjacent-overload-signatures
  get cropName() {
    return this._cropName;
   }
  graphType = 'column';

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private dashDefService: DashboardDefinitionService
  ) { }

  ngOnInit() {
    this.getmarkets();
    // this.getPrices(this.cropId, 'undefined');
    this.selected = this.dashDefService.selected;
  }

  getPrices(value, value2) {
      this.dashDefService.getPrices(value, value2).subscribe(
        (data) => {
          this.prices = data[0];
          this.usdPrices = data[1];
          this.markets = data[2];
          // data.forEach(element => {
          //     this.usdPrices.push( [element[0], element[1] * 0.00043, ]);
          // });
          // console.log(this.prices)
        }
      );
  }

  getmarkets() {
    this.selected = this.dashDefService.selected;
     this.dashDefService.getMarkets(this.cropId).subscribe(
       (data: Market) => {
         this.internationalMarkets = data.international;
         this.localMarkets = data.local;
         console.log(this.localMarkets)
       }
     );
  }

  markertEdit() {
    this.getmarkets();
    this.newMarketEditEvent.emit('edit');
  }

  getColor(change) {
    if (change > 0) {
        return '#116B38';
    }
    if (change < 0) {
        return '#C81212';
    }
    if (change === 0) {
      return '#0A3EA6';
  }
  }

  setGraphType(type) {
    this.graphType = type;
  }

  onAddMarket(type) {
    const dialogRef = this.dialog.open(AddMarketComponent, {
      width: '500px',
      height: '500px',
      data: {cropId: this.cropId, type}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getmarkets();
    });
  }

  onAddMarketPrice(type) {
    const edit = true;
    const dialogRef = this.dialog.open(AddMarketComponent, {
      width: '500px',
      height: '350px',
      data: {cropId: this.cropId, type, edit}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getmarkets();
    });
  }

  onImportMarket(type) {
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '500px',
      height: '250px',
      data: {cropId: this.cropId, type}
    });
    dialogRef.afterClosed().subscribe(result => {
     this.getmarkets();
    });
  }

  getGraphPrices(value) {
    console.log(value)
    if(value.crop_type != null) {
      this.selected = value.name + ' (' + value.crop_type + ')';
    }
    else {
      this.selected = value.name;
    }
    this.getPrices(this.cropId, JSON.stringify(value));
  }


}

export interface Market {
  international;
  local;
  date;
}
