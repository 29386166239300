import { ProfileComponent } from './../profile/profile.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ChangeCurrencyComponent } from 'src/app/modules/dashboard-definition/change-currency/change-currency.component';
import { AddSeasonComponent } from './../../../dashboard-definition/add-season/add-season.component';
import { AddUsersComponent } from './../../../dashboard-definition/add-users/add-users.component';
import { AddRolesComponent } from './../../../dashboard-definition/add-roles/add-roles.component';
import { AddUnitMeasureComponent } from './../../../dashboard-definition/add-unit-measure/add-unit-measure.component';
import { TranslateService } from '@ngx-translate/core';
import { UsdTshTodayComponent } from 'src/app/modules/dashboard-definition/usd-tsh-today/usd-tsh-today.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [AuthService]
})
export class HeaderComponent implements OnInit {
  baseURL = environment.apiUrl;


  path;

  username = this.authService.getUsername();
  usernameInitial;
  private _isMarket;
  selected = 'TZS';
  language;
  clicked = 0;

  @Input() graphType;

  get isMarket() {
    return this._isMarket;
  }
  @Input() set isMarket(value) {
    if (value === '1') {
      this._isMarket = true;
    } else {
      this._isMarket = false;
    }
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('language'));
    if (this.username !== null) {
      this.usernameInitial = this.username.charAt(0).toUpperCase();
    }
   }

  ngOnInit() {
    this.language = this.authService.getLanguage() || 'en'
    this.path = this.authService.path;
   }
   
  backToDashboards() {
    // this.router.navigate([`${ this.path + '' }`]);
    window.location.href = environment.baseUrl + "/mkilimo/switchboard";
  }
  onLogout() {
    this.authService.removeCredentials();
    this.router.navigate([`${ this.path + '/login' }`]);
  }
  toLogin() {
    this.router.navigate([`${ this.path + '/login' }`]);
  }
  onAboutUs() {
    this.router.navigate([`${ this.path + '/about-us' }`]);
  }
  onTransport() {
    this.router.navigate([`${ this.path + '/costs' }`]);
  }
  setCurrency(value) {
    this.selected = value.toUpperCase();
    this.authService.setCurrency(value);
  }
  onChangeCurrency() {
    const dialogRef = this.dialog.open(ChangeCurrencyComponent, {
      width: '500px',
      height: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
     console.log('closed');
    });
  }

  addSeason() {
    const dialogRef = this.dialog.open(AddSeasonComponent, {
      width: '500px',
      height: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
     console.log('closed');
    });
  }

  addRoles() {
    const dialogRef = this.dialog.open(AddRolesComponent, {
      width: '500px',
      height: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
     console.log('closed');
    });
  }

  addUsers() {
    const dialogRef = this.dialog.open(AddUsersComponent, {
      width: '600px',
      height: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {
     console.log('closed');
    });
  }

  addUnitMeasure() {
    const dialogRef = this.dialog.open(AddUnitMeasureComponent, {
      width: '500px',
      height: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
     console.log('closed');
    });
  }

  setLanguage(value) {
    this.language = value;
    this.authService.setLanguage(value);
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  toUsers() {
    this.router.navigate([`${ this.path + 'users' }`]);
  }

  manageProfile() {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '500px',
      height: '470px',
      data: {action: 'profile'}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  changePassword() {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '500px',
      height: '350px',
      data: {action: 'passwords'}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  todayConversion() {
    const dialogRef = this.dialog.open(UsdTshTodayComponent, {
      width: '500px',
      height: '330px',
      data: {action: 'passwords'}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  toLoginApp(value, desc) {
    this.authService.setCurrentApp(value)
    this.authService.setCurrentAppItem(desc)
    if (desc == 'ext') {
      window.location.href = environment.baseUrl + '/mkilimo/public/officers';
    }

    if (desc == 'emarket') {
      window.location.href = environment.baseUrl + "/mkilimo/public/market-places/sellers"
    }

    if (desc == 'frs') {
      window.location.href = environment.baseUrl + "/frs"
    }

    if (desc == 'dashb') {
      this.router.navigate(['/agri-dashboard'])
      // window.location.href = this.baseURL + '/mkilimo/auth/login';
    }
  }
  toDashboard(id, name) {
    this.router.navigate(['/dashboard/' + id, {name}], { skipLocationChange: false });
    // if (this.authService.isAuth) {
    //   if(this.authService.isApproved) {
    //     this.router.navigate(['/dashboard/' + id, {name}], { skipLocationChange: false });
    //   } else {
    //     this.router.navigate(['/waiting-approval'])
    //   }
    // } else {
    //   this.router.navigate(['/login'])
    // }
  }

  toDash() {
    this.router.navigate(['/']);
  }
  onChangeDashboard(i, event) {
    this.clicked = i;
    console.log(event.target.innerText)
   }
}
