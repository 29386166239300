import { Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators, FormArray, FormControlDirective} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DashboardDefinitionService } from '../../dashboard-definition/dashboard-definition.service';
import { DashlistService } from '../dashlist.service';

export interface DialogData {
  id;
}

@Component({
  selector: 'app-add-dashboard',
  templateUrl: './add-dashboard.component.html',
  styleUrls: ['./add-dashboard.component.css'],
  providers: [DashlistService]
})
export class AddDashboardComponent implements OnInit {
  dashboardForm = this.fb.group({
   dashboards: this.fb.array([
    this.fb.group({
    name:  ['', Validators.required],
    })])
  });

  constructor(
    public dialogRef: MatDialogRef<AddDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private dashListService: DashlistService
    // private snackbar: MatSnackBar
    ) {  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() { }


  getErrorMessage() {
    return 'You must enter a value';
  }

  get stocks() {
    return this.dashboardForm.get('dashboards') as FormArray;
  }

  addAlias() {
    this.stocks.push(
      this.fb.group({
        name: ['', Validators.required]
      })
    );
  }

  removeAlis(index) {
    this.stocks.removeAt(index);
  }

  onSubmit() {
    console.log(this.dashboardForm.value);
    this.dashListService.addDashboard(this.dashboardForm.value).subscribe(
      (data) => {
        this.onNoClick();
      }
    );
    }

}

