import { Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators, FormArray, FormControlDirective} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, DateAdapter } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { SettingService } from '../setting.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { async } from 'rxjs/internal/scheduler/async';

export interface DialogData {
  id;
  name;
}

export interface Crop {
  name: string;
}

@Component({
  selector: 'app-add-cost',
  templateUrl: './add-cost.component.html',
  styleUrls: ['./add-cost.component.css'],
  providers: [SettingService]
})
export class AddCostComponent implements OnInit {
  costForm = this.fb.group({
   costs: this.fb.array([
    this.fb.group({
    crop: ['', Validators.required],
    cropId:  ['', Validators.required],
    profit:  ['', Validators.required],
    transport:  ['', Validators.required],
    date:  ['', Validators.required],
    })])
  });
  crops: Crop[];
  filteredOptions: Observable<Crop[]>;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    public dialogRef: MatDialogRef<AddCostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private settingService: SettingService,
    private fb: FormBuilder,
    // private snackbar: MatSnackBar
    ) {this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.settingService.getCrops().subscribe(
      (data: Crop[]) => {
        this.crops = data;
        this.filteredOptions = this.costForm.get('costs').get('0').get('crop').valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.crops.slice())
        );
      }
    );
  }
  setCropId(index, value) {
    this.costForm.get('costs').get(index.toString()).get('cropId').setValue(value.id);
  }

  getCrops() {
    this.settingService.getCrops().subscribe(
      (data: Crop[]) => {
        this.crops = data;
      }
    );
  }

  displayFn(user: Crop): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): Crop[] {
    const filterValue = name.toLowerCase();

    return this.crops.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  get stocks() {
    return this.costForm.get('costs') as FormArray;
  }

  async addAlias() {
    this.stocks.push(
      this.fb.group({
        crop: ['', Validators.required],
        cropId:  ['', Validators.required],
        profit:  ['', Validators.required],
        transport:  ['', Validators.required],
        date:  ['', Validators.required],
      })
    );
    const length = this.stocks.length - 1;

    this.filteredOptions = this.costForm.get('costs').get(length.toString()).get('crop').valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.crops.slice())
    );
  }

  removeAlis(index) {
    this.stocks.removeAt(index);
  }

  onSubmit() {
   console.log(this.costForm.value);
   this.settingService.addCost(this.costForm.value).subscribe(
     (data) => {
      this.onNoClick();
     }
   );
  }

}
