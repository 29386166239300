import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl
const url1 = apiUrl + '/add-dashboard';
const url2 = apiUrl + '/get-dashboards';
const url3 = apiUrl + '/delete-dashboard/';


@Injectable({
  providedIn: 'root'
})
export class DashlistService {
  headers = new HttpHeaders({'Content-Type': 'application/json', 'X-Requested-Width': 'XMLHttpRequeest'});

  constructor(private http: HttpClient) { }

  addDashboard(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }
  getDashboards() {
    return this.http.get(url2, {headers: this.headers});
  }
  deleteDashboard(id) {
    return this.http.get(url3 + id, {headers: this.headers});
  }
}
