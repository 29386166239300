import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthService]
})
export class LoginComponent implements OnInit {

  path;
  authForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: Router
  ) { }

  ngOnInit() {
    this.path = this.authService.path;
  }

  onSubmit() {
    this.authService.login(this.authForm.value).subscribe(
      (data: string) => {
        if (data.hasOwnProperty('id')) {
          this.authService.setCredentials(data['id'], data['username'], data['role'], data['status']);
          // this.route.navigate(['/dashboards']);
          this.route.navigate([`${this.path + '/agri-dashboard'}`]);
          // this.path == '' ? window.location.replace("/") : window.location.href.replace('/login', '');
         } else {
           this.snackBar.open(data, 'Wrong password or username', {
             duration: 2000,
           });
         }
      }
    );
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

}
