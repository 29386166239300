import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LandcoverService } from '../../landcover/landcover.service';
import { NationalAccountService } from '../national-account.service';

@Component({
  selector: 'app-national-account-dashb',
  templateUrl: './national-account-dashb.component.html',
  styleUrls: ['./national-account-dashb.component.css']
})
export class NationalAccountDashbComponent implements OnInit {
  path = this.authService.path;
  index = 0;
  menus
  dashId;
  dashName;
  itemType;

  menuId;
  itemId;
  itemName;
  itemUnit;
  items;

  wait = true;
  values = [];
  wait_values = true;
  constructor(
    public router: Router,
    public authService: AuthService,
    public landServ: LandcoverService,
    public natAccServ: NationalAccountService,
    public route: ActivatedRoute
  ) {
    this.dashId = route.url['_value'][1].parameters['dashb'];
    this.itemType = route.url['_value'][1].parameters['name'];
    this.dashName = route.url['_value'][0].path;
    // this.itemType = route.url['_value'][2].path;

    // console.log(route.url['_value'])
    // console.log(this.dashId)
  }
  ngOnInit() {
    this.getMainMenu();
  }

  getMainMenu() {
    this.landServ.getItemsByType(this.itemType).subscribe(
      (data: []) => {
        this.menus = data;
        // console.log(this.menus)
        if (this.menus.length !== 0) {
          this.menuId = this.menus[0].id;
          this.itemName = this.menus[0].title;
          this.itemUnit = this.menus[0].unit;
          this.getItems();
        }
      }
    );
  }

  getItems() {
    this.wait_values = true;
    this.natAccServ.getNationalAccountValues(this.menuId, this.itemType).subscribe(
      (data: any) => {
        this.values = data[0];
        // console.log(this.values);

        this.wait_values = false;
      }
    );
  }

  backToDashboards() {
    this.router.navigate([`${this.path + '/agri-dashboard'}`]);
  }

  backToAimsDashboards() {
    var name = this.dashName;
    this.router.navigate(['/dashboard/' + this.dashId, { 'name': name }], { skipLocationChange: false });
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    // console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index;
    var activmenu = this.menus[this.index]
    
    this.menuId = activmenu.id;
    this.itemName = activmenu.title;
    this.getItems();
  }

}
