import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AimsService } from '../../aims.service';
import { LivestockService } from '../../livestock/livestock.service';

@Component({
  selector: 'app-prodval-main',
  templateUrl: './prodval-main.component.html',
  styleUrls: ['./prodval-main.component.css']
})
export class ProdvalMainComponent implements OnInit {

  path = this.authService.path;
  index = 0;
  menus
  dashId;
  dashName;
  category;

  years = [];
  selectedValue: string;
  constructor(
    public router: Router,
    public authService: AuthService,
    public aimsServ: AimsService,
    public route: ActivatedRoute,
    public livestock_serv: LivestockService,
  ) { 
    this.dashId = route.url['_value'][1].parameters['dashb'];
    this.category = route.url['_value'][1].parameters['name'];
    this.dashName = route.url['_value'][0].path;

  }

  ngOnInit() {
      // this.getAllmenus();
      this.getMainMenu();
      // this.getYears()
    }
  
    getMainMenu() {
       this.livestock_serv.getMainMenu(this.category).subscribe(
         (data: []) => {
           this.menus = data;
          //  console.log(this.menus)
         }
       );
     }
     backToDashboards() {
      this.router.navigate([`${this.path + '/agri-dashboard'}`]);
  }

   backToAimsDashboards() {
    var name = this.dashName;
      this.router.navigate(['/dashboard/'+ this.dashId, { 'name':name }], { skipLocationChange: false });
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    // console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index;
  }

  getYears() {
    var curyear = new Date().getFullYear();
    for(let i = 0; i < 20; i++) {
      // console.log( i )
      this.years.push(curyear - i)
    }
    
  }
}
