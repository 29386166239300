import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
HC_exporting(Highcharts);

@Component({
  selector: 'app-landcover-values',
  templateUrl: './landcover-values.component.html',
  styleUrls: ['./landcover-values.component.css']
})
export class LandcoverValuesComponent implements OnInit {
  @Input() values: any;
  @Input() itemName: any;
  @Input() itemUnit: any;

  Highcharts = Highcharts;
  chartOptions: {};
  chartOptions2: {};
  chartOptions3: {};
  chartOptions4: {};
  chartConstructor = 'chart';
  
  showChart1 = true;
  showChart2 = true;
  showChart3 = true;
  showChart4 = true;
  constructor() { }

  ngOnInit() {
    // console.log(this.values.chart1.original)

    this.showChart1 = this.values.chart1.chart1.original[0].series.length > 0;
    this.showChart2 = this.values.chart2.chart2.original[0].series.length > 0;
    this.showChart3 = this.values.chart3.chart3.original[0].series.length > 0;
    this.showChart4 = this.values.chart4.chart4.original[0].series.length > 0;
    
    this.drawChart1(this.values.chart1.chart1.original[0], this.values.chart1.title)
    this.drawChart2(this.values.chart2.chart2.original[0], this.values.chart2.title)
    this.drawChart3(this.values.chart3.chart3.original[0], this.values.chart3.title)
    this.drawChart4(this.values.chart4.chart4.original[0], this.values.chart4.title)
  }

  drawChart1(values, title) {
    this.chartOptions = {
      chart: {
          type: 'column',
      },
      title: {
          text: title
      },
      subtitle: {
          text: 'per year'
      },
      xAxis: {
          categories: values.categories,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Values'
          },
          
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              // borderWidth: 0,
              dataLabels: {
                enabled: false
            },
            borderRadius: '0.5%',
            groupPadding: 0.25
          }
      },
      series: values.series
  };
                
  }

  drawChart2(values, title) {
    this.chartOptions2 = {
      chart: {
          type: 'column',
      },
      title: {
          text: title
      },
      subtitle: {
          text: 'per year'
      },
      xAxis: {
          categories: values.categories,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Values'
          },
          
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              // borderWidth: 0,
              dataLabels: {
                enabled: false
            },
            borderRadius: '0.5%',
            groupPadding: 0.25
          }
      },
      series: values.series
  };
                
  }

  drawChart3(values, title) {
    this.chartOptions3 = {
      chart: {
          type: 'column',
      },
      title: {
          text: title
      },
      subtitle: {
          text: 'per year'
      },
      xAxis: {
          categories: values.categories,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Values'
          },
          
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              // borderWidth: 0,
              dataLabels: {
                enabled: false
            },
            borderRadius: '0.5%',
            groupPadding: 0.25
          }
      },
      series: values.series
  };
                
  }

  drawChart4(values, title) {
    this.chartOptions4 = {
      chart: {
          type: 'column',
      },
      title: {
          text: title
      },
      subtitle: {
          text: 'per year'
      },
      xAxis: {
          categories: values.categories,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Values'
          },
          
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              // borderWidth: 0,
              dataLabels: {
                enabled: false
            },
            borderRadius: '0.5%',
            groupPadding: 0.25
          }
      },
      series: values.series
  };
                
  }
}
