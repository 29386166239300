import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardDefinitionService } from 'src/app/modules/dashboard-definition/dashboard-definition.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashb-register',
  templateUrl: './dashb-register.component.html',
  styleUrls: ['./dashb-register.component.css']
})
export class DashbRegisterComponent implements OnInit {
  registerForm: FormGroup;
  regions;
  countries;
  occupations = ['Farmer', 'Engineer', 'Doctor', 'Teacher', 'Artist', 'Other'];
  showRegionField = false;
  submitting = false;

  constructor(
    private fb: FormBuilder, 
    private userService: AuthService,
    private dashDefServ: DashboardDefinitionService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: Router) 
    { }

  ngOnInit() {
    this.getCountries();
    this.getRegions();
    this.registerForm = this.fb.group({
      first_name: ['', Validators.required],
      surname: ['', Validators.required],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      date_of_birth: ['', Validators.required],
      occupation: ['', Validators.required],
      country: ['', Validators.required],
      phone: ['', [Validators.required, ]], // Validators.pattern(/^\d+$/)
      region: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
      password_confirmation: ['', [Validators.required]]
    }, {
      validator: this.passwordMatchValidator
    });

    this.registerForm.get('country').setValue('223')
    this.onCountryChange();
  }

  getCountries() {
    this.dashDefServ.getCountries().subscribe(
      (data: []) => {
        this.countries = data;
      }
    );
   }

  getRegions() {
    this.dashDefServ.getRegions().subscribe(
      (data: []) => {
        this.regions = data;
      }
    );
   }

   onCountryChange() {
    const countryControl = this.registerForm.get('country');
    const regionControl = this.registerForm.get('region');

    if (countryControl.value === '223') { 
      this.showRegionField = true;
      regionControl.setValidators([Validators.required]);
    } else {
      this.showRegionField = false;
      regionControl.clearValidators();
    }
    

    regionControl.updateValueAndValidity();
  }
  
  passwordMatchValidator(form: FormGroup) {
    return form.get('password').value === form.get('password_confirmation').value
      ? null : { mismatch: true };
  }

  onSubmit() {
    if (this.registerForm.valid) {
      this.submitting = true;
      this.userService.register(this.registerForm.value).subscribe(
        (data: string) => {
          if (data.hasOwnProperty('id')) {
            this.authService.setUserCredentials(data['id'], data['email']);
            this.route.navigate(['/agri-dashboard']);
            // window.location.replace("/");
          } else {
             this.snackBar.open(data, 'user with such email exists', {
               duration: 2000,
             });
          }
        },
        error => {
          console.error('Error registering user', error);
        }
      );
      this.submitting = false;
    }
  }

  toLogin() {
    this.route.navigate(['/login'])
  }
}