import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
const url1 = apiUrl + '/aims/item-categories/';
const url2 = apiUrl + '/aims/items/';
const url3 = apiUrl + '/aims/item-values/';

const url4 = apiUrl + '/aims/indicator-categories/';
const url5 = apiUrl + '/aims/item-indicators/';
const url6 = apiUrl + '/aims/indicator-values/';
const url7 = apiUrl + '/aims/indicators/';
const url8 = apiUrl + '/aims/aniplant-health-values/';
const url9 = apiUrl + '/aims/crops-values/';
const url10 = apiUrl + '/aims/item-categories-by-title/';
const url11 = apiUrl + '/aims/indicators_by_type_and_category/';

@Injectable({
  providedIn: 'root'
})
export class AimsService {

  constructor(private http: HttpClient) { }
  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Requested-Width': 'XMLHttpRequeest',
    }
  );

  getMainMenu(type) {
    return this.http.get(url1 + type, {headers: this.headers});
  }

  getItems(menuId) {
    return this.http.get(url2 + menuId, {headers: this.headers});
  }

  getItemValues(itemId, category) {
    return this.http.get(url3 + itemId + '/' + category, {headers: this.headers});
  }

  getIndicatorCategory(type) {
    return this.http.get(url4 + type, {headers: this.headers});
  }

  getItemIndicators(menuId) {
    return this.http.get(url5 + menuId, {headers: this.headers});
  }
  getIndicatorValues(indicator) {
    return this.http.get(url6 + indicator, {headers: this.headers});
  }

  getCropsValues(itemId, category) {
    return this.http.get(url9 + itemId + '/' + category, {headers: this.headers});
  }

  // land cover and others

  getIndicators(type) {
    return this.http.get(url7 + type, {headers: this.headers});
  }

  // animal plant health
  getAnimalPlantHealthValues(indicatorId, itemId) {
    return this.http.get(url8 + itemId + '/' + indicatorId, {headers: this.headers});
  }


  getMainMenuByTitle(title, type) {
    return this.http.get(url10 + title + '/' + type, {headers: this.headers});
  }

  getIndicatorsByCategoryAndType(type, category) {
    return this.http.get(url11 + type + '/' + category, {headers: this.headers});
  }
}
