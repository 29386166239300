import { Component, OnInit } from '@angular/core';
import { ForecastService } from 'src/app/forecast.service';
import { WeatherData } from 'src/app/models/weather-model';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { WeatherTodayComponent } from '../weather-today/weather-today.component';
import { NavigationEnd, Router } from '@angular/router';
import { RegionData } from 'src/app/models/region-model';
import { DistrictCoordinates} from 'src/app/models/district-model';
import { FormControl, FormGroup } from '@angular/forms';

export interface WeatherDataDialog {
  weatherDataArray:any;

}

@Component({
  selector: 'app-weather-future',
  templateUrl: './weather-future.component.html',
  styleUrls: ['./weather-future.component.css']
})
export class WeatherFutureComponent implements OnInit {
  selectedDistrict = '';
  iconSun;
  iconCloud;
  iconMoon;
  iconRain;
  createGroupForm:FormGroup;
  regions:{};
  districts:{};
  constructor(private forecastService:ForecastService,public dialog: MatDialog,private router: Router) { }
  weatherData?:WeatherData;  
  districtCoordinates?:DistrictCoordinates;
  latitude:any = -10.3725;
  longitude:any = 38.76251;
  weatherDataArray:any = [];
  
  ngOnInit() {
    this.getWeatherData(this.latitude,this.longitude);
    this.forecastService.getRegions().subscribe(
     data => {this.regions = data;
      console.log(this.regions);   
     }
    );
    this.createGroupForm = new FormGroup({
      region : new FormControl(''),
      district : new FormControl('')
    });
   }

onChangeRegion(regionId):void{
  if(regionId){
    this.forecastService.getDistricts(regionId).subscribe(
      data => {
        this.districts = data;
        console.log(this.districts);
      }
     );
  }
  else{
    this.districts = null;
  }
}

onChangeDistrict(id):void{
    this.forecastService.getDistrict(id).subscribe(
      response=>{
        this.districtCoordinates = response;
        console.log(this.districtCoordinates);
        const latitude = this.districtCoordinates[0].latitude;
        const longitude = this.districtCoordinates[0].longitude;
        this.selectedDistrict = this.districtCoordinates[0].name;
        this.getWeatherData(latitude,longitude);       
        if(this.weatherData.properties.timeseries[0].data.instant.details.air_temperature>19.9){
          this.iconSun=true;
          this.iconCloud=false;
          this.iconMoon=false;
          this.iconRain=false;
        }
        else if(this.weatherData.properties.timeseries[0].data.instant.details.air_temperature<=19.9){
          this.iconCloud=true;
          this.iconSun=false;
          this.iconMoon=false;
          this.iconRain=false;
        }
       else if(this.weatherData.properties.timeseries[0].data.next_1_hours.details.precipitation_amount>0){
          this.iconRain=true;
          this.iconSun=false;
          this.iconCloud=false;
          this.iconMoon=false;
        }
        else if(this.weatherData.properties.timeseries[0].data.next_1_hours.summary.symbol_code=='fair_night'){
          this.iconRain=false;
          this.iconSun=false;
          this.iconCloud=false;
          this.iconMoon=true;
        }
        else{
          this.iconRain=false;
          this.iconSun=false;
          this.iconCloud=false;
          this.iconMoon=false;
        }
      }
     ); 
}

private getWeatherData(latitude,longitude:any){
  this.forecastService.getWeatherForecast(latitude,longitude).subscribe({
    next:(respnse)=>{
      this.weatherData = respnse;  
      const time = respnse.properties.timeseries;
      console.log(time)
      this.futureForecast(time); 
      // if(this.weatherData.properties.timeseries[0].data.instant.details.air_temperature>19.9){
      //   this.iconSun=true;
      //   this.iconCloud=false;
      //   this.iconMoon=false;
      //   this.iconRain=false;
      // }
      // else if(this.weatherData.properties.timeseries[0].data.instant.details.air_temperature<=19.9){
      //   this.iconCloud=true;
      //   this.iconSun=false;
      //   this.iconMoon=false;
      //   this.iconRain=false;
      // }
       if(this.weatherData.properties.timeseries[0].data.next_1_hours.details.precipitation_amount==0){
        this.iconRain=false;
        this.iconSun=true;
        this.iconCloud=false;
        this.iconMoon=false;
      }
     else if(this.weatherData.properties.timeseries[0].data.next_1_hours.details.precipitation_amount>0){
        this.iconRain=true;
        this.iconSun=false;
        this.iconCloud=false;
        this.iconMoon=false;
      }
      else if(this.weatherData.properties.timeseries[0].data.next_1_hours.summary.symbol_code=='fair_night'){
        this.iconMoon=true;
        this.iconRain=false;
        this.iconSun=false;
        this.iconCloud=false;
      }
      else{
        this.iconRain=false;
        this.iconSun=false;
        this.iconCloud=false;
        this.iconMoon=false;
      }   
    }
  })
}

futureForecast(data:any){
  this.weatherDataArray=[];
for(let i=0;i<data.length;i=i+21){
this.weatherDataArray.push(data[i]);
}
}




indexComponent()
{ 
   this.router.navigate(['']);
 }
}
 


