import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material';
import * as Highcharts from 'highcharts';
import { StocksService } from '../stocks.service';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.css']
})
export class StorageComponent implements OnInit {
  cropName;
  cropGrades;
  regions;
  districts;
  crops;
  utilization;
  stockTrend;
  stockPerRegions;
  stockGrade;

  years;
  selected = 0;
  cropId;
  pickedRegion = 'Tanzania';
  titledist = "Regions";

  // filter variables
  selectedRegion = null;
  selectedDistrict = null;
  selectedYear = null;


  clearRegion = false;
  clearDistrict = false;
  clearYear = false;

  Highcharts = Highcharts;
  chartOptions: {};
  chartOptionsRegions: {};
  chartOptionsUtilization: {};
  chartOptionsGrade: {};
  chartConstructor = 'chart';
  constructor(
    private stockServ: StocksService,
  ) { }

  ngOnInit(): void {
    this.allCrops();
    this.getAllData();
  }

  getAllData() {
    this.allYears();
    this.allRegions();
    this.drawCharts();
  }

  getColor(change) {
    if (change > 0) {
        return '#116B38';
    }
    if (change < 0) {
        return '#C81212';
    }
    if (change === 0) {
      return '#0A3EA6';
    }
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }


  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollable() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noCrops() {
    if (this.crops.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  onChangeCrop(cropId, cropName, i) {
    console.log(cropId);
    this.cropId = cropId;
    this.cropName = cropName;
    this.selected = i;
    this.pickedRegion = 'Tanzania'

    this.clearRegion = false;
    this.clearDistrict = false;
    this.clearYear = false;

    this.selectedRegion = null;
    this.selectedDistrict = null;
    this.selectedYear = null;

    if (this.selectedRegion == null && this.selectedDistrict == null && this.selectedYear == null) {
      this.warehouseCharts(cropId);
    } else {
      this.filter();
    }

   }

  allYears() {
    this.years = this.stockServ.getYears();
  }

  allRegions() {
    this.stockServ.getRegions().subscribe(
      data => {
        this.regions = data;
        // console.log(this.regions)
      }
    );
  }

  getDistricts(regionId) {
    this.titledist = "Districts"
    var id = localStorage.getItem('id');
    this.stockServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
        // console.log(this.districts)
      }
    );
  }

  selectYear(year) {
    this.selectedYear = year;
    this.clearYear = true;
    this.filter();
  }

  selectRegion(event: MatSelectChange) {
    this.selectedRegion = event.value;
    this.pickedRegion = event.source.triggerValue;
    this.clearRegion = true;
    // this.getDistricts(event.value);
    this.filter();
  }

  selectDistrict(id) {
    this.selectedDistrict = id;
    this.clearDistrict = true;
    this.filter();
  }

  clearFilter(value) {
    if (value == 'region') {
      this.selectedRegion = null;
      this.clearRegion = false;
      this.titledist = "Regions";
      this.filter();
    }
    if (value == 'district') {
      this.selectedDistrict = null;
      this.clearDistrict = false;
      this.filter();
    }
    if (value == 'year') {
      this.selectedYear = null;
      this.clearYear = false;
      this.filter();
    }
  }


  allCrops() {
    this.stockServ.getCrops().subscribe(
      data => {
        this.crops = data;
        this.cropId = this.crops[0].id;
        this.cropName = this.crops[0].name;

        this.getAllData();
      }
    );
  }



  drawCharts() {
    this.warehouseCharts(this.cropId);
  }

  warehouseCharts(crop) {
    this.stockServ.stockWarehouseCharts(crop).subscribe(
      data => {
        this.stockTrend = data[0];
        this.stockPerRegions = data[1];
        this.utilization = data[2];
        this.stockGrade = data[3];
        this.stockTrendChart();
        this.stockDistributionChart();
        this.stockUtilizationChart();
        this.stockGradeChart();

      }
    );
  }




  // highcharts
  stockTrendChart() {
    this.chartOptions = {
      chart: {
        type: 'column',

    },
    title: {
        text: this.cropName + ' Stock in ' + this.pickedRegion
    },
    subtitle: {
        // text: 'Source: WorldClimate.com'
    },
    xAxis: {
        categories: this.stockTrend[0],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Quantity (MT)'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: this.stockTrend[1]
  };
  }

  stockDistributionChart() {
    this.chartOptionsRegions = {
      chart: {
        type: 'column',

    },
    title: {
        text: this.cropName + ' Stock Distribution in ' + this.pickedRegion
    },
    subtitle: {
        // text: 'Source: WorldClimate.com'
    },
    xAxis: {
        categories: this.stockPerRegions[0],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Quantity (MT)'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: this.stockPerRegions[1]
  };
  }

  stockUtilizationChart() {
    this.chartOptionsUtilization = {
      chart: {
        type: 'column',

    },
    title: {
        text: this.cropName + ' Stock Distribution in ' + this.pickedRegion
    },
    subtitle: {
        // text: 'Source: WorldClimate.com'
    },
    xAxis: {
        categories: this.utilization[0],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Quantity (MT)'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: this.utilization[1]
  };
  }

  stockGradeChart() {
    this.chartOptionsGrade = {
      chart: {
        type: 'column',
    },
    title: {
        text: this.cropName + ' Stock Distribution By Grades in ' + this.pickedRegion
    },
    subtitle: {
        // text: 'Source: WorldClimate.com'
    },
    xAxis: {
        categories: this.stockGrade[0],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Quantity (MT)'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.3,
            borderWidth: 0
        },
        series: {
          borderWidth: 0,
      },
    },
    series: this.stockGrade[1]
  };
  }


  // filters
  filter() {
    if (this.selectedDistrict == null) {
      // region and year
      if (this.selectedRegion == null && this.selectedYear == null) {
        this.warehouseCharts(this.cropId);
      }
      if (this.selectedRegion != null && this.selectedYear != null) {
        this.stockServ.getWarehouseStockByRegionAndYear(this.selectedRegion, this.selectedYear, this.cropId).subscribe(
          data => {
            this.updateValues(data);
          }
        ) ;
      }

      // only region
      if (this.selectedRegion != null && this.selectedYear == null) {
        this.stockServ.getWarehouseStockByRegion(this.selectedRegion, this.cropId).subscribe(
          data => {
            this.updateValues(data);
          }
        ) ;
      }

      // only year
      if (this.selectedRegion == null && this.selectedYear != null) {
        this.stockServ.getWarehouseStockByYear(this.selectedYear, this.cropId).subscribe(
          data => {
            this.updateValues(data);
          }
        ) ;
      }
    }
  }

  updateValues(data) {
    this.stockTrend = data[0];
    this.stockPerRegions = data[1];
    this.utilization = data[2];
    this.stockGrade = data[3];
    this.stockTrendChart();
    this.stockDistributionChart();
    this.stockUtilizationChart();
    this.stockGradeChart();
  }

}
