import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
const url1 = apiUrl + '/aims/nationalaccount-values/';

@Injectable({
  providedIn: 'root'
})
export class NationalAccountService {

  constructor(private http: HttpClient) { }
  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Requested-Width': 'XMLHttpRequeest',
    }
  );

  getNationalAccountValues(id, type) {
    return this.http.get(url1 + id + '/' + type, {headers: this.headers});
  }
}
