// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  weatherApiBaseUrl:'https://api.met.no/weatherapi/locationforecast/2.0/complete.json',

  apiUrl: 'https://iks.kilimo.go.tz/agri-dashboard/backend/public/api',
  baseUrl: 'https://iks.kilimo.go.tz',
  loginUrl: 'https://iks.kilimo.go.tz/mkilimo/auth',
  //apiUrl: 'http://ekilimo.local',
  // apiUrl: 'http://127.0.0.1:8000/api',
  // const apiUrl = 'http://agri-dashboard.multics.co.tz/public/api';
  // const apiUrl = 'http://104.236.104.29/agri-dashboard/public/public/api';
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
