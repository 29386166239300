import { Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { DashboardDefinitionService } from '../dashboard-definition.service';

export interface DialogData {
  id;
  type;
}
@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.css'],
    providers: [DashboardDefinitionService]
  })
  export class DeleteDialogComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dashDefServ: DashboardDefinitionService,
    // private snackbar: MatSnackBar
    ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() { }

  onSubmit() {
    this.isLoading = true;

    if (this.data.type === undefined) {
      this.dashDefServ.deleteMainMenu(this.data.id).subscribe(
        (data) => {
          this.isLoading = false;
          this.onNoClick();
        }
      );
    }

    if (this.data.type !== undefined) {
      this.dashDefServ.deleteCrop(this.data.id).subscribe(
        (data) => {
          this.isLoading = false;
          this.onNoClick();
        }
      );
    }
    }

}

