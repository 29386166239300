import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-main-aims-dashb',
  templateUrl: './main-aims-dashb.component.html',
  styleUrls: ['./main-aims-dashb.component.css']
})
export class MainAimsDashbComponent implements OnInit {
  @Input() dashboardName: string;
  path;
  id;
  constructor(
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
  ) { 
    this.id = route.url['_value'][1].path
  }

  ngOnInit() {
    this.path = this.authService.path;
  }

  toCropsDashb(name) {
    this.router.navigate(['/aims/crops', {'dashb':this.id, 'name':name}])
  }

  toLivestockDashb(name) {
    this.router.navigate(['/aims/livestock', {'dashb':this.id, 'name':name}])
  }

  toSocioEconomicDashb(name) {
    this.router.navigate(['/aims/socio-economics', {'dashb':this.id, 'name':name}])
  }

  toLandcoverDashb(name) {
    this.router.navigate(['/aims/land-cover', {'dashb':this.id, 'name':name}])
  }

  toNationalAccountDashb(name) {
    this.router.navigate(['/aims/national-accounts', {'dashb':this.id, 'name':name}])
  }

  toPublicFinanceDashb(name) {
    this.router.navigate(['/aims/public-finance', {'dashb':this.id, 'name':name}])
  }

  toItemsDashb(name) {
    this.router.navigate(['/aims/' + name, {'dashb':this.id, 'name':name}])
  }

  // toOtherDashb(name) {
  //   this.router.navigate(['/aims/type-2/' + name, {'dashb':this.id, 'name':name}])
  // }

  toTradeQtyDashb(name) {
    this.router.navigate(['/aims/trade-quantity', {'dashb':this.id, 'name':name}])
  }

  toAniplantDashb(name) {
    this.router.navigate(['/aims/aniplant-health/' + name, {'dashb':this.id, 'name':name}])
  }

  toProdValDashb(name) {
    this.router.navigate(['/aims/production-value-price', {'dashb':this.id, 'name':name}])
  }
}
