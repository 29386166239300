import { Component, OnInit } from '@angular/core';
import { ForecastService } from 'src/app/forecast.service';
import { WeatherData } from 'src/app/models/weather-model';

@Component({
  selector: 'app-weather-today',
  templateUrl: './weather-today.component.html',
  styleUrls: ['./weather-today.component.css']
})
export class WeatherTodayComponent implements OnInit {

  weatherNow:any;
  constructor(private forecastService:ForecastService) { }
  weatherData?:WeatherData;  
  weatherDataArray:any = [];
  latitude:any = -10.3725;
  longitude:any = 38.76251;
  timeArray:any
  ngOnInit() {
    this.getWeatherData(this.latitude,this.longitude);
    // this.latitude = '',
    // this.longitude = ''
    this.timeArray = this.weatherData.properties.timeseries
   }

private getWeatherData(latitude,longitude:any){
  this.forecastService.getWeatherForecast(latitude,longitude).subscribe({
    next:(respnse)=>{
      this.weatherData = respnse;      
      console.log(respnse)
      const time = respnse.properties.timeseries;
      console.log(time)
      this.futureForecast(time); 
    }
  })
}

futureForecast(data:any){
  for(let i=0;i<data.length;i=i+24){
  this.weatherDataArray.push(data[i]);
  }
  console.log(this.weatherDataArray);
  }

  onSubmit(){
this.getWeatherData(this.latitude,this.longitude);
this.latitude = '',
this.longitude = ''
  }


}
