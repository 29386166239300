import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { INFERRED_TYPE } from '@angular/compiler/src/output/output_ast';
import { AddMarketComponent } from 'src/app/modules/dashboard-definition/add-market/add-market.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-price-card',
  templateUrl: './price-card.component.html',
  styleUrls: ['./price-card.component.css'],
  providers: [AuthService]
})
export class PriceCardComponent implements OnInit {
  @Input() market: string;
  @Input() marketId: string;
  @Input() price: number;
  @Input() deviation: string;
  @Input() color: string;
  @Input() change: number;
  @Input() type: string;
  @Input() cropType: string;
  @Input() priceDate: any;

  @Output() newMarketEditEvent = new EventEmitter<string>();


  constructor(
    public dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit() {
  }

  notifyPriceEdit() {
    this.newMarketEditEvent.emit('edit');
  }

  increase(change) {
    if (change > 0) {
      return true;
    } else {
      return false;
    }
  }

  onEditMarket() {
    const dialogRef = this.dialog.open(AddMarketComponent, {
      width: '500px',
      height: '400px',
      data: {market: this.market, marketId: this.marketId}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.notifyPriceEdit();
    });
  }

}
