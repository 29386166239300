import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './components/profile/profile.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { PriceCardComponent } from './widgets/price-card/price-card.component';
import { TableComponent } from './widgets/table/table.component';
import { AreaGraphComponent } from './widgets/area-graph/area-graph.component';


import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import { HttpClient} from '@angular/common/http';

import { HeaderComponent } from './components/header/header.component';
import { ProductionTableComponent } from './widgets/production-table/production-table.component';
import { BarGraphComponent } from './widgets/bar-graph/bar-graph.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


const path = '.';
// const path = 'agri-dashboard/public';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, path + '/assets/i18n/');
}

@NgModule({
  declarations: [PriceCardComponent, TableComponent, AreaGraphComponent, HeaderComponent, ProductionTableComponent, BarGraphComponent, ProfileComponent],
  imports: [

  CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    MatCardModule,
    MatMenuModule,
    MatDividerModule,
    MatTabsModule,
    MatButtonModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    PriceCardComponent,
    TableComponent,
    AreaGraphComponent,
    HeaderComponent,
    ProductionTableComponent,
    BarGraphComponent
    ],

})
export class SharedModule { }
