import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardDefinitionService } from './../../../dashboard-definition/dashboard-definition.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

export interface DialogData {
  action;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user;
  userForm = this.fb.group({
    firstname: ['', Validators.required],
    lastname:  ['', Validators.required],
    username:  ['', Validators.required],
    email:  ['', Validators.required],
   });

   passwordForm = this.fb.group({
    current: ['', Validators.required],
    new:  ['', Validators.required],
   });
  constructor(
    private fb: FormBuilder,
    private dashService : DashboardDefinitionService,
    private dialogRef: MatDialogRef<ProfileComponent>,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.dashService.getUser(localStorage.getItem('id')).subscribe(
      data => {
        this.user = data;

        this.userForm.get('firstname').setValue(this.user.firstname);
        this.userForm.get('lastname').setValue(this.user.lastname);
        this.userForm.get('username').setValue(this.user.username);
        this.userForm.get('email').setValue(this.user.email);
      }
    );
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  onSubmit() {
    if(this.userForm.valid) {
      this.dashService.updateProfile(this.userForm.value, localStorage.getItem('id')).subscribe(
        data => {
          this.snackbar.open('success', data.toString(), {
            duration: 5000
          });
          this.onNoClick();
        },
        error => {
          console.log(error);
        }
      )

    }
  }

  
  onSubmitPasswords() {
    if(this.passwordForm.valid) {
      this.dashService.updatePasswords(this.passwordForm.value, localStorage.getItem('id')).subscribe(
        data => {
          if (data.toString() != 'failed') {
            this.snackbar.open('success', data.toString(), {
              duration: 5000
            });
            this.onNoClick();
          } else {
            this.snackbar.open('failed', 'current password is incorrect', {
              duration: 5000
            });
          }
        },
        error => {
          console.log(error);
        }
      )

    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
