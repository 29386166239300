import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { SettingService } from '../../settings/setting.service';
import { AddUsersComponent } from '../add-users/add-users.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['number', 'first_name', 'last_name', 'username', 'email'];
  dataSource;
  users;
  constructor(
    private settingService: SettingService,
    public dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.settingService.getUsers().subscribe(
      (data) => {
        this.users = data;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddUser() {
    const dialogRef = this.dialog.open(AddUsersComponent, {
      width: '550px',
      height: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
     this.getUsers();
    });
  }

}
