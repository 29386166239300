import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';


const apiUrl = environment.apiUrl
const url1 = apiUrl + '/get-costs';
const url2 = apiUrl + '/get-crops';
const url3 = apiUrl + '/add-cost';
const url4 = apiUrl + '/get-users';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  headers = new HttpHeaders({'Content-Type': 'application/json', 'X-Requested-Width': 'XMLHttpRequeest'});

  constructor(private http: HttpClient) { }

  getCosts() {
    return this.http.get(url1, {headers: this.headers});
  }
  getCrops() {
    return this.http.get(url2, {headers: this.headers});
  }
  addCost(data) {
    console.log(data)
    return this.http.post(url3, data, {headers: this.headers});
  }
  getUsers() {
    return this.http.get(url4, {headers: this.headers});
  }

}
