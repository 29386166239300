import { Component, OnInit, Inject } from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { DashboardDefinitionService } from '../dashboard-definition.service';

export interface DialogData {
  cropId;
  type;
}

@Component({
  selector: 'app-add-sale',
  templateUrl: './add-sale.component.html',
  styleUrls: ['./add-sale.component.css']
})
export class AddSaleComponent implements OnInit {

  regions;
  districts;
  seasons;

  salesForm = this.fb.group({
   cropId: ['', Validators.required],
   details: this.fb.array([
    this.fb.group({
    district:  ['', Validators.required],
    region:  ['', Validators.required],
    sold: ['', Validators.required],
    season: ['', Validators.required],
    })])
  });

  constructor(
    public dialogRef: MatDialogRef<AddSaleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dashDefServ: DashboardDefinitionService,
    private fb: FormBuilder,
    // private snackbar: MatSnackBar
    ) {
      this.salesForm.get('cropId').setValue(data.cropId);
     }



  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.getSeasons();
    this.getRegions();
  }
  getRegions() {
    this.dashDefServ.getRegions().subscribe(
      (data) => {
        this.regions = data;
      }
    );
   }

   getErrorMessage() {
    return 'You must enter a value';
  }

  get stocks() {
    return this.salesForm.get('details') as FormArray;
  }

  addAlias() {
    this.stocks.push(
      this.fb.group({
        district:  ['', Validators.required],
        region:  ['', Validators.required],
        sold: ['', Validators.required]
      })
    );
  }

  removeAlis(index) {
    this.stocks.removeAt(index);
  }

  onSubmit() {
    if (this.salesForm.valid) {
      this.dashDefServ.addCropSales(this.salesForm.value).subscribe(
        (data) => {
          this.onNoClick();
        }
      );
    } else {
      console.log('invalid');
    }
    }

    getDistricts() {
      console.log(this.salesForm.value.details[0].region);
      this.dashDefServ.getDistricts(this.salesForm.value.details[0].region).subscribe(
        (data) => {
          this.districts = data;
        }
      );
    }

    getSeasons() {
      this.dashDefServ.getSeasons().subscribe(
        (data : Seasons) => {
          this.seasons = data.seasons;
        }
      );
    }

}

export interface Seasons {
  seasons;
}
