import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  authForm = this.fb.group({
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    email: ['', Validators.required],
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: Router
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.authForm.valid) {
      this.authService.signup(this.authForm.value).subscribe(
        (data: string) => {
          if (data.hasOwnProperty('id')) {
            this.authService.setCredentials(data['id'], data['username'], data['role'], data['status']);
            // this.route.navigate(['/dashboards']);
            this.route.navigate(['/']);
            window.location.replace("/");
          } else {
             this.snackBar.open(data, 'user with such email exists', {
               duration: 2000,
             });
          }
        }
      );
    }
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  toLogin() {
    this.route.navigate(['login'])
  }
}
