import { Component, Input, OnInit } from '@angular/core';
import { ProdvalService } from '../prodval.service';
import { LivestockService } from '../../livestock/livestock.service';

@Component({
  selector: 'app-prodval-forestry',
  templateUrl: './prodval-forestry.component.html',
  styleUrls: ['./prodval-forestry.component.css']
})
export class ProdvalForestryComponent implements OnInit {
  @Input() menuId: string;
  @Input() dashboardName: string;

  itemId;
  itemName;
  items;
  selected = 0;

  wait = true;
  values = [];
  wait_values = true;
  animalNumber = true;
  empty = false;
  constructor(
    // public aimsServ: AimsService,
    public livestock_serv: LivestockService,
    public prodServ: ProdvalService,
    ) 
    { }

  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this.menuId = '32' // there is no items for prod val animal price of animals, so we use that of livestock
    this.livestock_serv.getItemsByIndicatorCategory(this.menuId).subscribe(
      (data: []) => {
        this.items = data;
        // console.log(this.items[0]);
        if (this.items.length !== 0) {;
           this.itemId = this.items[0].id;
           this.itemName = this.items[0].title;
             this.getItemValues();
        }
        this.wait = false;
      }
    );
  }

  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollAble() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noItems() {
    if (this.items.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  getUpperCase(value: string) {
    var val = '';
    if(value) {
      val = value.toUpperCase();;
    } else {
      val = value;
    }
    return val;
  }

  onChangeItem(itemId, itemName, i, event, categoryId) {
    this.wait_values = true;

    this.itemId = itemId;
    this.itemName = itemName;
    this.selected = i;
    // console.log(event.target.innerText)
    // console.log(i)
    // console.log(document.getElementById('list'))
      this.getItemValues();
    }


  getItemValues() {
    this.prodServ.getForestryValues(this.itemId).subscribe(
      (data: any) => {
        // console.log(data)
        if(data[0][0].data.length == 0 && data[0][1].data.length == 0) {
          this.empty = true;
        } else {
          this.empty = false
        }
        this.values = data;
        this.wait_values = false;
      }
    );
  }

}
