import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardDefinitionService } from '../dashboard-definition.service';
import { AddProductionComponent } from '../add-production/add-production.component';
import { AuthService } from 'src/app/auth/auth.service';
import { ImportFileComponent } from '../import-file/import-file.component';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-crop-trading-info',
  templateUrl: './crop-trading-info.component.html',
  styleUrls: ['./crop-trading-info.component.css'],
  providers: [DashboardDefinitionService, AuthService]
})
export class CropTradingInfoComponent implements OnInit {

  path;
  private _cropId: string;
  districtsProduction;
  regionsProduction;
  districtsSells;
  regionsSells;
  regionsRemain;
  districtsRemain;
  seasons;
  prices;
  usdPrices = [];
  current_year = new Date().getFullYear();
  next_year = new Date().getFullYear()+ 1;
  current_season = this.current_year + '/'+ this.next_year;
  year = new FormControl('');
  // foods = [
  //   {value: '2020/2021', viewValue: '2020/2021'},
  // ];

  @Input() set cropId(value) {
    this._cropId = value;
    this.getStats();
  }

  get cropId() {
    return this._cropId;
   }

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private dashDefService: DashboardDefinitionService
  ) {
    this.year.setValue(this.current_season);
  }

  ngOnInit() {
    this.path = this.authService.path;
    this.getStats();
    this.getSeasons();
  }

  onAddProduction(type) {
    const dialogRef = this.dialog.open(AddProductionComponent, {
      width: '500px',
      height: '500px',
      data: {cropId: this.cropId, type}
    });
    dialogRef.afterClosed().subscribe(result => {
     this.getStats();
    });
  }

  onImportProduction(type) {
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '500px',
      height: '250px',
      data: {cropId: this.cropId, type}
    });
    dialogRef.afterClosed().subscribe(result => {
     this.getStats();
    });
  }
  onImportSales(type) {
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '500px',
      height: '250px',
      data: {cropId: this.cropId, type}
    });
    dialogRef.afterClosed().subscribe(result => {
     this.getStats();
    });
  }

  getStats() {
    this.dashDefService.getCropProduction(this.cropId, this.current_season, 'defined').subscribe(
      (data: Result) => {
        this.regionsProduction = data.regionsProduction;
        this.districtsProduction = data.districtsProduction;
        this.regionsSells = data.regionsSold;
        this.districtsSells = data.districtsSold;
        this.regionsRemain = data.regionsRemain;
        this.districtsRemain = data.districtsRemain;
        this.year.setValue(data.season);
      }
    );
  }

  getSeasons() {
    this.dashDefService.getSeasons().subscribe(
      (data: Seasons) => {
        this.seasons = data.seasons;
        console.log(data);
      }
    );
  }


  getGraphPrices(season) {
    // console.log(this.current_season);
    this.dashDefService.getCropProduction(this.cropId, season, 'undefined').subscribe(
      (data: Result) => {
        this.regionsProduction = data.regionsProduction;
        this.districtsProduction = data.districtsProduction;
        this.regionsSells = data.regionsSold;
        this.districtsSells = data.districtsSold;
        this.regionsRemain = data.regionsRemain;
        this.districtsRemain = data.districtsRemain;
      }
    );
  }

}

export class Result {
     regionsProduction;
     districtsProduction;
     regionsSold;
     districtsSold;
     regionsRemain;
     districtsRemain;
     season;
}

export interface Seasons {
  seasons;
}
